<template>
  <div class="home_box">
    <Title :titleList="titleList"></Title>
    <div class="PowerconsumptionBox">
      <ul>
        <li>
          <img src="@/assets/imgs/HVAC/air-conditioningBcg.png" alt="" />
          <div class="powerBox">
            <p class="num">{{ EleconsList.deviceNum }}</p>
            <p class="title">空调总数</p>
          </div>
        </li>
      </ul>
    </div>
    <!--     空调数据-->
    <div class="AirconditioningBox">
      <ul>
        <li v-for="(item, index) in AirconditioningList" :key="index">
          <div class="powerBox">
            <div class="numBox">
              <p class="num">{{ item.num }}</p>
              <p class="type">个</p>
            </div>
            <p class="title">{{ item.name }}</p>
          </div>
        </li>
      </ul>
    </div>
    <!--    告警总数-->
    <div class="devicealarmsBox">
      <p class="title">设备告警总数：</p>
      <p class="num">{{ ararmNumToals }}</p>
    </div>
    <!--     图表-->
    <div class="Stack_Graph_box">
      <div class="Stack_Graph" ref="countryH_sages"></div>
    </div>
    <!--     图表 信息-->
    <div class="EchartsnewsBox">
      <ul>
        <li v-for="(item, index) in EchartsnewsList" :key="index">
          <div class="title">
            <p :class="item.color"></p>
            <p class="name">{{ item.name }}</p>
          </div>
          <p class="num">{{ item.num }}</p>
        </li>
      </ul>
    </div>
    <!--  小标题-->
    <smallTitle :titleList="smalltitle"></smallTitle>
    <div class="echartsBox">
      <div class="echarts_bcg">
        <div class="colorBcg">在线比例</div>
        <div class="careAgedEcharts" ref="careAgedEcharts"></div>
      </div>
      <!--     图表 数据 -->
      <div class="PieBox">
        <ul>
          <li v-for="(item, index) in EchartpieList" :key="index">
            <div class="title">
              <p :class="item.color"></p>
              <p class="name">{{ item.name }}</p>
            </div>
            <p class="num">{{ item.num }}%</p>
          </li>
        </ul>
      </div>
    </div>
    <Title :titleList="environmentalonitoringList"></Title>
    <!--     环境监测-->
    <div class="environmentalonitoringBox">
      <ul>
        <li>
          <div class="envirBox">
            <img src="@/assets/imgs/HVAC/humid_icon.png" alt="" />
            <div class="powerBox">
              <p class="title">温湿度舒适度</p>
              <div class="numbox">
                <p class="num">{{ humidityNum }}</p>
                <p class="type">%</p>
              </div>
            </div>
          </div>
          <div class="envirBox">
            <img src="@/assets/imgs/HVAC/PMT.png" alt="" />
            <div class="powerBox">
              <p class="title">PM2.5舒适度</p>
              <div class="numbox">
                <p class="num">{{ ComfortNum }}</p>
                <p class="type">%</p>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import * as echarts from "echarts";
import Title from "@/components/Title";
import smallTitle from "@/components/smallTitle";
import { OverviewofAirConditioning, exec } from "@/api/common";
import bus from "@/bus";

export default {
  components: {
    Title,
    smallTitle,
  },
  data() {
    return {
      // 标题
      titleList: {
        title: "空调概览",
      },
      // 标题
      environmentalonitoringList: {
        title: "环境监测",
      },
      // 小标题
      smalltitle: {
        title: "在线比例",
      },
      // 告警信息
      EchartsnewsList: [],
      //   饼图 数据
      EchartpieList: [],
      // 空调 数据
      AirconditioningList: [],
      // 拱宸桥Id
      labelIds: "528221552517316608",
      // 概览数据
      EleconsList: {},
      // 设备告警总数
      ararmNumToals: 0,
      // 环境监测
      humidityNum: "",
      ComfortNum: "",
      // 图表实例
      myChart: null,
      myechart: null,
    };
  },
  mounted() {
    this.execHandle();
    let labelIds ="528221552517316608";
    // let labelIds = this.$store.state.labelIds;
    this.OverviewofAirConditioningHandle(labelIds);
    // bus.$on("labelIds", (val) => {
    //   console.log(val, "val");
    //   setTimeout(() => {
    //     this.OverviewofAirConditioningHandle(val);
    //   }, 200)
    // });
  },
  methods: {
    // 空调概览
    async OverviewofAirConditioningHandle(labelIds) {
      const res = await OverviewofAirConditioning(labelIds);
      this.EleconsList = res;
      console.log(res, "res 空调概览");
      // 告警信息
      this.EchartsnewsList = [
        {
          color: "skyblue",
          name: "信息",
          num: res.infoNum,
        },
        {
          color: "orange",
          name: "告警",
          num: res.alarmNum,
        },
        {
          color: "cameobrown",
          name: "故障",
          num: res.faultNum,
        },
      ];
      (this.AirconditioningList = [
        {
          name: "当前在线数",
          num: res.onlineNum,
        },
        {
          name: "当前离线数",
          num: res.offlineNum,
        },
        {
          name: "当前故障数",
          num: res.offlineNum,
        },
        {
          name: "当前运行数",
          num: "43",
        },
      ]),
        (this.ararmNumToals =
          Number(res.infoNum) + Number(res.alarmNum) + Number(res.faultNum));
      let onlineNum = (Number(res.onlineNum) / Number(res.deviceNum)) * 100;
      let offlineNum = (Number(res.offlineNum) / Number(res.deviceNum)) * 100;
      let faultNum = (Number(res.faultNum) / Number(res.deviceNum)) * 100;
      console.log("sssssss", offlineNum);
      //   饼图 数据
      this.EchartpieList = [
        {
          color: "blue",
          name: "在线率",
          num: onlineNum.toFixed(2),
        },
        {
          color: "skyblue",
          name: "离线率",
          num: offlineNum.toFixed(2),
        },
        {
          color: "orange",
          name: "故障率",
          num: faultNum.toFixed(2),
        },
      ];
      this.$nextTick(() => {
        this.careAgedEcharts(res.onlineNum, res.offlineNum, res.faultNum);
        this.CountrySages(res.infoNum, res.alarmNum, res.faultNum);
      });
    },
    // 环境监测
    async execHandle() {
      const res = await exec();
      let data = res.data.content;
      this.humidityNum = data[0].WSDSSD;
      this.ComfortNum = data[0].PM25SSD;
      console.log(data, "返回数据");
      console.log(res, "res 环境监测");
    },

    //  图表
    CountrySages(infoNum, alarmNum, faultNum) {
      // if (this.myChart != null && this.myChart != '' && this.myChart != undefined) {
      //   this.myChart.dispose()
      // }
      // 基于准备好的dom，初始化echarts实例
      this.myChart = echarts.init(this.$refs.countryH_sages);
      this.myChart.setOption({
        // tooltip: {
        //   trigger: 'axis',
        //   axisPointer: {
        //     // Use axis to trigger tooltip
        //     type: 'shadow' // 'shadow' as default; can also be 'line' or 'shadow'
        //   }
        // },
        legend: {
          show: false,
        },
        grid: {
          left: "0.5%",
          right: "0.5%",
          containLabel: true,
        },
        xAxis: {
          type: "value",
          splitLine: {
            show: false,
          },
          axisLabel: {
            show: false,
          },
        },
        yAxis: {
          type: "category",
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            show: false,
          },
          data: ["数量"],
        },
        series: [
          {
            name: "会员",
            type: "bar",
            stack: "total",
            emphasis: {
              // focus: 'series'
            },
            label: {
              show: false,
            },
            itemStyle: {
              color: "#1EF8FF",
              // borderColor: '#fff',
              borderWidth: 1,
            },
            barWidth: 27,
            data: [infoNum],
          },
          {
            name: "",
            type: "bar",
            stack: "total",
            emphasis: {
              // focus: 'series'
            },
            label: {
              show: false,
            },
            itemStyle: {
              color: "rgba(255, 255, 255, .1)",
              // borderColor: 'rgba(255, 255, 255, .1)',
              borderWidth: 10,
            },
            barWidth: 27,
            data: [0],
          },
          {
            name: "",
            type: "bar",
            stack: "total",
            emphasis: {
              // focus: 'series'
            },
            label: {
              show: false,
            },
            itemStyle: {
              color: "#FBB351",
              // borderColor: 'rgba(255, 255, 255, .1)',
              borderWidth: 10,
            },
            barWidth: 27,
            data: [alarmNum],
          },
          {
            name: "",
            type: "bar",
            stack: "total",
            emphasis: {
              // focus: 'series'
            },
            label: {
              show: false,
            },
            itemStyle: {
              color: "rgba(255, 255, 255, .1)",
              // borderColor: 'rgba(255, 255, 255, .1)',
              borderWidth: 10,
            },
            barWidth: 27,
            data: [0],
          },
          {
            name: "理事",
            type: "bar",
            stack: "total",
            label: {
              show: false,
            },
            itemStyle: {
              color: "#FF6E6F",
              // borderColor: '#fff',
              borderWidth: 2,
            },
            barWidth: 27,
            emphasis: {
              // focus: 'series'
            },
            data: [faultNum],
          },
        ],
      });
      // window.addEventListener('resize', () => {
      //   this.myChart.resize()
      // })
    },
    //  饼图
    careAgedEcharts(onlineNum, offlineNum, faultNum) {
      // if (this.myechart != null && this.myechart != '' && this.myechart != undefined) {
      //   this.myechart.dispose()
      // }
      // 基于准备好的dom，初始化echarts实例
      this.myechart = echarts.init(this.$refs.careAgedEcharts);
      // 绘制图表
      this.myechart.setOption({
        color: ["#199BFF", "#4CE9E9", "#F8B051"], //饼状图颜⾊数组
        tooltip: {
          trigger: "item",
          //提示框样式调整
          textStyle: {
            color: "#e6e6e6",
            fontFamily: "SourceHanSansCN-Regular",
            fontSize: 14,
          },
          extraCssText:
            "border: 1px solid #e6e6e6;color: #ccc;font-family:'SourceHanSansCN-Regular';background:rgba(39, 69, 86,.8);font-size:14px;",
        },
        series: [
          {
            type: "pie",
            radius: ["70%", "90%"],
            avoidLabelOverlap: false,

            label: {
              show: false,
              position: "center",
            },
            emphasis: {
              label: {
                show: false,
                fontSize: "14",
                fontWeight: "bold",
              },
            },
            labelLine: {
              show: false,
            },
            data: [onlineNum, offlineNum, faultNum],
          },
        ],
      });
      // window.addEventListener('resize', () => {
      //   this.myechart.resize()
      // })
    },
  },
};
</script>

<style lang="less" scoped>
.home_box {
  width: 2.224rem;
  height: 5.1823rem;
  position: absolute;
  right: 0.125rem;
  top: 0.4427rem;
  // 空调概览
  .PowerconsumptionBox {
    height: 0.5833rem;
    margin: 0.112rem 0 0.0651rem;

    ul {
      li {
        display: flex;
        align-items: center;
        justify-content: space-around;

        img {
          width: 0.6563rem;
          height: 0.5833rem;
        }

        .powerBox {
          .title {
            font-size: 0.1198rem;
            font-family: SourceHanSansCN-Medium;
            font-weight: 500;
            color: #ffffff;
            letter-spacing: 0.0052rem;
            text-align: center;
            height: 0.09rem;
          }

          .num {
            width: 0.4479rem;
            height: 0.21875rem;
            line-height: 0.2rem;
            font-size: 0.2292rem;
            font-family: YouSheBiaoTiHei;
            font-weight: 400;
            color: #ffffff;
            text-align: center;
            // background: url("~@/assets/imgs/Energy/analysis_bcg.png") no-repeat
            //   center / cover;
            // background-size: 100% 100%;
          }
        }
      }
    }
  }

  // 空调数据
  .AirconditioningBox {
    height: 0.5833rem;
    margin: 0.1667rem 0 0.2708rem;

    ul {
      display: flex;
      align-items: center;
      justify-content: space-around;

      li {
        display: flex;

        .powerBox {
          .title {
            font-size: 0.0781rem;
            font-family: SourceHanSansCN-Regular;
            font-weight: 400;
            color: #e7e7e7;
            letter-spacing: 0.0052rem;
            height: 0.09rem;
            line-height: 0.09rem;
          }

          .numBox {
            display: flex;
            //align-items: center;
            justify-content: center;
            width: 0.4271rem;
            height: 0.4427rem;
            line-height: 0.4427rem;
            background: url("~@/assets/imgs/HVAC/currentAir_icon.png") no-repeat
              center / cover;
            background-size: 100% 100%;

            .num {
              font-size: 0.1458rem;
              font-family: YouSheBiaoTiHei;
              font-weight: 400;
              color: #ffffff;
              text-shadow: 0px 0px 0.013rem rgba(19, 159, 255, 0.8);
              padding-right: 0.0052rem;
            }

            .type {
              line-height: 0.4688rem;
              font-family: YouSheBiaoTiHei;
              font-weight: 400;
              color: #ffffff;
              font-size: 0.0833rem;
            }
          }
        }
      }
    }
  }

  //   告警总数
  .devicealarmsBox {
    display: flex;
    align-items: center;
    // margin: 0.1068rem 0 0.0807rem;

    .title {
      font-size: 0.0781rem;
      font-family: SourceHanSansCN-Medium;
      font-weight: 500;
      color: #ffffff;
    }

    .num {
      font-size: 0.125rem;
      font-family: YouSheBiaoTiHei;
      font-weight: 400;
      color: #1ef8ff;
    }
  }

  //   图表
  .Stack_Graph_box {
    width: 2.224rem;
    height: 0.125rem;
    background: url("~@/assets/imgs/Energy/line_bcg.png") no-repeat center /
      cover;
    background-size: 100% 100%;

    .Stack_Graph {
      padding: 0.01rem 0;
      height: 0.125rem;
    }
  }

  // 饼图
  .echartsBox {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 0.8906rem;
    padding: 0 0.02rem;
    margin: 0.09375rem 0 0.1068rem;

    //   饼图
    .echarts_bcg {
      position: relative;
      height: 0.6927rem;
      .colorBcg {
        position: relative;
        top: 0.0821rem;
        left: 20%;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 0.5156rem;
        height: 0.5156rem;
        background: #00325a;
        border-radius: 50%;
        font-size: 0.0885rem;
        font-family: SourceHanSansCN-Bold;
        font-weight: bold;
        color: #e6e6e6;
        padding: 0.055rem;
        text-align: center;
      }

      .careAgedEcharts {
        position: absolute;
        top: 0;
        width: 0.6927rem;
        height: 0.6927rem;
      }
    }

    //   饼图信息
    .PieBox {
      width: 50%;
      //margin-top: 0.0521rem;
      ul {
        li {
          display: flex;
          justify-content: space-evenly;
          align-items: center;
          height: 0.1875rem;

          .title {
            display: flex;
            align-items: center;

            .blue {
              width: 0.0833rem;
              height: 0.0833rem;
              background: #199afd;
              border-radius: 50%;
              opacity: 1;
            }

            .skyblue {
              width: 0.0833rem;
              height: 0.0833rem;
              background: #4be8e9;
              border-radius: 50%;
              opacity: 1;
            }

            .orange {
              width: 0.0833rem;
              height: 0.0833rem;
              background: #f8b051;
              border-radius: 50%;
              opacity: 1;
            }

            .name {
              font-size: 0.0938rem;
              font-family: SourceHanSansCN-Medium;
              font-weight: 500;
              color: #e6e6e6;
              margin: 0 0.151rem 0 0.0573rem;
            }
          }

          .num {
            width: 40%;
            font-size: 0.1458rem;
            font-family: YouSheBiaoTiHei;
            font-weight: 400;
            color: #e6e6e6;
            text-align: right;
          }
        }
      }
    }
  }

  //   图表信息
  .EchartsnewsBox {
    margin: 0.1198rem 0 0.2448rem;

    ul {
      display: flex;
      justify-content: space-around;

      li {
        display: flex;
        align-items: center;

        .title {
          display: flex;
          align-items: center;

          .name {
            font-size: 0.0729rem;
            font-family: SourceHanSansCN-Medium;
            font-weight: 500;
            color: #e6e6e6;
            margin: 0 0.0469rem 0 0.0156rem;
          }
        }

        .num {
          font-size: 0.1146rem;
          font-family: YouSheBiaoTiHei;
          font-weight: 400;
          color: #ffffff;
        }

        .skyblue {
          width: 0.0729rem;
          height: 0.0729rem;
          background: #1ef8ff;
          border-radius: 0px 0px 0px 0px;
          opacity: 1;
        }

        .orange {
          width: 0.0729rem;
          height: 0.0729rem;
          background: #fbb351;
          border-radius: 0px 0px 0px 0px;
          opacity: 1;
        }

        .cameobrown {
          width: 0.0729rem;
          height: 0.0729rem;
          background: #ff6e6f;
          border-radius: 0px 0px 0px 0px;
          opacity: 1;
        }
      }
    }
  }

  // 环境监测
  .environmentalonitoringBox {
    height: 0.2917rem;
    margin: 0.2083rem 0 0.0651rem;

    ul {
      li {
        display: flex;
        align-items: center;
        justify-content: space-around;

        .envirBox {
          display: flex;
          justify-content: space-between;
          align-items: center;
          //   margin-top: 0.2083rem;

          img {
            width: 0.4323rem;
            height: 0.4323rem;
          }

          .powerBox {
            margin-left: 0.03125rem;

            .title {
              height: 0.1406rem;
              font-size: 0.099rem;
              font-weight: 500;
              color: #ffffff;
              font-family: SourceHanSansCN-Medium;
              letter-spacing: 0.0052rem;
              text-align: center;
              opacity: 0.8;
              line-height: 0.1406rem;
            }

            .numbox {
              display: flex;
              justify-content: center;
              align-items: center;

              .num {
                font-size: 0.1875rem;
                line-height: 0.2448rem;
                font-family: YouSheBiaoTiHei;
                font-weight: 400;
                color: #ffffff;
                text-align: center;
              }

              .type {
                font-size: 0.1042rem;
                font-family: YouSheBiaoTiHei;
                font-weight: 400;
                color: #ffffff;
                letter-spacing: 0.0052rem;
              }
            }
          }
        }
      }
    }
  }
}
</style>
