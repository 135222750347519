<template>
  <div class="page_right">
    <Title :titleList="titleList"></Title>
    <div class="campus_tab">
      <div
        :class="campusTabs == index ? 'activeItem' : 'item'"
        v-for="(item, index) in campusTab"
        :key="index"
        @click="changeCampusTab(index)"
      >
        {{ item }}
      </div>
    </div>
    <div class="school_building_situation">
      <smallTitle :titleList="schoolData"></smallTitle>
      <div class="content">
        <div class="item" v-for="(item, index) in schoolDatas" :key="index">
          <p class="name">{{ item.name }}</p>
          <p>
            <span class="value">{{ item.value }}</span>
            <span class="unit">{{ item.unit }}</span>
          </p>
        </div>
      </div>
    </div>
    <div class="discipline_construction">
      <smallTitle :titleList="disciplineData"></smallTitle>
      <div class="content">
        <div class="item">
          <p>{{ disciplineList.BKZY }}个</p>
          <p>本科专业</p>
        </div>
        <div class="item">
          <p>{{ disciplineList.YJXK }}个</p>
          <p>一级学科</p>
        </div>
        <div class="subject">
          <p>学科门类</p>
          <p>{{ disciplineList.XKML }}类</p>
        </div>
        <div class="item">
          <p>{{ disciplineList.YLXK }}个</p>
          <p>一流学科</p>
        </div>
        <div class="item">
          <p>{{ disciplineList.YLZY }}个</p>
          <p>一流专业</p>
        </div>
      </div>
    </div>
    <div class="research_situation">
      <smallTitle :titleList="researchData"></smallTitle>
      <div class="research_chart">
        <div id="research_chart"></div>
      </div>
      <div class="funds_chart">
        <div id="funds_chart"></div>
      </div>
    </div>
  </div>
</template>

<script>
import * as echarts from "echarts";
import Title from "@/components/Title.vue";
import smallTitle from "@/components/smallTitle.vue";
import {
  schoolConditions,
  teachersAndStudents,
  Campus,
  culture,
  teaching,
  schoolBuilding,
  discipline,
  research,
  researchNum,
  researchMoneyNum,
} from "@/api/common";
export default {
  components: { Title, smallTitle },
  data() {
    return {
      campusTab: ["拱宸桥校区", "杨汛桥校区"],
      campusTabs: 0,
      titleList: {
        title: "校情概览",
      },
      titleData: {
        title: "办学条件",
      },
      teaStuInfo: {
        title: "师生基本情况及概览",
      },
      teachingData: {
        title: "教学项目情况（近五年）",
      },
      schoolData: {
        title: "校舍情况",
      },
      schoolDatas: [
        {
          name: "占地面积：",
          value: "427.54",
          unit: "亩",
        },
        {
          name: "校舍面积：",
          value: "348626.73",
          unit: "㎡",
        },
        {
          name: "生均校舍面积：",
          value: "25.99",
          unit: "㎡",
        },
        {
          name: "教学科研及辅助用房：",
          value: "11.05",
          unit: "㎡",
        },
        {
          name: "行政及辅助用房：",
          value: "0.80",
          unit: "㎡",
        },
      ],
      teachingDatas: [
        {
          name: "国家级教学成果奖",
          value: "2",
          unit: "个",
        },
        {
          name: "国家级课程",
          value: "1",
          unit: "门",
        },
        {
          name: "国家级思政平台/课",
          value: "0",
          unit: "个",
        },
        {
          name: "省部级教学成果奖",
          value: "3",
          unit: "个",
        },
        {
          name: "省级课程",
          value: "65",
          unit: "门",
        },
        {
          name: "省级思政课程",
          value: "5",
          unit: "门",
        },
        {
          name: "省级思政平台",
          value: "5",
          unit: "个",
        },
        {
          name: "在线课程",
          value: "1456",
          unit: "门",
        },
      ],
      disciplineData: {
        title: "学科建设",
      },
      disciplineList: {},
      researchData: {
        title: "科研情况（近五年）",
      },
      office_details: [
        {
          name: "生师比",
          value: "1：15.4",
          unit: "",
        },
        {
          name: "硕士及以上专任教师比例",
          value: "95.33",
          unit: "%",
        },
        {
          name: "教学科研仪器设备值",
          value: "13916",
          unit: "元",
        },
        {
          name: "图书",
          value: "194.28",
          unit: "万册",
        },
        {
          name: "本年度教学经费",
          value: "6031.78",
          unit: "万元",
        },
        {
          name: "本年度新增设备",
          value: "3973.28",
          unit: "万元",
        },
      ],
      cultivate: [
        {
          name: "四级通过率",
          value: "25",
          unit: "%",
        },
        {
          name: "六级通过率",
          value: "17",
          unit: "%",
        },
        {
          name: "就业率",
          value: "95.33",
          unit: "%",
        },
        {
          name: "读研率",
          value: "4.03",
          unit: "%",
        },
      ],
      research_: [
        {
          name: "国家级科研奖",
          value: "1项",
          // unit: "%",
        },
        {
          name: "省部级以上科研奖",
          value: "9项",
          // unit: "%",
        },
        {
          name: "省部级重点学科数",
          value: "8个",
          // unit: "%",
        },
        {
          name: "省部级重点实验室",
          value: "8个",
          // unit: "%",
        },
      ],
      totalStudent: 0,
      totalStudents: 0,
      schoolBuildingData: {},
    };
  },
  mounted() {
    // this.campusChart();
    // this.cultureChart();
    // this.researchChart();
    // this.fundsChart();
    this.schoolConditionsApi();
    this.teachersAndStudentsApi();
    // this.CampusApi();
    this.cultureApi();
    this.teachingApi();
    this.schoolBuildingApi();
    this.disciplineApi();
    this.researchApi();
    this.researchNumApi();
    this.researchMoneyNumApi();
  },
  methods: {
    //办学条件
    schoolConditionsApi() {
      schoolConditions().then((res) => {
        // console.log(res.data.content[0], "办学条件");
        let data = res.data.content[0];
        this.office_details[0].value = data.ZRJSSSB;
        this.office_details[1].value =
          data.SSJYSZRJSBL.match(/\d+(\.\d+)?/g)[0];
        this.office_details[2].value = data.JXKYYQSBZ.match(/\d+(\.\d+)?/g)[0];
        this.office_details[3].value = data.TS.match(/\d+(\.\d+)?/g)[0];
        this.office_details[4].value = data.BNDJXJF.match(/\d+(\.\d+)?/g)[0];
        this.office_details[5].value = data.BNDXZSB.match(/\d+(\.\d+)?/g)[0];
      });
    },
    // 师生基本情况
    teachersAndStudentsApi() {
      teachersAndStudents().then((res) => {
        let data = res.data.content[0];
        // console.log(data, "师生基本情况");
        this.totalStudent = data.ZXXSS;
        this.cultivate[0].value = data.SJTGL.match(/\d+(\.\d+)?/g)[0];
        this.cultivate[1].value = data.LJTGL.match(/\d+(\.\d+)?/g)[0];
        this.cultivate[2].value = data.JYL.match(/\d+(\.\d+)?/g)[0];
        this.cultivate[3].value = data.DYL.match(/\d+(\.\d+)?/g)[0];
      });
    },
    // // 按校区师生基本情况
    // CampusApi() {
    //   Campus().then((res) => {
    //     console.log(res.data.content, "按校区师生基本情况");
    //     let seriesData = res.data.content.map((item) => {
    //       return {
    //         name: item.XQ,
    //         value: item.ZXXSS,
    //       };
    //     });
    //     this.campusChart(seriesData);
    //   });
    // },
    // 按培养师生基本情况
    cultureApi() {
      culture().then((res) => {
        console.log(res.data, "按培养师生基本情况");
        let seriesData = res.data.content.map((item) => {
          this.totalStudents += Number(item.ZXXSS);
          return {
            name: item.PYPCC,
            value: item.ZXXSS,
          };
        });
        // this.cultureChart(seriesData);
      });
    },
    //教学项目情况
    teachingApi() {
      teaching().then((res) => {
        console.log(res.data.content, "教学项目情况");
        this.teachingDatas[0].value = res.data.content[0].JWNGJJJXCGJ;
        this.teachingDatas[1].value = res.data.content[0].JWNGJJKC;
        this.teachingDatas[2].value = res.data.content[0].JWNGJJSZPT;
        this.teachingDatas[3].value = res.data.content[0].JWNSBJJXCGJ;
        this.teachingDatas[4].value = res.data.content[0].JWNSJKC;
        this.teachingDatas[5].value = res.data.content[0].JWNGJJSZKC;
        this.teachingDatas[6].value = res.data.content[0].JWNSJSZPTKC;
        this.teachingDatas[7].value = res.data.content[0].ZXKC;
      });
    },
    //校舍情况
    schoolBuildingApi() {
      schoolBuilding().then((res) => {
        console.log(res.data.content, "校舍情况");
        this.schoolBuildingData = res.data.content[0];
        this.schoolDatas[0].value =
          this.schoolBuildingData.GCQXQZDMJ.match(/\d+(\.\d+)?/g)[0];
        this.schoolDatas[1].value =
          this.schoolBuildingData.GCQXQXSMJ.match(/\d+(\.\d+)?/g)[0];
        this.schoolDatas[2].value =
          this.schoolBuildingData.SJXSMJ.match(/\d+(\.\d+)?/g)[0];
        this.schoolDatas[3].value =
          this.schoolBuildingData.JXKYJFDYF.match(/\d+(\.\d+)?/g)[0];
        this.schoolDatas[4].value =
          this.schoolBuildingData.XZJFZYF.match(/\d+(\.\d+)?/g)[0];
      });
    },
    // 学科建设
    disciplineApi() {
      discipline().then((res) => {
        console.log(res.data.content, "学科建设");
        this.disciplineList = res.data.content[0];
      });
    },
    //科研情况
    researchApi() {
      research().then((res) => {
        console.log(res.data.content, "科研情况");
        this.research_[0].value = res.data.content[0].JWNKYHGJJJ;
        this.research_[1].value = res.data.content[0].JWNSBJJYSJL;
        this.research_[2].value = res.data.content[0].JWNSBJZDXKS;
        this.research_[3].value = res.data.content[0].JWNSBJZDSYS;
      });
    },
    //科研数量统计
    researchNumApi() {
      researchNum().then((res) => {
        console.log(res.data.content, "科研数量统计");
        let arr = res.data.content.filter((item) => item.XMJB == "国家级");
        let arr1 = res.data.content.filter((item) => item.XMJB == "省部级");
        let xAxisData = arr.map((item) => {
          return item.NF;
        });
        let seriesData1 = arr.map((item) => {
          return item.KYSL;
        });
        let seriesData2 = arr1.map((item) => {
          return item.KYSL;
        });
        // console.log(xAxisData, seriesData1, seriesData2);
        this.researchChart(xAxisData, seriesData1, seriesData2);
      });
    },
    //科研经费
    researchMoneyNumApi() {
      researchMoneyNum().then((res) => {
        console.log(res.data.content, "科研经费");
        let xAxisData = res.data.content.map((item) => {
          return item.NF;
        });
        let seriesData = res.data.content.map((item) => {
          return item.KYJFDZQQ;
        });
        this.fundsChart(xAxisData, seriesData);
      });
    },
    // campusChart(seriesData) {
    //   let myChartMachine = echarts.init(
    //     document.getElementById("campus_chart")
    //   );
    //   // 圆环图各环节的颜色
    //   var color = ["rgba(25, 155, 255, 1)", "rgba(76, 233, 233, 1)"];

    //   // 圆环图各环节的名称和值(系列中各数据项的名称和值)
    //   var data = seriesData;

    //   let option = {
    //     // 图例
    //     legend: [
    //       {
    //         icon: "circle",
    //         textStyle: {
    //           color: "#2efcfa",
    //         },
    //         orient: "vertical",
    //         // top: "middle",
    //         top: 0,
    //         itemWidth: 10,
    //         itemHeight: 10,
    //         data: data.slice(0, 1),
    //         y: "top",
    //         formatter: (name) => {
    //           // 添加
    //           let total = 0;
    //           let target;
    //           for (let i = 0; i < data.length; i++) {
    //             total += data[i].value;
    //             if (data[i].name === name) {
    //               target = data[i].value;
    //             }
    //           }
    //           var arr = [];
    //           return `{a|${name}}{b|${target}人}`;
    //         },
    //         textStyle: {
    //           // 添加
    //           padding: [0, 0, 0, 0],
    //           rich: {
    //             a: {
    //               // 设置文字、数学、英语这一列的样式
    //               // width: 60,
    //               color: "rgba(231, 231, 231, 1)",
    //               fontSize: this.fontSize(14),
    //               // fontWeight: "bolder",
    //               verticalAlign: "middle",
    //             },
    //             b: {
    //               // 设置文字、数学、英语这一列的样式
    //               color: "rgba(231, 231, 231, 1)",
    //               fontSize: this.fontSize(20),
    //               fontFamily: "DigitalRegular",
    //               verticalAlign: "middle",
    //             },
    //             c: {
    //               // 设置文字、数学、英语这一列的样式
    //               // width: 60,
    //               color: "rgba(231, 231, 231, 1)",
    //               fontSize: this.fontSize(8),
    //               // fontWeight: "bolder",
    //               verticalAlign: "middle",
    //             },
    //           },
    //         },
    //       },
    //       {
    //         icon: "circle",
    //         textStyle: {
    //           color: "#2efcfa",
    //         },
    //         orient: "vertical",
    //         // top: "middle",
    //         itemWidth: 10,
    //         itemHeight: 10,
    //         data: data.slice(1, 2),
    //         y: "bottom",
    //         bottom: 0,
    //         formatter: (name) => {
    //           // 添加
    //           let total = 0;
    //           let target;
    //           for (let i = 0; i < data.length; i++) {
    //             total += data[i].value;
    //             if (data[i].name === name) {
    //               target = data[i].value;
    //             }
    //           }
    //           var arr = [];
    //           return `{a|${name}} {b|${target}人}`;
    //         },
    //         textStyle: {
    //           // 添加
    //           padding: [0, 0, 0, 0],
    //           rich: {
    //             a: {
    //               // 设置文字、数学、英语这一列的样式
    //               // width: 60,
    //               color: "rgba(231, 231, 231, 1)",
    //               fontSize: this.fontSize(14),
    //               // fontWeight: "bolder",
    //               verticalAlign: "middle",
    //             },
    //             b: {
    //               // 设置文字、数学、英语这一列的样式
    //               color: "rgba(231, 231, 231, 1)",
    //               fontSize: this.fontSize(20),
    //               fontFamily: "DigitalRegular",
    //               verticalAlign: "middle",
    //             },
    //             c: {
    //               // 设置文字、数学、英语这一列的样式
    //               // width: 60,
    //               color: "rgba(231, 231, 231, 1)",
    //               fontSize: this.fontSize(8),
    //               // fontWeight: "bolder",
    //               verticalAlign: "middle",
    //             },
    //           },
    //         },
    //       },
    //     ],

    //     // // 提示框
    //     // tooltip: {
    //     //   show: true, // 是否显示提示框
    //     //   formatter: "{b} </br> 攻击{c}次 </br> 占比{d}%", // 提示框显示内容,此处{b}表示各数据项名称，此项配置为默认显示项，{c}表示数据项的值，默认不显示，({d}%)表示数据项项占比，默认不显示。
    //     // },

    //     // graphic 是原生图形元素组件。可以支持的图形元素包括：image, text, circle, sector, ring, polygon, polyline, rect, line, bezierCurve, arc, group,

    //     // 系列列表
    //     series: [
    //       {
    //         name: "圆环图系列名称", // 系列名称
    //         type: "pie", // 系列类型
    //         center: ["50%", "50%"], // 饼图的中心（圆心）坐标，数组的第一项是横坐标，第二项是纵坐标。[ default: ['50%', '50%'] ]
    //         radius: ["40%", "55%"], // 饼图的半径，数组的第一项是内半径，第二项是外半径。[ default: [0, '75%'] ]
    //         hoverAnimation: true, // 是否开启 hover 在扇区上的放大动画效果。[ default: true ]
    //         color: color, // 圆环图的颜色
    //         label: {
    //           // 饼图图形上的文本标签，可用于说明图形的一些数据信息，比如值，名称等.
    //           normal: {
    //             show: true, // 是否显示标签[ default: false ]
    //             position: "center", // 标签的位置。'outside'饼图扇区外侧，通过视觉引导线连到相应的扇区。'inside','inner' 同 'inside',饼图扇区内部。'center'在饼图中心位置。
    //             formatter: "校区", // 标签内容
    //             fontSize: this.fontSize(14),
    //             color: "#fff",
    //           },
    //         },
    //         // labelLine: {
    //         //   // 标签的视觉引导线样式,在 label 位置 设置为'outside'的时候会显示视觉引导线。
    //         //   normal: {
    //         //     show: true, // 是否显示视觉引导线。
    //         //     length: 15, // 在 label 位置 设置为'outside'的时候会显示视觉引导线。
    //         //     length2: 10, // 视觉引导项第二段的长度。
    //         //     lineStyle: {
    //         //       // 视觉引导线的样式
    //         //       //color: '#000',
    //         //       //width: 1
    //         //     },
    //         //   },
    //         // },
    //         data: data, // 系列中的数据内容数组。
    //       },
    //     ],
    //   };
    //   myChartMachine.setOption(option, true);
    //   window.addEventListener("resize", () => {
    //     if (myChartMachine) {
    //       myChartMachine.resize();
    //     }
    //   });
    // },
    // cultureChart(seriesData) {
    //   let myChartMachine = echarts.init(
    //     document.getElementById("culture_chart")
    //   );
    //   // 圆环图各环节的颜色
    //   var color = [
    //     "rgba(25, 155, 255, 1)",
    //     "rgba(248, 178, 83, 1)",
    //     "rgba(75, 232, 233, 1)",
    //     "rgba(255, 110, 111, 1)",
    //     "rgba(129, 223, 159, 1)",
    //   ];

    //   // 圆环图各环节的名称和值(系列中各数据项的名称和值)
    //   var data = seriesData;

    //   let option = {
    //     // 图例
    //     legend: [
    //       {
    //         icon: "circle",
    //         textStyle: {
    //           color: "#2efcfa",
    //         },
    //         orient: "vertical",
    //         top: "middle",
    //         itemGap: 0,
    //         // top: 0,
    //         itemWidth: 10,
    //         itemHeight: 10,
    //         data: data,
    //         right: 0,
    //         formatter: (name) => {
    //           // 添加
    //           let total = 0;
    //           let target;
    //           for (let i = 0; i < data.length; i++) {
    //             total += data[i].value;
    //             if (data[i].name === name) {
    //               target = data[i].value;
    //             }
    //           }
    //           var arr = [];
    //           return `{a|${name}} {b|${target}人}`;
    //         },
    //         textStyle: {
    //           // 添加
    //           padding: [0, 0, 0, 0],
    //           rich: {
    //             a: {
    //               // 设置文字、数学、英语这一列的样式
    //               width: this.fontSize(50),
    //               color: "rgba(231, 231, 231, 1)",
    //               fontSize: this.fontSize(14),
    //               // fontWeight: "bolder",
    //               verticalAlign: "middle",
    //             },
    //             b: {
    //               // 设置文字、数学、英语这一列的样式
    //               color: "rgba(231, 231, 231, 1)",
    //               fontSize: this.fontSize(20),
    //               fontFamily: "DigitalRegular",
    //               verticalAlign: "middle",
    //             },
    //           },
    //         },
    //       },
    //       // {
    //       //   icon: "circle",
    //       //   textStyle: {
    //       //     color: "#2efcfa",
    //       //   },
    //       //   orient: "vertical",
    //       //   // top: "middle",
    //       //   itemWidth: 6,
    //       //   itemHeight: 6,
    //       //   data: data.slice(1, 2),
    //       //   y: "bottom",
    //       //   bottom: 0,
    //       //   formatter: (name) => {
    //       //     // 添加
    //       //     let total = 0;
    //       //     let target;
    //       //     for (let i = 0; i < data.length; i++) {
    //       //       total += data[i].value;
    //       //       if (data[i].name === name) {
    //       //         target = data[i].value;
    //       //       }
    //       //     }
    //       //     var arr = [];
    //       //     return `{a|${name}} {b|${target}人}`;
    //       //   },
    //       //   textStyle: {
    //       //     // 添加
    //       //     padding: [0, 0, 0, 0],
    //       //     rich: {
    //       //       a: {
    //       //         // 设置文字、数学、英语这一列的样式
    //       //         // width: 60,
    //       //         color: "rgba(231, 231, 231, 1)",
    //       //         fontSize: 8,
    //       //         // fontWeight: "bolder",
    //       //         verticalAlign: "middle",
    //       //       },
    //       //       b: {
    //       //         // 设置文字、数学、英语这一列的样式
    //       //         color: "rgba(231, 231, 231, 1)",
    //       //         fontSize: 12,
    //       //         fontFamily: "DigitalRegular",
    //       //         verticalAlign: "middle",
    //       //       },
    //       //     },
    //       //   },
    //       // },
    //     ],

    //     // // 提示框
    //     // tooltip: {
    //     //   show: true, // 是否显示提示框
    //     //   formatter: "{b} </br> 攻击{c}次 </br> 占比{d}%", // 提示框显示内容,此处{b}表示各数据项名称，此项配置为默认显示项，{c}表示数据项的值，默认不显示，({d}%)表示数据项项占比，默认不显示。
    //     // },

    //     // graphic 是原生图形元素组件。可以支持的图形元素包括：image, text, circle, sector, ring, polygon, polyline, rect, line, bezierCurve, arc, group,

    //     // 系列列表
    //     series: [
    //       {
    //         name: "圆环图系列名称", // 系列名称
    //         type: "pie", // 系列类型
    //         center: ["30%", "50%"], // 饼图的中心（圆心）坐标，数组的第一项是横坐标，第二项是纵坐标。[ default: ['50%', '50%'] ]
    //         radius: ["50%", "65%"], // 饼图的半径，数组的第一项是内半径，第二项是外半径。[ default: [0, '75%'] ]
    //         hoverAnimation: true, // 是否开启 hover 在扇区上的放大动画效果。[ default: true ]
    //         color: color, // 圆环图的颜色
    //         label: {
    //           width: 12,
    //           // 饼图图形上的文本标签，可用于说明图形的一些数据信息，比如值，名称等.
    //           normal: {
    //             show: true, // 是否显示标签[ default: false ]
    //             position: "center", // 标签的位置。'outside'饼图扇区外侧，通过视觉引导线连到相应的扇区。'inside','inner' 同 'inside',饼图扇区内部。'center'在饼图中心位置。
    //             formatter: "培养\n层次", // 标签内容
    //             fontSize: this.fontSize(18),
    //             color: "#fff",
    //           },
    //         },
    //         // labelLine: {
    //         //   // 标签的视觉引导线样式,在 label 位置 设置为'outside'的时候会显示视觉引导线。
    //         //   normal: {
    //         //     show: true, // 是否显示视觉引导线。
    //         //     length: 15, // 在 label 位置 设置为'outside'的时候会显示视觉引导线。
    //         //     length2: 10, // 视觉引导项第二段的长度。
    //         //     lineStyle: {
    //         //       // 视觉引导线的样式
    //         //       //color: '#000',
    //         //       //width: 1
    //         //     },
    //         //   },
    //         // },
    //         data: data, // 系列中的数据内容数组。
    //       },
    //     ],
    //   };
    //   myChartMachine.setOption(option, true);
    //   window.addEventListener("resize", () => {
    //     if (myChartMachine) {
    //       myChartMachine.resize();
    //     }
    //   });
    // },
    changeCampusTab(index) {
      this.campusTabs = index;
      if (index == 0) {
        this.schoolDatas[0].value =
          this.schoolBuildingData.GCQXQZDMJ.match(/\d+(\.\d+)?/g)[0];
        this.schoolDatas[1].value =
          this.schoolBuildingData.GCQXQXSMJ.match(/\d+(\.\d+)?/g)[0];
        this.schoolDatas[2].value =
          this.schoolBuildingData.SJXSMJ.match(/\d+(\.\d+)?/g)[0];
        this.schoolDatas[3].value =
          this.schoolBuildingData.JXKYJFDYF.match(/\d+(\.\d+)?/g)[0];
        this.schoolDatas[4].value =
          this.schoolBuildingData.XZJFZYF.match(/\d+(\.\d+)?/g)[0];
      } else {
        this.schoolDatas[0].value =
          this.schoolBuildingData.YXQXQZDMJ.match(/\d+(\.\d+)?/g)[0];
        this.schoolDatas[1].value =
          this.schoolBuildingData.YXQXQXSMJ.match(/\d+(\.\d+)?/g)[0];
        this.schoolDatas[2].value =
          this.schoolBuildingData.SJXSMJ.match(/\d+(\.\d+)?/g)[0];
        this.schoolDatas[3].value =
          this.schoolBuildingData.JXKYJFDYF.match(/\d+(\.\d+)?/g)[0];
        this.schoolDatas[4].value =
          this.schoolBuildingData.XZJFZYF.match(/\d+(\.\d+)?/g)[0];
      }
    },
    researchChart(xAxisData, seriesData1, seriesData2) {
      let myChartMachine = echarts.init(
        document.getElementById("research_chart")
      );
      let option = {
        color: ["rgba(50, 229, 255, 1)", "rgba(250, 207, 18, 1)"],
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
            crossStyle: {
              color: "#999",
            },
          },
        },
        title: {
          text: "科研数量（项）",
          top: 0,
          textStyle: {
            color: "#fff",
            fontSize: this.fontSize(14),
          },
        },
        legend: {
          itemWidth: 18,
          itemHeight: 10,
          right: 0,
          textStyle: {
            color: "#fff",
            fontSize: this.fontSize(14),
          },
        },
        grid: {
          top: "20%",
          left: "5%",
          right: "5%",
          bottom: "5%",
          containLabel: true,
        },
        xAxis: [
          {
            type: "category",
            data: xAxisData,
            boundaryGap: false,
            axisLine: {
              show: true,
              lineStyle: {
                color: "rgba(230, 230, 230, .1)",
              },
            },
            axisLabel: {
              color: "rgba(230, 230, 230, 1)",
              fontSize: this.fontSize(14),
            },
            axisTick: {
              show: false,
            },
            splitLine: {
              show: false,
              lineStyle: {
                color: "blue",
                width: 1,
                type: "solid",
              },
            },
            axisPointer: {
              type: "shadow",
            },
          },
        ],
        yAxis: [
          {
            type: "value",
            axisLabel: {
              color: "rgba(167, 177, 196, 1)",
              fontSize: this.fontSize(14),
            },
            axisLine: {
              show: false,
              lineStyle: {
                color: "#FFDEAD",
              },
            },
            splitLine: {
              show: false,
              lineStyle: {
                color: "blue",
                width: 1,
                type: "solid",
              },
            },
          },
        ],
        series: [
          {
            name: "国家级",
            type: "bar",
            data: seriesData1,
            barWidth: 10, // 柱状图的宽度
            itemStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: "rgba(204, 217, 241, 0.80)" },
                { offset: 1, color: "rgba(204, 217, 241, 0)" },
              ]),
            },
          },
          {
            name: "省级",
            type: "line",
            yAxisIndex: 0,
            symbol: "circle",
            data: seriesData2,
            itemStyle: {
              color: "rgba(89, 238, 242, 1)",
            },
            areaStyle: {
              color: new echarts.graphic.LinearGradient(0, 1, 0, 0, [
                {
                  offset: 0,
                  color: "rgba(6, 97, 249, 0)",
                },
                {
                  offset: 1,
                  color: "rgba(89, 238, 242, 0.80)",
                },
              ]),
            },
            showSymbol: false,
          },
        ],
      };
      myChartMachine.setOption(option, true);
      window.addEventListener("resize", () => {
        if (myChartMachine) {
          myChartMachine.resize();
        }
      });
    },
    fundsChart(xAxisData, seriesData) {
      let myChartMachine = echarts.init(document.getElementById("funds_chart"));
      let option = {
        color: ["rgba(50, 229, 255, 1)", "rgba(250, 207, 18, 1)"],
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
            crossStyle: {
              color: "#999",
            },
          },
        },
        title: {
          text: "协议经费情况（万元）",
          top: 0,
          textStyle: {
            color: "#fff",
            fontSize: this.fontSize(14),
          },
        },
        legend: {
          itemWidth: 18,
          itemHeight: 10,
          right: 0,
          textStyle: {
            color: "#fff",
            fontSize: this.fontSize(14),
          },
        },
        grid: {
          top: "20%",
          left: "5%",
          right: "5%",
          bottom: "5%",
          containLabel: true,
        },
        xAxis: [
          {
            type: "category",
            data: xAxisData,
            boundaryGap: false,
            axisLine: {
              show: true,
              lineStyle: {
                color: "rgba(230, 230, 230, .1)",
              },
            },
            axisLabel: {
              color: "rgba(230, 230, 230, 1)",
              fontSize: this.fontSize(14),
            },
            axisTick: {
              show: false,
            },
            splitLine: {
              show: false,
              lineStyle: {
                color: "blue",
                width: 1,
                type: "solid",
              },
            },
            axisPointer: {
              type: "shadow",
            },
          },
        ],
        yAxis: [
          {
            type: "value",
            axisLabel: {
              color: "rgba(167, 177, 196, 1)",
              fontSize: this.fontSize(14),
            },
            axisLine: {
              show: false,
              lineStyle: {
                color: "#FFDEAD",
              },
            },
            splitLine: {
              show: false,
              lineStyle: {
                color: "blue",
                width: 1,
                type: "solid",
              },
            },
          },
        ],
        series: [
          {
            name: "经费",
            type: "line",
            yAxisIndex: 0,
            symbol: "circle",
            data: seriesData,
            itemStyle: {
              color: "rgba(42, 145, 243, 1)",
            },
            areaStyle: {
              color: new echarts.graphic.LinearGradient(0, 1, 0, 0, [
                {
                  offset: 0,
                  color: "rgba(6, 97, 249, 0)",
                },
                {
                  offset: 1,
                  color: "rgba(42, 145, 243, .80)",
                },
              ]),
            },
            showSymbol: false,
          },
        ],
      };
      myChartMachine.setOption(option, true);
      window.addEventListener("resize", () => {
        if (myChartMachine) {
          myChartMachine.resize();
        }
      });
    },
    fontSize(res) {
      let docEl = document.documentElement,
        clientWidth =
          window.innerWidth ||
          document.documentElement.clientWidth ||
          document.body.clientWidth;
      if (!clientWidth) return;
      // 此处的3840 为设计稿的宽度，记得修改！
      let fontSize = clientWidth / 1920;
      return res * fontSize;
    },
  },
};
</script>

<style lang="less" scoped>
.page_right {
  width: 2.224rem;
  height: 100%;
  // background-color: red;
  position: absolute;
  right: .125rem;
  top: 0.4427rem;
  .campus_tab {
    position: absolute;
    right: 0;
    top: 0.2873rem;
    display: flex;
    width: 1.0938rem;
    height: 0.1823rem;
    justify-content: space-between;
    .item {
      width: 0.5052rem;
      height: 0.1823rem;
      font-size: 0.0729rem;
      font-family: "SourceHanSansCN-Regular";
      font-weight: 500;
      color: #77adff;
      display: flex;
      justify-content: center;
      align-items: center;
      background-image: url("~@/assets/imgs/campus_tab.png");
      background-size: 100% 100%;
      background-position: 0 0;
      background-repeat: no-repeat;
      cursor: pointer;
    }
    .activeItem {
      width: 0.5052rem;
      height: 0.1823rem;
      font-size: 0.0729rem;
      font-family: "SourceHanSansCN-Regular";
      font-weight: 500;
      color: #e6f2ff;
      display: flex;
      justify-content: center;
      align-items: center;
      background-image: url("~@/assets/imgs/campus_tabs.png");
      background-size: 100% 100%;
      background-position: 0 0;
      background-repeat: no-repeat;
      cursor: pointer;
    }
  }
  .school_building_situation {
    width: 100%;
    height: 1.2604rem;
    .content {
      width: 100%;
      height: 1rem;
      margin-top: 0.0781rem;
      background-image: url("~@/assets/imgs/school_building_situation.png");
      background-size: 100% 100%;
      background-position: 0 0;
      background-repeat: no-repeat;
      display: flex;
      flex-direction: column;
      .item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 0.1667rem;
        .name {
          font-size: 0.0729rem;
          font-family: "SourceHanSansCN-Regular";
          font-weight: 500;
          color: #ffffff;
        }
        .value {
          font-size: 0.1146rem;
          font-family: "SourceHanSansCN-Regular";
          font-weight: bold;
          color: #6cbdff;
          margin-right: 0.0547rem;
        }
        .unit {
          font-size: 0.0833rem;
          font-family: "SourceHanSansCN-Regular";
          font-weight: 500;
          color: #ffffff;
          margin-right: 0.0599rem;
        }
      }
      .item:nth-of-type(1) {
        margin-left: 0.7188rem;
        margin-top: 0.1198rem;
      }
      .item:nth-of-type(2) {
        margin-left: 0.7188rem;
      }
      .item:nth-of-type(3) {
        margin-left: 0.7188rem;
      }
      .item:nth-of-type(4) {
        margin-left: 0.1458rem;
      }
      .item:nth-of-type(5) {
        margin-left: 0.1458rem;
      }
    }
  }
  .discipline_construction {
    width: 100%;
    height: 0.7604rem;
    margin-top: 0.0938rem;
    .content {
      width: 100%;
      height: 0.5625rem;
      margin-top: 0.0469rem;
      display: flex;
      justify-content: space-between;
      .item {
        width: 0.401rem;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        p:nth-of-type(1) {
          background-image: url("~@/assets/imgs/office_details.png");
          background-size: 100% 100%;
          background-position: 0 0;
          background-repeat: no-repeat;
          width: 0.401rem;
          height: 0.2969rem;
          font-size: 0.099rem;
          font-family: "SourceHanSansCN-Regular";
          font-weight: bold;
          color: #ffffff;
          display: flex;
          justify-content: center;
          // align-items: center;
        }
        p:nth-of-type(2) {
          font-size: 0.0729rem;
          font-family: "SourceHanSansCN-Regular";
          font-weight: 500;
          color: #e6e6e6;
        }
      }
      .subject {
        width: 0.5677rem;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-image: url("~@/assets/imgs/subject.png");
        background-size: 100% 100%;
        background-position: 0 0;
        background-repeat: no-repeat;
        p:nth-of-type(1) {
          font-size: 0.0729rem;
          font-family: "SourceHanSansCN-Regular";
          font-weight: 500;
          color: #f2f2f2;
        }
        p:nth-of-type(2) {
          font-size: 0.125rem;
          font-family: "NOTOSANSHANS-REGULAR";
          font-weight: 500;
          color: #ffffff;
        }
      }
    }
  }
  .research_situation {
    width: 100%;
    height: 2.6667rem;
    .cultivate_ {
      width: 100%;
      height: 0.2786rem;
      display: flex;
      justify-content: space-around;
      align-items: center;
      .item {
        width: 0.2786rem;
        height: 0.2786rem;
        .value {
          width: 0.2969rem;
          height: 0.2448rem;
          background-image: url("~@/assets/imgs/office_details.png");
          background-size: 100% 100%;
          background-position: 0 0;
          background-repeat: no-repeat;
          display: flex;
          justify-content: center;
          align-items: center;
          span:nth-of-type(1) {
            font-size: 0.0573rem;
            font-family: "SourceHanSansCN-Regular";
            font-weight: bold;
            color: #ffffff;
          }
          span:nth-of-type(2) {
            font-size: 0.0365rem;
            font-family: "SourceHanSansCN-Regular";
            font-weight: bold;
            color: #ffffff;
            margin-top: 0.013rem;
          }
        }
        .name {
          font-size: 0.0365rem;
          font-family: "SourceHanSansCN-Regular";
          font-weight: 500;
          color: #e6e6e6;
          width: 0.2786rem;
          text-align: center;
        }
      }
    }
    .research_chart {
      width: 100%;
      height: 1.2188rem;
      margin-top: 0.0821rem;
      #research_chart {
        width: 100%;
        height: 100%;
      }
    }
    .funds_chart {
      width: 100%;
      height: 1.2188rem;
      #funds_chart {
        width: 100%;
        height: 100%;
      }
    }
  }
}
</style>