<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
import { login } from "@/api/login";
import Cookies from "js-cookie";

export default {
  data() {
    return {};
  },
  created() {},
  mounted() {
    // this.loginHandle()
   
  },

  methods: {
    // async loginHandle() {
    //   const res = await login();
    //   console.log(res, "登录信息");
    //   let token = res.data.token;
    //   console.log(token, "token");
    //   Cookies.set("token", token);
    // },
  },
};
</script>
<style lang="less">
#app {
  position: relative;
  width: 10rem;
  height: 5.625rem;
}

* {
  padding: 0 0;
  margin: 0 0;
  box-sizing: border-box;
  -webkit-touch-callout: none; /*系统默认菜单被禁用*/
  -webkit-user-select: none; /*webkit浏览器*/
  -khtml-user-select: none; /*早期浏览器*/
  -moz-user-select: none; /*火狐*/
  -ms-user-select: none; /*IE10*/
  user-select: none;
}

input {
  -webkit-user-select: auto; /*webkit浏览器*/
}

textarea {
  -webkit-user-select: auto; /*webkit浏览器*/
}

li {
  list-style-type: none;
}

::-webkit-scrollbar {
  width: 0px;
  height: 0px;
  background-color: #61b6eb;
}
</style>
