<template>
  <div class="carousel">
    <el-carousel
      trigger="click"
      height="150px"
      :autoplay="false"
      indicator-position="none"
      arrow="always"
      :loop="false"
    >
      <el-carousel-item>
        <energyLeft></energyLeft>
      </el-carousel-item>
      <el-carousel-item>
        <energyRight></energyRight>
      </el-carousel-item>
    </el-carousel>
  </div>
</template>

<script>
import energyLeft from "@/components/energy_page/energyLeft.vue";
import energyRight from "@/components/energy_page/energyRight.vue";
export default {
  components: {
    energyLeft,
    energyRight,
  },
};
</script>

<style lang="less" scoped>
.carousel {
  width: 2.224rem;
  height: 5.1823rem;
  position: absolute;
  top: 0.4427rem;
  right: 0.125rem;
  /deep/ .el-carousel,
  /deep/ .el-carousel__container {
    width: 100%;
    height: 100% !important;
  }
  /deep/ .el-carousel__arrow--left,
  /deep/ .el-carousel__arrow {
    position: absolute;
    top: 95%;
  }
  /deep/ .el-carousel__arrow--left {
    left: 40%;
  }
  /deep/ .el-carousel__arrow--right {
    right: 40%;
  }
}
</style>