<template>
  <div class="monitor_">
    <div class="close" @click="close">
      <img src="@/assets/imgs/close.png" alt="" />
    </div>
    <div class="title">{{ popupData.title }}</div>
    <div class="content">
      <div class="item" v-for="(item, index) in popupData.data" :key="index">
        <p>{{ item.name }}</p>
        <p>{{ item.value }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    popupData: {
      type: Object,
      default: "",
    },
  },
  data() {
    return {
      dataList: {},
    };
  },
  mounted() {},
  updated() {},
  methods: {
    close() {
        this.$store.commit("changeOverviewPopup", false);
    },
  },
};
</script>

<style lang="less" scoped>
.monitor_ {
  width: 2.2786rem;
  height: 1.6016rem;
  background-image: url("~@/assets/imgs/overviewPopup.png");
  background-size: 100% 100%;
  background-position: 0 0;
  background-repeat: no-repeat;
  position: absolute;
  top: 0.8802rem;
  left: 3.8464rem;
  z-index: 999;
  .close {
    width: 0.1875rem;
    height: 0.1875rem;
    position: absolute;
    top: -0.0286rem;
    right: -0.0286rem;
    cursor: pointer;

    img {
      width: 100%;
      height: 100%;
    }
  }
  .title {
    font-size: 0.099rem;
    font-family: "SourceHanSansCN-Regular";
    font-weight: bold;
    color: #ffffff;
    margin-top: 0.1198rem;
    margin-left: 0.3073rem;
  }
  .content {
    width: 1.7995rem;
    height: 1.099rem;
    margin: 0 auto;
    margin-top: 0.1328rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    .item {
      width: 1.7995rem;
      height: 0.1719rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-image: url("~@/assets/imgs/overviewPopup_.png");
      background-size: 100% 100%;
      background-position: 0 0;
      background-repeat: no-repeat;
      p {
        font-size: 0.0938rem;
        font-family: "SourceHanSansCN-Regular";
        font-weight: 400;
        color: #e6e6e6;
      }
      p:first-of-type {
        margin-left: 0.0938rem;
      }
      p:last-of-type {
        margin-right: 0.0938rem;
      }
    }
  }
}
</style>