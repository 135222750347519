<template>
  <div class="home_box">
    <!-- <img src="@/assets/tietu/Security_situation_left.png" alt="" /> -->
    <Title :titleList="controlData"></Title>
    <!--     用电概览 -->
    <div class="OverviewofelectricityBox">
      <div class="TotalnumberBox">
        <div class="metersBox">
          <p class="num">{{ deviceList.deviceNum }}</p>
          <p class="totles">监控总数</p>
        </div>
      </div>
      <!--       在线-->
      <div class="onlineBox">
        <div class="metersBox">
          <p class="num">{{ deviceList.onlineNum }}</p>
          <p class="totles">在线数</p>
        </div>
        <div class="metersBox">
          <p class="grey">{{ deviceList.offlineNum }}</p>
          <p class="totles">离线数</p>
        </div>
      </div>
    </div>
    <!--    告警总数-->
    <div class="devicealarmsBox">
      <p class="title">设备告警总数：</p>
      <p class="num">{{ deviceTotal }}</p>
    </div>
    <!--     图表-->
    <div class="Stack_Graph_box">
      <div class="Stack_Graph" ref="countrys_sagesb"></div>
    </div>
    <!--     图表 信息-->
    <div class="EchartsnewsBox">
      <ul>
        <li v-for="(item, index) in EchartsnewsList" :key="index">
          <div class="title">
            <p :class="item.color"></p>
            <p class="name">{{ item.name }}</p>
          </div>
          <p class="num">{{ item.num }}</p>
        </li>
      </ul>
    </div>
    <!--    小标题-->
    <smallTitle :titleList="smalltitle"></smallTitle>
    <div class="echartsBox">
      <div class="echarts_bcg">
        <div class="colorBcg">在线比例</div>
        <div class="careAgedEcharts" ref="careAgedsEcharts"></div>
      </div>
      <!--     图表 数据 -->
      <div class="PieBox">
        <ul>
          <li v-for="(item, index) in EchartpieList" :key="index">
            <div class="title">
              <p :class="item.color"></p>
              <p class="name">{{ item.name }}</p>
            </div>
            <p class="num">{{ item.num }}%</p>
          </li>
        </ul>
      </div>
    </div>
    <!-- 视频监控 -->
    <Title :titleList="monitorData"></Title>
    <div class="videoBox">
      <ul>
        <li>
          <video
            id="video_playerc"
            ref="video_player"
            style="width: 100%; height: 100%"
            controls
          ></video>
          <!-- <div class="videoImg" id="videomonitorCodeCLK"></div> -->
        </li>
        <li>
          <video
            id="video_playerd"
            ref="video_player"
            style="width: 100%; height: 100%"
            controls
          ></video>
          <!-- <div class="videoImg" id="videomonitorTwoCodeCLK"></div> -->
        </li>
        <li>
          <video
            id="video_playere"
            ref="video_player"
            style="width: 100%; height: 100%"
            controls
          ></video>
          <!-- <div class="videoImg" id="videomonitorThrCodeCLK"></div> -->
        </li>
        <li>
          <video
            id="video_playerf"
            ref="video_player"
            style="width: 100%; height: 100%"
            controls
          ></video>
          <!-- <div class="videoImg" id="videomonitorFourCodeCLK"></div> -->
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import * as echarts from "echarts";
import Title from "@/components/Title.vue";
import smallTitle from "@/components/smallTitle.vue";
import { monitor } from "@/api/common";
import { invokeService } from "@/api/commons";
export default {
  components: { Title, smallTitle },
  data() {
    return {
      controlData: {
        title: "监控概览",
      },
      // 小标题
      smalltitle: {
        title: "在线比例",
      },
      monitorData: {
        title: "视频监控",
      },
      EchartsnewsList: [
        {
          color: "skyblue",
          name: "信息",
          num: "62",
        },
        {
          color: "orange",
          name: "告警",
          num: "6",
        },
        {
          color: "cameobrown",
          name: "故障",
          num: "4",
        },
      ],
      //   饼图 数据
      EchartpieList: [
        {
          color: "blue",
          name: "在线率",
          num: "95",
        },
        {
          color: "skyblue",
          name: "离线率",
          num: "5",
        },
      ],
      webRtcServer: null,
      deviceList: {
        deviceNum: 0,
        onlineNum: 0,
        offlineNum: 0,
      },
      deviceTotal: 0,
      timer1: null,
      timer2: null,
      timer3: null,
      timer4: null,
    };
  },
  mounted() {
    // this.CountrySages();
    this.careAgedEcharts();
    this.videoPlay();
    this.videoTwoSrcPlay();
    this.videoThrSrcPlay();
    this.videoFourSrcPlay();
    this.monitorApi();
  },
  beforeDestroy() {
    this.webRtcServer.disconnect();
    this.webRtcServer = null;
  },
  updated() {
    clearTimeout(this.timer1);
    clearTimeout(this.timer2);
    clearTimeout(this.timer3);
    clearTimeout(this.timer4);
  },
  methods: {
    //监控概览
    monitorApi() {
      monitor().then((res) => {
        console.log(res, "监控概览");
        this.deviceList = {
          deviceNum: res.deviceNum,
          onlineNum: res.onlineNum,
          offlineNum: res.offlineNum,
        };
        let data = [
          {
            name: "信息",
            value: res.infoNum,
          },
          {
            name: "告警",
            value: res.alarmNum,
          },
          {
            name: "故障",
            value: res.faultNum,
          },
        ];
        (this.EchartsnewsList = [
          {
            color: "skyblue",
            name: "信息",
            num: res.infoNum,
          },
          {
            color: "orange",
            name: "告警",
            num: res.alarmNum,
          },
          {
            color: "cameobrown",
            name: "故障",
            num: res.faultNum,
          },
        ]),
          (this.deviceTotal =
            Number(res.infoNum) + Number(res.alarmNum) + Number(res.faultNum));
        this.CountrySages(data);
        let onlineNum = (Number(res.onlineNum) / Number(res.deviceNum)) * 100;
        let offlineNum = (Number(res.offlineNum) / Number(res.deviceNum)) * 100;
        this.EchartpieList = [
          {
            color: "blue",
            name: "在线率",
            num: onlineNum.toFixed(2),
          },
          {
            color: "skyblue",
            name: "离线率",
            num: offlineNum.toFixed(2),
          },
        ];
        let seriesData = [res.onlineNum, res.offlineNum];
        this.careAgedEcharts(seriesData);
      });
    },
    // 监控
    videoPlay() {
      invokeService("0d9f6000154f4df99efdea1745cb150a").then((res) => {
        console.log(res, "============监控2===========");
        // let url = `${res.data.outputParams.url}?channel=1&subtype=1`;
        // 3;
        // let dom = document.getElementById("videomonitorCodeCLK");
        // this.webRtcServer = new WebRtcStreamer(
        //   "videomonitorCode",
        //   location.protocol + "//127.0.0.1:8000"
        // );
        // //需要看的rtsp视频地址，可以在网上找在线的rtsp视频地址来进行demo实验，在vlc中能播放就能用
        // this.webRtcServer.connect(url);
        // this.timer1 = setTimeout(() => {
        //   dom.click();
        // }, 30);
        var video = document.getElementById("video_playerc");
        // console.log(video, "video");
        var hls = new Hls();
        hls.loadSource(res.data.outputParams.url);
        hls.attachMedia(video);
        hls.on(Hls.Events.MANIFEST_PARSED, function () {
          video.play();
        });
      });
    },
    videoTwoSrcPlay() {
      invokeService("9f308e9a2a334658b2870fa613733871").then((res) => {
        console.log(res, "============监控3===========");
        // let url = `${res.data.outputParams.url}?channel=1&subtype=1`;
        // let dom = document.getElementById("videomonitorTwoCodeCLK");
        // this.webRtcServer = new WebRtcStreamer(
        //   "videomonitorTwoCode",
        //   location.protocol + "//127.0.0.1:8000"
        // );
        // //需要看的rtsp视频地址，可以在网上找在线的rtsp视频地址来进行demo实验，在vlc中能播放就能用
        // this.webRtcServer.connect(url);
        // this.timer2 = setTimeout(() => {
        //   dom.click();
        // }, 30);
        var video = document.getElementById("video_playerd");
        // console.log(video, "video");
        var hls = new Hls();
        hls.loadSource(res.data.outputParams.url);
        hls.attachMedia(video);
        hls.on(Hls.Events.MANIFEST_PARSED, function () {
          video.play();
        });
      });
    },
    videoThrSrcPlay() {
      invokeService("e6eb16754629432e957ce7d2c977cd18").then((res) => {
        console.log(res, "============监控4===========");
        // let url = `${res.data.outputParams.url}?channel=1&subtype=1`;
        // let dom = document.getElementById("videomonitorThrCodeCLK");
        // this.webRtcServer = new WebRtcStreamer(
        //   "videomonitorThrCode",
        //   location.protocol + "//127.0.0.1:8000"
        // );
        // //需要看的rtsp视频地址，可以在网上找在线的rtsp视频地址来进行demo实验，在vlc中能播放就能用
        // this.webRtcServer.connect(url);
        // this.timer3 = setTimeout(() => {
        //   dom.click();
        // }, 30);
        var video = document.getElementById("video_playere");
        // console.log(video, "video");
        var hls = new Hls();
        hls.loadSource(res.data.outputParams.url);
        hls.attachMedia(video);
        hls.on(Hls.Events.MANIFEST_PARSED, function () {
          video.play();
        });
      });
    },
    videoFourSrcPlay() {
      invokeService("9c1795030890451fb6c57e9837c7accd").then((res) => {
        console.log(res, "============监控5===========");
        // let url = `${res.data.outputParams.url}?channel=1&subtype=1`;
        // let dom = document.getElementById("videomonitorFourCodeCLK");
        // this.webRtcServer = new WebRtcStreamer(
        //   "videomonitorFourCode",
        //   location.protocol + "//127.0.0.1:8000"
        // );
        // //需要看的rtsp视频地址，可以在网上找在线的rtsp视频地址来进行demo实验，在vlc中能播放就能用
        // this.webRtcServer.connect(url);
        // this.timer4 = setTimeout(() => {
        //   dom.click();
        // }, 20);
        var video = document.getElementById("video_playerf");
        // console.log(video, "video");
        var hls = new Hls();
        hls.loadSource(res.data.outputParams.url);
        hls.attachMedia(video);
        hls.on(Hls.Events.MANIFEST_PARSED, function () {
          video.play();
        });
      });
    },
    //  图表
    CountrySages(data) {
      // 基于准备好的dom，初始化echarts实例
      const myChart = echarts.init(this.$refs.countrys_sagesb);
      myChart.setOption({
        // tooltip: {
        //   trigger: 'axis',
        //   axisPointer: {
        //     // Use axis to trigger tooltip
        //     type: 'shadow' // 'shadow' as default; can also be 'line' or 'shadow'
        //   }
        // },
        legend: {
          show: false,
        },
        grid: {
          left: "0.5%",
          right: "0.5%",
          containLabel: true,
        },
        xAxis: {
          type: "value",
          splitLine: {
            show: false,
          },
          axisLabel: {
            show: false,
          },
        },
        yAxis: {
          type: "category",
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            show: false,
          },
          data: ["数量"],
        },
        series: [
          {
            name: "会员",
            type: "bar",
            stack: "total",
            emphasis: {
              // focus: 'series'
            },
            label: {
              show: false,
            },
            itemStyle: {
              color: "#1EF8FF",
              // borderColor: '#fff',
              borderWidth: 1,
            },
            barWidth: 27,
            data: [data[0].value],
          },
          {
            name: "",
            type: "bar",
            stack: "total",
            emphasis: {
              // focus: 'series'
            },
            label: {
              show: false,
            },
            itemStyle: {
              color: "rgba(255, 255, 255, .1)",
              // borderColor: 'rgba(255, 255, 255, .1)',
              borderWidth: 10,
            },
            barWidth: 27,
            data: [0],
          },
          {
            name: "",
            type: "bar",
            stack: "total",
            emphasis: {
              // focus: 'series'
            },
            label: {
              show: false,
            },
            itemStyle: {
              color: "#FBB351",
              // borderColor: 'rgba(255, 255, 255, .1)',
              borderWidth: 10,
            },
            barWidth: 27,
            data: [data[1].value],
          },
          {
            name: "",
            type: "bar",
            stack: "total",
            emphasis: {
              // focus: 'series'
            },
            label: {
              show: false,
            },
            itemStyle: {
              color: "rgba(255, 255, 255, .1)",
              // borderColor: 'rgba(255, 255, 255, .1)',
              borderWidth: 10,
            },
            barWidth: 27,
            data: [0],
          },
          {
            name: "理事",
            type: "bar",
            stack: "total",
            label: {
              show: false,
            },
            itemStyle: {
              color: "#FF6E6F",
              // borderColor: '#fff',
              borderWidth: 2,
            },
            barWidth: 27,
            emphasis: {
              // focus: 'series'
            },
            data: [data[2].value],
          },
        ],
      });
    },
    //  饼图
    careAgedEcharts(seriesData) {
      // 基于准备好的dom，初始化echarts实例
      const myChart = echarts.init(this.$refs.careAgedsEcharts);
      // 绘制图表
      myChart.setOption({
        color: ["#199BFF", "#4CE9E9"], //饼状图颜⾊数组
        tooltip: {
          trigger: "item",
          //提示框样式调整
          textStyle: {
            color: "#e6e6e6",
            fontFamily: "SourceHanSansCN-Regular",
            fontSize: 14,
          },
          extraCssText:
            "border: 1px solid #e6e6e6;color: #ccc;font-family:'SourceHanSansCN-Regular';background:rgba(39, 69, 86,.8);font-size:14px;",
        },
        series: [
          {
            type: "pie",
            radius: ["70%", "90%"],
            avoidLabelOverlap: false,

            label: {
              show: false,
              position: "center",
            },
            emphasis: {
              label: {
                show: false,
                fontSize: "14",
                fontWeight: "bold",
              },
            },
            labelLine: {
              show: false,
            },
            data: seriesData,
          },
        ],
      });
    },
  },
};
</script>

<style lang="less" scoped>
.home_box {
  width: 2.224rem;
  height: 5.1823rem;
  position: absolute;
  right: 0.125rem;
  top: 0.4427rem;
  // 用电 概览
  .OverviewofelectricityBox {
    display: flex;
    width: 100%;
    height: 0.4948rem;
    background: url("~@/assets/imgs/Energy/overviewofelect_bcg.png") no-repeat
      center / cover;
    background-size: 100% 100%;
    margin-top: 0.0339rem;

    .TotalnumberBox {
      display: flex;
      width: 1.0833rem;
      // 电表总数
      .metersBox {
        margin: 0.1069rem 0 0 0.5521rem;

        .num {
          height: 0.1171875rem;
          line-height: 0.1171875rem;
          font-size: 0.1667rem;
          font-family: DigitalRegular;
          font-weight: 400;
          color: #fdfdfd;
          letter-spacing: 0.0052rem;
          text-align: center;
          text-shadow: 0px 0.013rem 0.0313rem #00dcfe;
          -webkit-background-clip: text;
        }

        .totles {
          //   background: red;

          font-size: 0.099rem;
          font-family: SourceHanSansCN-Regular;
          font-weight: 400;
          color: #e6e6e6;
          letter-spacing: 0.0052rem;
          -webkit-background-clip: text;
        }
      }
    }

    .onlineBox {
      display: flex;
      justify-content: center;
      width: 0.9375rem;
      margin-left: 0.1354rem;
      //  在线 离线 率
      .metersBox {
        margin: 0.0390625rem 0;

        width: 0.3906rem;
        text-align: center;
        // padding: 0.04rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .num {
          height: 0.1171875rem;
          line-height: 0.1171875rem;
          font-size: 0.125rem;
          font-family: DigitalRegular;
          color: #58ff6d;
          font-weight: 400;
          letter-spacing: 0.0052rem;
          text-shadow: 0px 0.013rem 0.0313rem #00dcfe;
          -webkit-background-clip: text;
        }

        .grey {
          height: 0.1171875rem;
          line-height: 0.1171875rem;
          font-size: 0.125rem;
          font-family: DigitalRegular;
          color: #cccccc;
          font-weight: 400;
          letter-spacing: 0.0052rem;
          text-shadow: 0px 0.013rem 0.0313rem #00dcfe;
          -webkit-background-clip: text;
        }

        .totles {
          font-size: 0.0729rem;
          font-family: SourceHanSansCN-Regular;
          letter-spacing: 0.0052rem;
          font-weight: 500;
          color: #e6e6e6;
          -webkit-background-clip: text;
        }
      }
      .metersBox:first-of-type {
        margin-right: 0.1021rem;
        margin-left: 0.0521rem;
      }
    }
  }

  //   告警总数
  .devicealarmsBox {
    display: flex;
    align-items: center;
    margin-top: 0.1406rem;

    .title {
      font-size: 0.0781rem;
      font-family: SourceHanSansCN-Medium;
      font-weight: 500;
      color: #ffffff;
    }

    .num {
      font-size: 0.125rem;
      font-family: YouSheBiaoTiHei;
      font-weight: 400;
      color: #1ef8ff;
    }
  }

  //   图表
  .Stack_Graph_box {
    width: 2.224rem;
    height: 0.1094rem;
    background: url("~@/assets/imgs/Energy/line_bcg.png") no-repeat center /
      cover;
    background-size: 100% 100%;

    .Stack_Graph {
      padding: 0.01rem 0;
      height: 0.1094rem;
    }
  }

  //   图表信息
  .EchartsnewsBox {
    margin-top: 0.0286rem;
    margin-bottom: 0.25rem;
    ul {
      display: flex;
      justify-content: space-around;

      li {
        display: flex;
        align-items: center;

        .title {
          display: flex;
          align-items: center;

          .name {
            font-size: 0.0729rem;
            font-family: SourceHanSansCN-Medium;
            font-weight: 500;
            color: #e6e6e6;
            margin: 0 0.0208rem 0 0.013rem;
          }
        }

        .num {
          font-size: 0.1094rem;
          font-family: YouSheBiaoTiHei;
          font-weight: 400;
          color: #ffffff;
        }

        .skyblue {
          width: 0.0729rem;
          height: 0.0729rem;
          background: #1ef8ff;
          border-radius: 0px 0px 0px 0px;
          opacity: 1;
        }

        .orange {
          width: 0.0729rem;
          height: 0.0729rem;
          background: #fbb351;
          border-radius: 0px 0px 0px 0px;
          opacity: 1;
        }

        .cameobrown {
          width: 0.0729rem;
          height: 0.0729rem;
          background: #ff6e6f;
          border-radius: 0px 0px 0px 0px;
          opacity: 1;
        }
      }
    }
  }

  // 饼图
  .echartsBox {
    display: flex;
    justify-content: space-between;
    padding: 0 0.02rem;
    margin: 0.1875rem 0 0.2813rem;

    //   饼图
    .echarts_bcg {
      position: relative;

      .colorBcg {
        position: relative;
        top: 0.0972rem;
        left: 20%;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 0.5208rem;
        height: 0.5208rem;
        background: #00325a;
        border-radius: 50%;
        font-size: 0.099rem;
        font-family: SourceHanSansCN-Bold;
        font-weight: bold;
        color: #e6e6e6;
        padding: 0.0521rem;
        text-align: center;
      }

      .careAgedEcharts {
        position: absolute;
        top: 0;
        left: 0.0104rem;
        width: 0.6927rem;
        height: 0.6927rem;
      }
    }

    //   饼图信息
    .PieBox {
      //margin-top: 0.0521rem;
      height: 0.6927rem;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
      margin-right: 0.1042rem;
      ul {
        li {
          display: flex;
          align-items: center;
          height: 0.2344rem;

          .title {
            display: flex;
            align-items: center;

            .blue {
              width: 0.0833rem;
              height: 0.0833rem;
              background: #199afd;
              border-radius: 50%;
              opacity: 1;
            }

            .skyblue {
              width: 0.0833rem;
              height: 0.0833rem;
              background: #4be8e9;
              border-radius: 50%;
              opacity: 1;
            }

            .name {
              font-size: 0.0938rem;
              font-family: SourceHanSansCN-Medium;
              font-weight: 500;
              color: #e6e6e6;
              margin: 0 0.151rem 0 0.0573rem;
            }
          }

          .num {
            font-size: 0.1458rem;
            font-family: YouSheBiaoTiHei;
            font-weight: 400;
            color: #e6e6e6;
          }
        }
      }
    }
  }
  .passes_total {
    width: 100%;
    height: 1.3594rem;
    margin-bottom: 0.0521rem;
    margin-top: 0.0573rem;
    #passes_total_chart {
      width: 100%;
      height: 100%;
    }
  }
  //   监控
  .videoBox {
    margin-top: 0.1615rem;

    ul {
      display: flex;
      align-items: center;
      justify-content: space-around;
      flex-wrap: wrap;

      li {
        position: relative;
        width: 1.0573rem;
        height: 0.6823rem;
        background: url("~@/assets/imgs/electrical/video_line_bcg.png")
          no-repeat center / cover;
        background-size: 100% 100%;
        overflow: hidden;
        margin-bottom: 0.1354rem;

        .videoImg {
          position: absolute;
          top: 0;
          width: 0.5807rem;
          height: 0.375rem;
          background: transparent;
          background-size: 100% 100%;
        }

        .videoCanvas {
          width: 1.0573rem;
          height: 0.6823rem;

          background: red;
        }

        video {
          width: 1.0573rem;
          height: 0.6823rem;
        }

        video::-webkit-media-controls {
          display: none !important;
        }

        .video-js.vjs-fluid,
        .video-js.vjs-16-9,
        .video-js.vjs-4-3,
        .video-js.vjs-9-16,
        .video-js.vjs-1-1 {
          width: 1.0573rem;
          height: 0.6823rem;
        }
      }
    }
  }
}
</style>
