<template>
  <div class="home_box">
    <Title :titleList="titleList"></Title>
    <div class="PowerconsumptionBox">
      <ul>
        <li>
          <div class="OccupancyrateNumBox">
            <div class="TotalnumberBox">
              <img src="@/assets/imgs/electrical/car_icon.png" alt="" />
              <div class="Totalnumber">
                <p class="num">{{ ParkingList.CWZS }}</p>
                <p class="title">车位总数</p>
              </div>
            </div>
            <div class="occpowerBox">
              <div class="powerBox">
                <p class="title">占用车位</p>
                <p class="num">{{ ParkingList.ZYCW }}</p>
              </div>
              <div class="powerBox">
                <p class="title">空余车位</p>
                <p class="num">{{ ParkingList.KXCW }}</p>
              </div>
            </div>
          </div>
          <div class="Occupancyrate">
            <div class="echartsNumBox">
              <div class="numBox">
                <p class="num">{{ ParkingList.CWZYL * 100 }}</p>
                <p class="type">%</p>
              </div>
              <p class="title">车位占用率</p>
            </div>
            <div ref="annularEcharts" class="annularEcharts"></div>
          </div>
        </li>
      </ul>
    </div>
    <!--    小标题-->
    <smallTitle :titleList="smalltitle"></smallTitle>
    <!--     车位统计 -->
    <div class="OverviewofelectricityBox">
      <div class="TotalnumberBox">
        <div class="metersBox">
          <p class="num">{{ EleconsList.deviceNum }}</p>
          <p class="totles">道闸总数</p>
        </div>
      </div>
      <!--       在线-->
      <div class="onlineBox">
        <div class="metersBox">
          <p class="num">{{ EleconsList.onlineNum }}</p>
          <p class="totles">在线数</p>
        </div>
        <div class="metersBox">
          <p class="grey">{{ EleconsList.offlineNum }}</p>
          <p class="totles">离线数</p>
        </div>
      </div>
    </div>
    <!--    告警总数-->
    <div class="devicealarmsBox">
      <p class="title">设备告警总数：</p>
      <p class="num">{{ ararmNumToals }}</p>
    </div>
    <!--     图表-->
    <div class="Stack_Graph_box">
      <div class="Stack_Graph" ref="countrysv_sages"></div>
    </div>
    <!--     图表 信息-->
    <div class="EchartsnewsBox">
      <ul>
        <li v-for="(item, index) in EchartsnewsList" :key="index">
          <div class="title">
            <p :class="item.color"></p>
            <p class="name">{{ item.name }}</p>
          </div>
          <p class="num">{{ item.num }}</p>
        </li>
      </ul>
    </div>
    <div class="echartsBox">
      <div class="echarts_bcg">
        <div class="colorBcg">在线比例</div>
        <div class="careAgedEcharts" ref="careAgedscarEcharts"></div>
      </div>
      <!--     图表 数据 -->
      <div class="PieBox">
        <ul>
          <li v-for="(item, index) in EchartpieList" :key="index">
            <div class="title">
              <p :class="item.color"></p>
              <p class="name">{{ item.name }}</p>
            </div>
            <p class="num">{{ item.num }}%</p>
          </li>
        </ul>
      </div>
    </div>
    <Title :titleList="videoList"></Title>
    <div class="videoBox">
      <ul>
        <li>
          <video
            id="video_playerh"
            ref="video_player"
            style="width: 100%; height: 100%"
            controls
          ></video>
          <!-- <div class="videoImg" id="videoCodeCLK"></div> -->
        </li>
        <li>
          <video
            id="video_playeri"
            ref="video_player"
            style="width: 100%; height: 100%"
            controls
          ></video>
          <!-- <div class="videoImg" id="videoTwoCodeCLK"></div> -->
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import * as echarts from "echarts";
import Title from "@/components/Title";
import smallTitle from "@/components/smallTitle";
import { execmetadata, deviceStatisticsexecParking } from "@/api/common";
import { invokeService } from "@/api/commons";
import bus from "@/bus";

export default {
  components: {
    Title,
    smallTitle,
  },
  data() {
    return {
      // 标题
      titleList: {
        title: "车位统计",
      },
      videoList: {
        title: "视频监控",
      },
      // 小标题
      smalltitle: {
        title: "道闸情况",
      },

      EchartsnewsList: [],
      //   饼图 数据
      EchartpieList: [],
      webRtcServer: null,
      deviceTotal: 0,
      // 拱宸桥Id
      labelIds: "528221552517316608",
      // 概览数据
      EleconsList: {},
      // 设备告警总数
      ararmNumToals: 0,
      // 车位统计
      ParkingList: {},
      timer1: null,
      timer2: null,
    };
  },
  beforeDestroy() {
    this.webRtcServer.disconnect();
    this.webRtcServer = null;
  },
  watch: {
    completionRate: function (newVal) {
      this.draw(newVal);
    },
  },
  mounted() {
    // this.CountrySages();
    this.careAgedEcharts();
    this.annularEcharts();
    this.videoPlay();
    this.videoSrcPlay();
    this.execmetadataHandle();
    let labelIds = this.$store.state.labelIds;
    this.execParkingHandle(labelIds);
    // bus.$on("labelIds", (val) => {
    //   console.log(val, "val");
    //   setTimeout(() => {
    //     this.execParkingHandle(val);
    //   }, 200)
    // });
  },
  updated() {
    clearTimeout(this.timer1);
    clearTimeout(this.timer2);
  },
  methods: {
    // 车位统计
    async execmetadataHandle(labelIds) {
      const res = await execmetadata(labelIds);
      console.log(res, "res 车位统计");
      this.ParkingList = res.data.content[0];
    },

    // 道闸
    async execParkingHandle(labelIds) {
      const res = await deviceStatisticsexecParking(labelIds);
      console.log(res, "res 道闸");
      this.EleconsList = res;
      this.ararmNumToals =
        Number(res.infoNum) + Number(res.alarmNum) + Number(res.faultNum);
      let onlineNum = (Number(res.onlineNum) / Number(res.deviceNum)) * 100;
      let offlineNum = (Number(res.offlineNum) / Number(res.deviceNum)) * 100;
      let faultNum = (Number(res.faultNum) / Number(res.deviceNum)) * 100;
      console.log("sssssss", offlineNum);
      // 告警信息
      this.EchartsnewsList = [
        {
          color: "skyblue",
          name: "信息",
          num: res.infoNum,
        },
        {
          color: "orange",
          name: "告警",
          num: res.alarmNum,
        },
        {
          color: "cameobrown",
          name: "故障",
          num: res.faultNum,
        },
      ];
      //   饼图 数据
      this.EchartpieList = [
        {
          color: "blue",
          name: "在线率",
          num: onlineNum,
        },
        {
          color: "skyblue",
          name: "离线率",
          num: offlineNum,
        },
      ];
      this.$nextTick(() => {
        this.careAgedEcharts(res.onlineNum, res.offlineNum, res.faultNum);
        this.CountrySages(res.infoNum, res.alarmNum, res.faultNum);
      });
    },
    // 监控
    videoPlay() {
      invokeService("0fb87d1e2613463d951792ce28bc05e3").then((res) => {
        console.log(res, "============监控6===========");
        // let url = `${res.data.outputParams.url}?channel=1&subtype=1`;
        // let dom = document.getElementById("videoCodeCLK");
        // this.webRtcServer = new WebRtcStreamer(
        //   "videoCode",
        //   location.protocol + "//127.0.0.1:8000"
        // );
        // //需要看的rtsp视频地址，可以在网上找在线的rtsp视频地址来进行demo实验，在vlc中能播放就能用
        // this.webRtcServer.connect(url);
        // this.timer1 = setTimeout(() => {
        //   dom.click();
        // }, 20);
        var video = document.getElementById("video_playerh");
        // console.log(video, "video");
        var hls = new Hls();
        hls.loadSource(res.data.outputParams.url);
        hls.attachMedia(video);
        hls.on(Hls.Events.MANIFEST_PARSED, function () {
          video.play();
        });
      });
    },
    videoSrcPlay() {
      invokeService("f9fbe6697c92441d9cd11a01ed2ca3bc").then((res) => {
        console.log(res, "============监控7===========");
        // let url = `${res.data.outputParams.url}?channel=1&subtype=1`;
        // let dom = document.getElementById("videoTwoCodeCLK");
        // this.webRtcServer = new WebRtcStreamer(
        //   "videoTwoCode",
        //   location.protocol + "//127.0.0.1:8000"
        // );
        // //需要看的rtsp视频地址，可以在网上找在线的rtsp视频地址来进行demo实验，在vlc中能播放就能用
        // this.webRtcServer.connect(url);
        // this.timer2 = setTimeout(() => {
        //   dom.click();
        // }, 20);
        var video = document.getElementById("video_playeri");
        // console.log(video, "video");
        var hls = new Hls();
        hls.loadSource(res.data.outputParams.url);
        hls.attachMedia(video);
        hls.on(Hls.Events.MANIFEST_PARSED, function () {
          video.play();
        });
      });
    },
    //  图表
    CountrySages(infoNum, alarmNum, faultNum) {
      // 基于准备好的dom，初始化echarts实例
      const myChart = echarts.init(this.$refs.countrysv_sages);
      myChart.setOption({
        // tooltip: {
        //   trigger: 'axis',
        //   axisPointer: {
        //     // Use axis to trigger tooltip
        //     type: 'shadow' // 'shadow' as default; can also be 'line' or 'shadow'
        //   }
        // },
        legend: {
          show: false,
        },
        grid: {
          left: "0.5%",
          right: "0.5%",
          containLabel: true,
        },
        xAxis: {
          type: "value",
          splitLine: {
            show: false,
          },
          axisLabel: {
            show: false,
          },
        },
        yAxis: {
          type: "category",
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            show: false,
          },
          data: ["数量"],
        },
        series: [
          {
            name: "会员",
            type: "bar",
            stack: "total",
            emphasis: {
              // focus: 'series'
            },
            label: {
              show: false,
            },
            itemStyle: {
              color: "#1EF8FF",
              // borderColor: '#fff',
              borderWidth: 1,
            },
            barWidth: 27,
            data: [infoNum],
          },
          {
            name: "",
            type: "bar",
            stack: "total",
            emphasis: {
              // focus: 'series'
            },
            label: {
              show: false,
            },
            itemStyle: {
              color: "rgba(255, 255, 255, .1)",
              // borderColor: 'rgba(255, 255, 255, .1)',
              borderWidth: 10,
            },
            barWidth: 27,
            data: [0],
          },
          {
            name: "",
            type: "bar",
            stack: "total",
            emphasis: {
              // focus: 'series'
            },
            label: {
              show: false,
            },
            itemStyle: {
              color: "#FBB351",
              // borderColor: 'rgba(255, 255, 255, .1)',
              borderWidth: 10,
            },
            barWidth: 27,
            data: [alarmNum],
          },
          {
            name: "",
            type: "bar",
            stack: "total",
            emphasis: {
              // focus: 'series'
            },
            label: {
              show: false,
            },
            itemStyle: {
              color: "rgba(255, 255, 255, .1)",
              // borderColor: 'rgba(255, 255, 255, .1)',
              borderWidth: 10,
            },
            barWidth: 27,
            data: [0],
          },
          {
            name: "理事",
            type: "bar",
            stack: "total",
            label: {
              show: false,
            },
            itemStyle: {
              color: "#FF6E6F",
              // borderColor: '#fff',
              borderWidth: 2,
            },
            barWidth: 27,
            emphasis: {
              // focus: 'series'
            },
            data: [faultNum],
          },
        ],
      });
    },
    //  饼图
    careAgedEcharts(onlineNum, offlineNum) {
      // 基于准备好的dom，初始化echarts实例
      const myChart = echarts.init(this.$refs.careAgedscarEcharts);
      // 绘制图表
      myChart.setOption({
        color: ["#199BFF", "#4CE9E9"], //饼状图颜⾊数组
        tooltip: {
          trigger: "item",
          //提示框样式调整
          textStyle: {
            color: "#e6e6e6",
            fontFamily: "SourceHanSansCN-Regular",
            fontSize: 14,
          },
          extraCssText:
            "border: 1px solid #e6e6e6;color: #ccc;font-family:'SourceHanSansCN-Regular';background:rgba(39, 69, 86,.8);font-size:14px;",
        },
        series: [
          {
            type: "pie",
            radius: ["70%", "90%"],
            avoidLabelOverlap: false,

            label: {
              show: false,
              position: "center",
            },
            emphasis: {
              label: {
                show: false,
                fontSize: "14",
                fontWeight: "bold",
              },
            },
            labelLine: {
              show: false,
            },
            data: [onlineNum, offlineNum],
          },
        ],
      });
    },
    annularEcharts() {
      let myEcharts = echarts.init(this.$refs.annularEcharts);
      let option = {
        title: {
          text: "",
          left: "center",
          top: "55%",
          textStyle: {
            color: "#fff",
            fontSize: 16,
            fontFamily: "SourceHanSansCN-Medium",
            fontWeight: "500",
          },
          // bottom:'0'
        },
        //饼图中间显示文字
        graphic: {
          type: "text",
          left: "center",
          top: "50%",
          style: {
            text: "", //文字内容
            // 	fill: "red",//文字颜色
            fontSize: 30, //文字字号
            fontWeight: "800",
            fill: "#3478FF",
          },
        },
        tooltip: {
          trigger: "item",
        },
        series: [
          {
            //第一张圆环
            type: "pie",
            radius: ["80%", "100%"],
            center: ["50%", "50%"],
            // 隐藏指示线
            labelLine: { show: false },
            //隐藏圆环上文字
            label: {
              show: false,
            },
            data: [
              //value当前进度 + 颜色
              {
                value: "80",
                itemStyle: {
                  normal: {
                    color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                      { offset: 0, color: "#068EF6" },
                      { offset: 1, color: "#00DAE7" },
                    ]),
                  },
                },
              },
              {
                value: 100 - 80,
                itemStyle: {
                  normal: { color: "transparent" },
                },
              },
            ],
          },
        ],
      };
      myEcharts.setOption(option);
    },
  },
};
</script>

<style lang="less" scoped>
.home_box {
  width: 2.224rem;
  height: 5.1823rem;
  position: absolute;
  right: 0.125rem;
  top: 0.4427rem;
  // 用电 概览
  .OverviewofelectricityBox {
    display: flex;
    width: 100%;
    height: 0.4948rem;
    background: url("~@/assets/imgs/Energy/overviewofelect_bcg.png") no-repeat
      center / cover;
    background-size: 100% 100%;
    margin-top: 0.0339rem;

    .TotalnumberBox {
      display: flex;
      width: 1.0833rem;
      // 电表总数
      .metersBox {
        margin: 0.1069rem 0 0 0.5521rem;

        .num {
          height: 0.1171875rem;
          line-height: 0.1171875rem;
          font-size: 0.1667rem;
          font-family: DigitalRegular;
          font-weight: 400;
          color: #fdfdfd;
          letter-spacing: 0.0052rem;
          text-align: center;
          text-shadow: 0px 0.013rem 0.0313rem #00dcfe;
          -webkit-background-clip: text;
        }

        .totles {
          //   background: red;

          font-size: 0.099rem;
          font-family: SourceHanSansCN-Regular;
          font-weight: 400;
          color: #e6e6e6;
          letter-spacing: 0.0052rem;
          -webkit-background-clip: text;
        }
      }
    }

    .onlineBox {
      display: flex;
      justify-content: center;
      width: 0.9375rem;
      margin-left: 0.1354rem;
      //  在线 离线 率
      .metersBox {
        margin: 0.0390625rem 0;

        width: 0.3906rem;
        text-align: center;
        // padding: 0.04rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .num {
          height: 0.1171875rem;
          line-height: 0.1171875rem;
          font-size: 0.125rem;
          font-family: DigitalRegular;
          color: #58ff6d;
          font-weight: 400;
          letter-spacing: 0.0052rem;
          text-shadow: 0px 0.013rem 0.0313rem #00dcfe;
          -webkit-background-clip: text;
        }

        .grey {
          height: 0.1171875rem;
          line-height: 0.1171875rem;
          font-size: 0.125rem;
          font-family: DigitalRegular;
          color: #cccccc;
          font-weight: 400;
          letter-spacing: 0.0052rem;
          text-shadow: 0px 0.013rem 0.0313rem #00dcfe;
          -webkit-background-clip: text;
        }

        .totles {
          font-size: 0.0729rem;
          font-family: SourceHanSansCN-Regular;
          letter-spacing: 0.0052rem;
          font-weight: 500;
          color: #e6e6e6;
          -webkit-background-clip: text;
        }
      }
      .metersBox:first-of-type {
        margin-right: 0.1021rem;
        margin-left: 0.0521rem;
      }
    }
  }

  //   告警总数
  .devicealarmsBox {
    display: flex;
    align-items: center;
    margin-top: 0.1406rem;

    .title {
      font-size: 0.0781rem;
      font-family: SourceHanSansCN-Medium;
      font-weight: 500;
      color: #ffffff;
    }

    .num {
      font-size: 0.125rem;
      font-family: YouSheBiaoTiHei;
      font-weight: 400;
      color: #1ef8ff;
    }
  }

  //   图表
  .Stack_Graph_box {
    width: 2.224rem;
    height: 0.1094rem;
    background: url("~@/assets/imgs/Energy/line_bcg.png") no-repeat center /
      cover;
    background-size: 100% 100%;

    .Stack_Graph {
      padding: 0.01rem 0;
      height: 0.1094rem;
    }
  }

  //   图表信息
  .EchartsnewsBox {
    margin-top: 0.0286rem;

    ul {
      display: flex;
      justify-content: space-around;

      li {
        display: flex;
        align-items: center;

        .title {
          display: flex;
          align-items: center;

          .name {
            font-size: 0.0729rem;
            font-family: SourceHanSansCN-Medium;
            font-weight: 500;
            color: #e6e6e6;
            margin: 0 0.0208rem 0 0.013rem;
          }
        }

        .num {
          font-size: 0.1094rem;
          font-family: YouSheBiaoTiHei;
          font-weight: 400;
          color: #ffffff;
        }

        .skyblue {
          width: 0.0729rem;
          height: 0.0729rem;
          background: #1ef8ff;
          border-radius: 0px 0px 0px 0px;
          opacity: 1;
        }

        .orange {
          width: 0.0729rem;
          height: 0.0729rem;
          background: #fbb351;
          border-radius: 0px 0px 0px 0px;
          opacity: 1;
        }

        .cameobrown {
          width: 0.0729rem;
          height: 0.0729rem;
          background: #ff6e6f;
          border-radius: 0px 0px 0px 0px;
          opacity: 1;
        }
      }
    }
  }

  // 饼图
  .echartsBox {
    display: flex;
    justify-content: space-between;
    padding: 0 0.02rem;
    margin: 0.1875rem 0 0.0677rem;

    //   饼图
    .echarts_bcg {
      position: relative;

      .colorBcg {
        position: relative;
        top: 0.0972rem;
        left: 20%;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 0.5208rem;
        height: 0.5208rem;
        background: #00325a;
        border-radius: 50%;
        font-size: 0.099rem;
        font-family: SourceHanSansCN-Bold;
        font-weight: bold;
        color: #e6e6e6;
        padding: 0.0521rem;
        text-align: center;
      }

      .careAgedEcharts {
        position: absolute;
        top: 0;
        left: 0.0104rem;
        width: 0.6927rem;
        height: 0.6927rem;
      }
    }

    //   饼图信息
    .PieBox {
      //margin-top: 0.0521rem;
      height: 0.6927rem;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
      margin-right: 0.1042rem;
      ul {
        li {
          display: flex;
          align-items: center;
          height: 0.2344rem;

          .title {
            display: flex;
            align-items: center;

            .blue {
              width: 0.0833rem;
              height: 0.0833rem;
              background: #199afd;
              border-radius: 50%;
              opacity: 1;
            }

            .skyblue {
              width: 0.0833rem;
              height: 0.0833rem;
              background: #4be8e9;
              border-radius: 50%;
              opacity: 1;
            }

            .name {
              font-size: 0.0938rem;
              font-family: SourceHanSansCN-Medium;
              font-weight: 500;
              color: #e6e6e6;
              margin: 0 0.151rem 0 0.0573rem;
            }
          }

          .num {
            font-size: 0.1458rem;
            font-family: YouSheBiaoTiHei;
            font-weight: 400;
            color: #e6e6e6;
          }
        }
      }
    }
  }

  .PowerconsumptionBox {
    margin: 0.0964rem 0 0.0885rem;

    ul {
      li {
        display: flex;
        align-items: center;
        justify-content: space-between;
        //justify-content: space-around;

        .OccupancyrateNumBox {
          .TotalnumberBox {
            display: flex;
            align-items: center;
            justify-content: space-evenly;
            margin-bottom: 0.125rem;

            img {
              width: 0.6198rem;
              height: 0.3333rem;
            }

            .Totalnumber {
              .num {
                font-size: 0.1927rem;
                font-family: SourceHanSansCN-Regular;
                font-weight: 400;
                color: #ffffff;
                letter-spacing: 0.0052rem;
              }

              .title {
                font-size: 0.1094rem;
                font-family: SourceHanSansCN-Medium;
                font-weight: 500;
                color: #ffffff;
                letter-spacing: 0.0052rem;
                opacity: 0.9;
              }
            }
          }

          .occpowerBox {
            display: flex;
            align-items: center;
            justify-content: space-evenly;
            width: 1.4427rem;
            height: 0.5781rem;
            background: url("~@/assets/imgs/electrical/occupan.png") no-repeat
              center / cover;
            background-size: 100% 100%;

            .powerBox {
              .num {
                font-size: 0.1563rem;
                font-family: SourceHanSansCN-Regular;
                font-weight: 400;
                color: #ffffff;
                letter-spacing: 0.0052rem;
              }

              .title {
                font-size: 0.0938rem;
                font-family: SourceHanSansCN-Medium;
                font-weight: 500;
                color: #ffffff;
                letter-spacing: 0.0052rem;
                opacity: 0.8;
              }
            }
          }
        }

        .Occupancyrate {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 0.7604rem;
          height: 0.7448rem;
          background: url("~@/assets/imgs/electrical/occEcharts_bcg.png")
            no-repeat center / cover;
          background-size: 100% 100%;

          .echartsNumBox {
            position: absolute;

            .numBox {
              display: flex;
              align-items: center;
              justify-content: center;

              .num {
                font-size: 0.1927rem;
                font-family: SourceHanSansCN-Regular;
                font-weight: 400;
                color: #ffffff;
                letter-spacing: 0.0052rem;
              }

              .type {
                font-size: 0.0938rem;
                font-family: SourceHanSansCN-Regular;
                font-weight: 400;
                color: #ffffff;
                letter-spacing: 0.0052rem;
                padding-top: 0.026rem;
              }
            }

            .title {
              font-size: 0.0833rem;
              font-family: SourceHanSansCN-Medium;
              font-weight: 500;
              color: #ffffff;
              //   letter-spacing: 0.0052rem;
            }
          }

          .annularEcharts {
            width: 0.6417rem;
            height: 0.6417rem;
          }
        }
      }
    }
  }

  //   监控
  .videoBox {
    margin-top: 0.1198rem;

    ul {
      display: flex;
      align-items: center;
      justify-content: space-around;

      li {
        position: relative;
        width: 1.0573rem;
        height: 0.6823rem;
        background: url("~@/assets/imgs/electrical/video_line_bcg.png")
          no-repeat center / cover;
        background-size: 100% 100%;
        overflow: hidden;
        .videoImg {
          position: absolute;
          top: 0;
          width: 1.0573rem;
          height: 0.6823rem;
          background: transparent;
          background-size: 100% 100%;
        }

        video {
          width: 1.0573rem;
          height: 0.6823rem;
        }

        video::-webkit-media-controls {
          display: none !important;
        }

        .video-js.vjs-fluid,
        .video-js.vjs-16-9,
        .video-js.vjs-4-3,
        .video-js.vjs-9-16,
        .video-js.vjs-1-1 {
          width: 1.0573rem;
          height: 0.6823rem;
        }
      }
    }
  }
}
</style>
