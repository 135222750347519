<template>
  <div class="home_box">
    <Title :titleList="titleList"></Title>
    <div class="PowerconsumptionBox">
      <ul>
        <li>
          <div ref="wavechart" class="wavechart"></div>
          <div class="powerBox">
            <p class="num">{{ EleconsList.deviceNum }}</p>
            <p class="title">智能照明总数</p>
          </div>
        </li>
      </ul>
    </div>
    <!--     照明概览-->
    <div class="AirconditioningBox">
      <ul>
        <li v-for="(item, index) in AirconditioningList" :key="index">
          <div class="powerBox">
            <div class="numBox">
              <p class="num">{{ item.num }}</p>
              <p class="type">个</p>
            </div>
            <p class="title">{{ item.name }}</p>
          </div>
        </li>
      </ul>
    </div>
    <smallTitle :titleList="environmentalonitoringList"></smallTitle>
    <!--    告警总数-->
    <div class="devicealarmsBox">
      <p class="title">设备告警总数：</p>
      <p class="num">{{ ararmNumToals }}</p>
    </div>
    <!--     图表-->
    <div class="Stack_Graph_box">
      <div class="Stack_Graph" ref="countrylig_sages"></div>
    </div>
    <!--     图表 信息-->
    <div class="EchartsnewsBox">
      <ul>
        <li v-for="(item, index) in EchartsnewsList" :key="index">
          <div class="title">
            <p :class="item.color"></p>
            <p class="name">{{ item.name }}</p>
          </div>
          <p class="num">{{ item.num }}</p>
        </li>
      </ul>
    </div>
    <!--  小标题-->
    <smallTitle :titleList="smalltitle"></smallTitle>
    <div class="echartsBox">
      <div class="echarts_bcg">
        <div class="colorBcg">在线比例</div>
        <div class="careAgedEcharts" ref="careAgedligEcharts"></div>
      </div>
      <!--     图表 数据 -->
      <div class="PieBox">
        <ul>
          <li v-for="(item, index) in EchartpieList" :key="index">
            <div class="title">
              <p :class="item.color"></p>
              <p class="name">{{ item.name }}</p>
            </div>
            <p class="num">{{ item.num }}%</p>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import * as echarts from "echarts";
import "echarts-liquidfill";
import Title from "@/components/Title";
import smallTitle from "@/components/smallTitle";
import { intelligentlighting } from "@/api/common";
import bus from "@/bus";

export default {
  components: {
    Title,
    smallTitle,
  },
  data() {
    return {
      // 标题
      titleList: {
        title: "照明概览",
      },
      // 标题
      environmentalonitoringList: {
        title: "设备告警",
      },
      // 小标题
      smalltitle: {
        title: "在线比例",
      },
      // 告警信息
      EchartsnewsList: [],
      //   饼图 数据
      EchartpieList: [],
      // 空调 数据
      AirconditioningList: [],
      // 拱宸桥Id
      labelIds: "528221552517316608",
      // 概览数据
      EleconsList: {},
      // 设备告警总数
      ararmNumToals: 0,
    };
  },
  mounted() {
    let labelIds = this.$store.state.labelIds;
    this.intelligentlightingHandle(labelIds);
    // bus.$on("labelIds", (val) => {
    //   console.log(val, "val");
    // this.intelligentlightingHandle(val)
    // });
  },
  methods: {
    // 照明概览
    async intelligentlightingHandle(labelIds) {
      const res = await intelligentlighting(labelIds);
      this.EleconsList = res;
      console.log(res, "res 照明概览");
      // 告警信息
      this.EchartsnewsList = [
        {
          color: "skyblue",
          name: "信息",
          num: res.infoNum,
        },
        {
          color: "orange",
          name: "告警",
          num: res.alarmNum,
        },
        {
          color: "cameobrown",
          name: "故障",
          num: res.faultNum,
        },
      ];
      (this.AirconditioningList = [
        {
          name: "当前在线数",
          num: res.onlineNum,
        },
        {
          name: "当前离线数",
          num: res.offlineNum,
        },
        {
          name: "当前故障数",
          num: res.offlineNum,
        },
      ]),
        (this.ararmNumToals =
          Number(res.infoNum) + Number(res.alarmNum) + Number(res.faultNum));
      let onlineNum = (Number(res.onlineNum) / Number(res.deviceNum)) * 100;
      let offlineNum = (Number(res.offlineNum) / Number(res.deviceNum)) * 100;
      let faultNum = (Number(res.faultNum) / Number(res.deviceNum)) * 100;
      console.log("sssssss", offlineNum);
      //   饼图 数据
      this.EchartpieList = [
        {
          color: "blue",
          name: "在线率",
          num: onlineNum,
        },
        {
          color: "skyblue",
          name: "离线率",
          num: offlineNum,
        },
        {
          color: "orange",
          name: "故障率",
          num: faultNum,
        },
      ];
      this.$nextTick(() => {
        this.careAgedEcharts(res.onlineNum, res.offlineNum, res.faultNum);
        this.CountryligSages(res.infoNum, res.alarmNum, res.faultNum);
        this.draw(res.deviceNum);
      });
    },
    //  图表
    CountryligSages(infoNum, alarmNum, faultNum) {
      // 基于准备好的dom，初始化echarts实例
      const myChart = echarts.init(this.$refs.countrylig_sages);
      myChart.setOption({
        // tooltip: {
        //   trigger: 'axis',
        //   axisPointer: {
        //     // Use axis to trigger tooltip
        //     type: 'shadow' // 'shadow' as default; can also be 'line' or 'shadow'
        //   }
        // },
        legend: {
          show: false,
        },
        grid: {
          left: "0.5%",
          right: "0.5%",
          containLabel: true,
        },
        xAxis: {
          type: "value",
          splitLine: {
            show: false,
          },
          axisLabel: {
            show: false,
          },
        },
        yAxis: {
          type: "category",
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            show: false,
          },
          data: ["数量"],
        },
        series: [
          {
            name: "会员",
            type: "bar",
            stack: "total",
            emphasis: {
              // focus: 'series'
            },
            label: {
              show: false,
            },
            itemStyle: {
              color: "#1EF8FF",
              // borderColor: '#fff',
              borderWidth: 1,
            },
            barWidth: 27,
            data: [infoNum],
          },
          {
            name: "",
            type: "bar",
            stack: "total",
            emphasis: {
              // focus: 'series'
            },
            label: {
              show: false,
            },
            itemStyle: {
              color: "rgba(255, 255, 255, .1)",
              // borderColor: 'rgba(255, 255, 255, .1)',
              borderWidth: 10,
            },
            barWidth: 27,
            data: [0],
          },
          {
            name: "",
            type: "bar",
            stack: "total",
            emphasis: {
              // focus: 'series'
            },
            label: {
              show: false,
            },
            itemStyle: {
              color: "#FBB351",
              // borderColor: 'rgba(255, 255, 255, .1)',
              borderWidth: 10,
            },
            barWidth: 27,
            data: [alarmNum],
          },
          {
            name: "",
            type: "bar",
            stack: "total",
            emphasis: {
              // focus: 'series'
            },
            label: {
              show: false,
            },
            itemStyle: {
              color: "rgba(255, 255, 255, .1)",
              // borderColor: 'rgba(255, 255, 255, .1)',
              borderWidth: 10,
            },
            barWidth: 27,
            data: [0],
          },
          {
            name: "理事",
            type: "bar",
            stack: "total",
            label: {
              show: false,
            },
            itemStyle: {
              color: "#FF6E6F",
              // borderColor: '#fff',
              borderWidth: 2,
            },
            barWidth: 27,
            emphasis: {
              // focus: 'series'
            },
            data: [faultNum],
          },
        ],
      });
    },
    //  饼图
    careAgedEcharts(onlineNum, offlineNum, faultNum) {
      // 基于准备好的dom，初始化echarts实例
      const myChart = echarts.init(this.$refs.careAgedligEcharts);
      // 绘制图表
      myChart.setOption({
        color: ["#199BFF", "#4CE9E9", "#F8B051"], //饼状图颜⾊数组
        tooltip: {
          trigger: "item",
          //提示框样式调整
          textStyle: {
            color: "#e6e6e6",
            fontFamily: "SourceHanSansCN-Regular",
            fontSize: 14,
          },
          extraCssText:
            "border: 1px solid #e6e6e6;color: #ccc;font-family:'SourceHanSansCN-Regular';background:rgba(39, 69, 86,.8);font-size:14px;",
        },
        series: [
          {
            type: "pie",
            radius: ["70%", "90%"],
            avoidLabelOverlap: false,

            label: {
              show: false,
              position: "center",
            },
            emphasis: {
              label: {
                show: false,
                fontSize: "14",
                fontWeight: "bold",
              },
            },
            labelLine: {
              show: false,
            },
            data: [onlineNum, offlineNum, faultNum],
          },
        ],
      });
    },
    // 水波球
    draw(newVal) {
      const myChart = echarts.init(this.$refs.wavechart);
      //ECharts5 除了一贯的默认主题外，还内置了'dark'主题。可以像这样切换成深色模式：var chart = echarts.init(dom, 'dark');
      //       window.onresize = myChart.resize;//在窗口大小改变时，相应的元素能够适应新的窗口尺寸。
      const option = {
        title: {
          show: false,
          text: "",
          // text: [`{a|${newVal.toFixed(2)}}`, "%\n完成率"].join(""),//'\n'换行，主标题
          textStyle: {
            //主标题文字的样式
            rich: {
              //在 rich 里面，可以自定义富文本样式。利用富文本样式，可以在标签中做出非常丰富的效果。例子：formatter: ['{a|这段文本采用样式a}','{b|这段文本采用样式b}这段用默认样式{x|这段用样式x}'].join('\n')
              a: {
                fontSize: 18,
              },
            },
            color: "#E2F8FF",
            fontSize: 16,
            lineHeight: 24,
            fontWeight: 400,
          },
          subtext: "Sub Title", //副标题
          subtextStyle: { fontSize: 20 }, //副标题文字的样式
          left: "center",
          top: "center", //title 组件离容器上侧的距离。
        },
        series: [
          {
            type: "liquidFill",
            center: ["50%", "50%"],
            radius: "98%",
            data: [newVal / 100],
            direction: "right", //波浪的方向｛left right none}
            outline: {
              show: true, //是否显示轮廓 布尔值
              borderDistance: 15, // 外部边框与图表的距离 数字
              itemStyle: {
                borderColor: {
                  // 边框的颜色
                  type: "linear",
                  x: 0,
                  y: 0,
                  x2: 0,
                  y2: 1,
                  colorStops: [
                    {
                      offset: 0,
                      color: ["#2E5491"], // 0% 处的颜色
                    },
                    {
                      offset: 0.5,
                      color: ["#2E5491"], // 0% 处的颜色
                    },
                    {
                      offset: 1,
                      color: ["#2E5491"], // 100% 处的颜色
                    },
                  ],
                  global: false, // 缺省为 false
                },
                borderWidth: 1, // 边框的宽度
                shadowOffsetX: 0,
                shadowOffsetY: 8,
                shadowColor: "#1C4B97", //外部轮廓的阴影颜色
              },
            },
            itemStyle: {
              opacity: 0.8, // 波浪的透明度·
              shadowBlur: 0, // 波浪的阴影范围
              color: {
                type: "linear",
                x: 0,
                y: 1,
                x2: 0,
                y2: 0,
                colorStops: [
                  {
                    offset: 0,
                    color: ["#73A9FF"], // 0% 处的颜色
                  },
                  {
                    offset: 1,
                    color: ["#73A9FF"], // 100% 处的颜色
                  },
                ],
                global: false, // 缺省为 false
              },
            },
            backgroundStyle: {
              color: {
                type: "radial",
                x: 0.5,
                y: 0.5,
                r: 0.5,
                colorStops: [
                  {
                    offset: 0,
                    color: ["#2E5491"], // 0% 处的颜色
                  },
                  {
                    offset: 0.6,
                    color: ["#2E5491"], // 60% 处的颜色
                  },
                  {
                    offset: 0.93,
                    color: ["#2E5491"], // 93% 处的颜色
                  },
                  {
                    offset: 1,
                    color: ["#2E5491"], // 100% 处的颜色
                  },
                ],
                global: false, // 缺省为 false
              },
            },
            label: {
              // 数据展示样式
              show: false,
            },
          },
        ],
      };
      myChart.setOption(option);
    },
  },
};
</script>

<style lang="less" scoped>
.home_box {
  width: 2.224rem;
  height: 5.1823rem;
  position: absolute;
  right: 0.125rem;
  top: 0.4427rem;
  // 空调概览
  .PowerconsumptionBox {
    margin: 0.1615rem 0 0.1615rem;

    ul {
      display: flex;
      align-items: center;
      justify-content: center;

      li {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 1.2865rem;
        height: 1.1667rem;
        background: url("~@/assets/imgs/intelig/light_bcg.png") no-repeat center /
          cover;
        background-size: 100% 100%;

        .wavechart {
          width: 0.651rem;
          height: 0.651rem;
          margin-top: -0.08rem;
          margin-left: -0.018rem;
        }

        .powerBox {
          position: absolute;
          margin-top: 0.097rem;

          .num {
            text-align: center;
            font-size: 0.3385rem;
            font-family: SourceHanSansCN-Regular;
            font-weight: 400;
            color: #ffffff;
            height: 0.35rem;
            line-height: 0.35rem;
            margin-top: 0.227rem;
          }

          .title {
            font-size: 0.1354rem;
            font-family: SourceHanSansCN-Regular;
            font-weight: 400;
            color: #e7e7e7;
            letter-spacing: 0.0052rem;
            margin-top: 0.227rem;
          }
        }
      }
    }
  }

  // 空调数据
  .AirconditioningBox {
    height: 0.5833rem;
    margin: 0.1667rem 0 0.2708rem;

    ul {
      display: flex;
      align-items: center;
      justify-content: space-around;

      li {
        display: flex;

        .powerBox {
          .title {
            font-size: 0.0781rem;
            font-family: SourceHanSansCN-Regular;
            font-weight: 400;
            color: #e7e7e7;
            letter-spacing: 0.0052rem;
            height: 0.09rem;
            line-height: 0.09rem;
          }

          .numBox {
            display: flex;
            //align-items: center;
            justify-content: center;
            width: 0.4271rem;
            height: 0.4427rem;
            line-height: 0.4427rem;
            background: url("~@/assets/imgs/HVAC/currentAir_icon.png") no-repeat
              center / cover;
            background-size: 100% 100%;

            .num {
              font-size: 0.1458rem;
              font-family: YouSheBiaoTiHei;
              font-weight: 400;
              color: #ffffff;
              text-shadow: 0px 0px 0.013rem rgba(19, 159, 255, 0.8);
              padding-right: 0.0052rem;
            }

            .type {
              line-height: 0.4688rem;
              font-family: YouSheBiaoTiHei;
              font-weight: 400;
              color: #ffffff;
              font-size: 0.0833rem;
            }
          }
        }
      }
    }
  }
  //   告警总数
  .devicealarmsBox {
    display: flex;
    align-items: center;
    margin: 0.151rem 0 0.0833rem;

    .title {
      font-size: 0.0781rem;
      font-family: SourceHanSansCN-Medium;
      font-weight: 500;
      color: #ffffff;
    }

    .num {
      font-size: 0.125rem;
      font-family: YouSheBiaoTiHei;
      font-weight: 400;
      color: #1ef8ff;
    }
  }

  //   图表
  .Stack_Graph_box {
    width: 2.224rem;
    height: 0.125rem;
    background: url("~@/assets/imgs/Energy/line_bcg.png") no-repeat center /
      cover;
    background-size: 100% 100%;

    .Stack_Graph {
      padding: 0.01rem 0;
      height: 0.125rem;
    }
  }

  // 饼图
  .echartsBox {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 0.8906rem;
    padding: 0 0.02rem;
    margin: 0.09375rem 0 0.1068rem;

    //   饼图
    .echarts_bcg {
      position: relative;
      height: 0.6927rem;
      .colorBcg {
        position: relative;
        top: 0.0821rem;
        left: 20%;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 0.5156rem;
        height: 0.5156rem;
        background: #00325a;
        border-radius: 50%;
        font-size: 0.0885rem;
        font-family: SourceHanSansCN-Bold;
        font-weight: bold;
        color: #e6e6e6;
        padding: 0.055rem;
        text-align: center;
      }

      .careAgedEcharts {
        position: absolute;
        top: 0;
        width: 0.6927rem;
        height: 0.6927rem;
      }
    }

    //   饼图信息
    .PieBox {
      width: 50%;
      //margin-top: 0.0521rem;
      ul {
        li {
          display: flex;
          justify-content: space-evenly;
          align-items: center;
          height: 0.1875rem;

          .title {
            display: flex;
            align-items: center;

            .blue {
              width: 0.0833rem;
              height: 0.0833rem;
              background: #199afd;
              border-radius: 50%;
              opacity: 1;
            }

            .skyblue {
              width: 0.0833rem;
              height: 0.0833rem;
              background: #4be8e9;
              border-radius: 50%;
              opacity: 1;
            }

            .orange {
              width: 0.0833rem;
              height: 0.0833rem;
              background: #f8b051;
              border-radius: 50%;
              opacity: 1;
            }

            .name {
              font-size: 0.0938rem;
              font-family: SourceHanSansCN-Medium;
              font-weight: 500;
              color: #e6e6e6;
              margin: 0 0.151rem 0 0.0573rem;
            }
          }

          .num {
            width: 40%;
            font-size: 0.1458rem;
            font-family: YouSheBiaoTiHei;
            font-weight: 400;
            color: #e6e6e6;
            text-align: right;
          }
        }
      }
    }
  }

  //   图表信息
  .EchartsnewsBox {
    margin: 0.1198rem 0 0.2448rem;

    ul {
      display: flex;
      justify-content: space-around;

      li {
        display: flex;
        align-items: center;

        .title {
          display: flex;
          align-items: center;

          .name {
            font-size: 0.0729rem;
            font-family: SourceHanSansCN-Medium;
            font-weight: 500;
            color: #e6e6e6;
            margin: 0 0.0469rem 0 0.0156rem;
          }
        }

        .num {
          font-size: 0.1146rem;
          font-family: YouSheBiaoTiHei;
          font-weight: 400;
          color: #ffffff;
        }

        .skyblue {
          width: 0.0729rem;
          height: 0.0729rem;
          background: #1ef8ff;
          border-radius: 0px 0px 0px 0px;
          opacity: 1;
        }

        .orange {
          width: 0.0729rem;
          height: 0.0729rem;
          background: #fbb351;
          border-radius: 0px 0px 0px 0px;
          opacity: 1;
        }

        .cameobrown {
          width: 0.0729rem;
          height: 0.0729rem;
          background: #ff6e6f;
          border-radius: 0px 0px 0px 0px;
          opacity: 1;
        }
      }
    }
  }
}
</style>
