<template>
  <div class="monitor_">
    <div class="close" @click="close">
      <img src="@/assets/imgs/close.png" alt="" />
    </div>
    <div class="title">
      <img src="@/assets/imgs/monitor_s.png" alt="" />
      <span>{{ $store.state.monitorData.name }}</span>
    </div>
    <div class="content"></div>
    <div class="videoBox">
      <ul>
        <li>
          <video
            id="video_playerg"
            ref="video_player"
            style="width: 100%; height: 100%"
            controls
          ></video>
          <!-- <div class="videoImg" id="videonotificationCodeCLK"></div> -->
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { invokeService } from "@/api/commons";
export default {
  props: {
    // popupData: {
    //   type: Object,
    //   default: "",
    // },
  },
  data() {
    return {
      dataList: {},
      webRtcServer: null,
    };
  },
  mounted() {
    this.videoPlay();
    // this.videoSrcPlay();
  },
  updated() {
    this.videoPlay();
  },
  methods: {
    // 监控

    videoPlay() {
      invokeService(this.$store.state.monitorData.devId).then((res) => {
        console.log(res.data);
        // let url = `${res.data.outputParams.url}?channel=1&subtype=1`;
        // this.webRtcServer = new WebRtcStreamer(
        //   "videonotificationCode",
        //   location.protocol + "//127.0.0.1:8000"
        // );
        // //需要看的rtsp视频地址，可以在网上找在线的rtsp视频地址来进行demo实验，在vlc中能播放就能用
        // this.webRtcServer.connect(url);
        // setTimeout(() => {
        //   document.getElementById("videonotificationCodeCLK").click();
        // }, 20);
        var video = document.getElementById("video_playerg");
        // console.log(video, "video");
        var hls = new Hls();
        hls.loadSource(res.data.outputParams.url);
        hls.attachMedia(video);
        hls.on(Hls.Events.MANIFEST_PARSED, function () {
          video.play();
        });
      });
    },
    close() {
      this.$store.commit("changeMonitorPopup", false);
    },
  },
};
</script>

<style lang="less" scoped>
.monitor_ {
  width: 3.3359rem;
  height: 2.0026rem;
  background-image: url("~@/assets/imgs/monitorPopup.png");
  background-size: 100% 100%;
  background-position: 0 0;
  background-repeat: no-repeat;
  position: absolute;
  top: 0.8802rem;
  left: 3.2698rem;
  z-index: 999;
  .close {
    width: 0.1875rem;
    height: 0.1875rem;
    position: absolute;
    top: -0.0286rem;
    right: -0.0286rem;
    cursor: pointer;

    img {
      width: 100%;
      height: 100%;
    }
  }
  .title {
    font-size: 0.099rem;
    font-family: "SourceHanSansCN-Regular";
    font-weight: bold;
    color: #ffffff;
    margin-top: 0.1198rem;
    margin-left: 0.3073rem;
    display: flex;
    align-items: center;
    img {
      width: 0.1302rem;
      height: 0.1302rem;
      margin-right: 0.0677rem;
    }
  }
  //   监控
  .videoBox {
    width: 3.0052rem;
    height: 1.5573rem;
    margin: 0 auto;
    margin-top: 0.0781rem;
    ul {
      display: flex;
      align-items: center;
      justify-content: space-around;

      li {
        position: relative;
        width: 3.0052rem;
        height: 1.5573rem;
        overflow: hidden;

        .videoImg {
          position: absolute;
          top: 0;
          width: 3.0052rem;
          height: 1.5573rem;
          background: transparent;
          background-size: 100% 100%;
        }

        video {
          width: 3.0052rem;
          height: 1.5573rem;
        }

        video::-webkit-media-controls {
          display: none !important;
        }

        .video-js.vjs-fluid,
        .video-js.vjs-16-9,
        .video-js.vjs-4-3,
        .video-js.vjs-9-16,
        .video-js.vjs-1-1 {
          width: 3.0052rem;
          height: 1.5573rem;
        }
      }
    }
  }
}
</style>
