<template>
  <div class="floor_tab" v-if="$store.state.isFloor">
    <div class="floor">
      <div
        :class="isFloor == index ? 'itemActive_' : 'item'"
        v-for="(item, index) in floorTabList"
        :key="index"
        @click="changeFloor(index)"
      >
        {{ item }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      floorTabList: ["6F", "5F", "4F", "3F", "2F", "1F"],
      isFloor: -1,
    };
  },
  methods: {
    changeFloor(index) {
      this.isFloor = index;
      ue4("SetBuildShow", false);
      ue4("MoveToTarget", "9号楼");
      if (index == 0) {
        ue4("SetFloor", 6);
      } else if (index == 1) {
        ue4("SetFloor", 5);
      } else if (index == 2) {
        ue4("SetFloor", 4);
      } else if (index == 3) {
        ue4("SetFloor", 3);
      } else if (index == 4) {
        ue4("SetFloor", 2);
      } else if (index == 5) {
        ue4("SetFloor", 1);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.floor_tab {
  width: 0.2057rem;
  height: 1.1693rem;
  background-image: url("~@/assets/imgs/floor_tab.png");
  background-size: 100% 100%;
  background-position: 0 0;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  align-items: center;
  .floor {
    width: 100%;
    height: 1.0156rem;
    margin-top: 0.1536rem;
    .item {
      width: 0.2031rem;
      height: 0.1693rem;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 0.0729rem;
      font-family: "SourceHanSansCN-Regular";
      font-weight: 500;
      color: #ffffff;
      cursor: pointer;
    }
    .itemActive_ {
      width: 0.2031rem;
      height: 0.1693rem;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 0.0729rem;
      font-family: "SourceHanSansCN-Regular";
      font-weight: 500;
      color: #ffffff;
      background-image: url("~@/assets/imgs/itemActive_.png");
      background-size: 100% 100%;
      background-position: 0 0;
      background-repeat: no-repeat;
      cursor: pointer;
    }
  }
}
</style>