<template>
  <div class="home_box">
    <img src="@/assets/imgs/event.png" alt="" />
  </div>
</template>

<script>
export default {};
</script>

<style lang="less" scoped>
.home_box {
  width: 2.224rem;
  height: 5.1823rem;
  position: absolute;
  right: 0.125rem;
  top: 0.4427rem;
  img {
    width: 100%;
    height: 100%;
  }
}
</style>