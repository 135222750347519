<template>
  <div class="home_box">
    <!-- <img src="@/assets/tietu/Park_situation_right.png" alt="" /> -->
    <div class="page">
      <div class="page_left">
        <div class="iot_overview">
          <Title :titleList="iotData"></Title>
          <div class="content">
            <div class="device_">
              <div class="left_">
                <p>{{ deviceList.deviceNum }}</p>
                <p>设备总数</p>
              </div>
              <div class="right_">
                <p>
                  <span>{{ deviceList.onlineNum }}</span>
                  <span>在线数</span>
                </p>
                <p>
                  <span>{{ deviceList.offlineNum }}</span>
                  <span>离线数</span>
                </p>
              </div>
            </div>
            <div class="alarms_total">
              <p>
                设备告警总数：<span>{{ deviceTotal }}</span>
              </p>
              <div class="alarms_chart">
                <div id="alarms_chart"></div>
              </div>
            </div>
          </div>
        </div>
        <div class="intelligent_energy">
          <Title :titleList="energyData"></Title>
          <div class="content">
            <div class="item" v-for="(item, index) in energyDatas" :key="index">
              <div class="img">
                <img :src="item.url" alt="" />
              </div>
              <div class="name_value">
                <p class="name">{{ item.name }}</p>
                <p class="value">
                  <span>{{ item.value }}</span
                  >{{ item.unit }}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="device_type">
          <Title :titleList="typeData"></Title>
          <div class="content">
            <div class="shadow"></div>
            <div class="type_chart">
              <div id="type_chart"></div>
            </div>
          </div>
        </div>
        <div class="monitoring">
          <div class="monitoring_title">
            <p>视频监控</p>
          </div>
          <div class="videoBox">
            <ul>
              <li>
                <video
                  id="video_playera"
                  ref="video_player"
                  style="width: 100%; height: 100%"
                  controls
                ></video>
                <!-- <div class="videoImg" id="videoCodeCLK"></div> -->
              </li>
              <li>
                <video
                  id="video_playerb"
                  ref="video_player"
                  style="width: 100%; height: 100%"
                  controls
                ></video>
                <!-- <div class="videoImg" id="videoTwoCodeCLK"></div> -->
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <!-- <div class="point_tab">
      <div
        :class="pointTab == index ? 'itemActives' : 'item'"
        v-for="(item, index) in pointTabList"
        :key="index"
        @click="changePoint(index)"
      >
        <img :src="pointTab == index ? item.urlActive : item.url" alt="" />
        <p>{{ item.text }}</p>
      </div>
    </div> -->
  </div>
</template>

<script>
import * as echarts from "echarts";
import Title from "@/components/Title.vue";
import smallTitle from "@/components/smallTitle.vue";
import vueSeamlessScroll from "vue-seamless-scroll";
import floorTab from "@/components/floorTab.vue";
import { deviceState, deviceType } from "@/api/common";
import { invokeService } from "@/api/commons";
export default {
  components: { Title, smallTitle, vueSeamlessScroll, floorTab },
  data() {
    return {
      iotData: {
        title: "物联概览",
      },
      typeData: {
        title: "设备类型",
      },
      energyData: {
        title: "智能能耗",
      },
      energyDatas: [
        {
          name: "总能耗",
          value: "6684",
          unit: "KWh",
          url: require("@/assets/imgs/qqq.png"),
        },
        {
          name: "总电费",
          value: "3342",
          unit: "¥",
          url: require("@/assets/imgs/fgf.png"),
        },
      ],
      monitoringList: [
        {
          url: "",
        },
        {
          url: "",
        },
        {
          url: "",
        },
        {
          url: "",
        },
      ],
      pointTabList: [
        {
          url: require("@/assets/imgs/monitor_icon_2.png"),
          urlActive: require("@/assets/imgs/monitor_icon_1.png"),
          text: "校园监控",
        },
        {
          url: require("@/assets/imgs/building_2.png"),
          urlActive: require("@/assets/imgs/building_1.png"),
          text: "楼宇情况",
        },
      ],
      pointTab: -1,
      webRtcServer: null,
      deviceList: {
        deviceNum: 0,
        onlineNum: 0,
        offlineNum: 0,
      },
      deviceTotal: 0,
    };
  },
  computed: {
    scrollOption() {
      return {
        step: 0.5, // 数值越大速度滚动越快
        limitMoveNum: 2, // 开始无缝滚动的数据量 this.list.length+1
        hoverStop: true, // 是否开启鼠标悬停stop
        direction: 1, // 0向下 1向上 2向左 3向右
        openWatch: true, // 开启数据实时监控刷新dom
        singleHeight: 21, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 10, // 单步运动停止的时间(默认值1000ms)
      };
    },
  },
  mounted() {
    // this.typeChart();
    this.videoPlay();
    this.videoTwoPlay();
    this.deviceStateApi();
    this.deviceTypeApi();
  },
  updated() {
    this.videoPlay();
    this.videoTwoPlay();
    this.deviceTypeApi();
  },
  methods: {
    deviceTypeApi() {
      deviceType().then((res) => {
        console.log(res, "设备类型");
        let data = res.data.content.map((item) => {
          return {
            name: item.product_name,
            value: Number(item.count),
          };
        });
        this.typeChart(data);
      });
    },
    //物联概览
    deviceStateApi() {
      deviceState().then((res) => {
        console.log(res, "物联概览");
        this.deviceList = {
          deviceNum: res.deviceNum,
          onlineNum: res.onlineNum,
          offlineNum: res.offlineNum,
        };
        let data = [
          {
            name: "信息",
            value: res.infoNum,
          },
          {
            name: "告警",
            value: res.alarmNum,
          },
          {
            name: "故障",
            value: res.faultNum,
          },
        ];

        this.deviceTotal =
          Number(res.infoNum) + Number(res.alarmNum) + Number(res.faultNum);
        this.alarmsChart(data);
      });
    },
    // 监控
    videoPlay() {
      invokeService("e0dc27bf582a4037aa3f2c15872d0a0a").then((res) => {
        console.log(res, "============监控===========");
        // let url = `${res.data.outputParams.url}?channel=1&subtype=1`;
        // this.webRtcServer = new WebRtcStreamer(
        //   "videoCode",
        //   location.protocol + "//127.0.0.1:8000"
        // );
        // //需要看的rtsp视频地址，可以在网上找在线的rtsp视频地址来进行demo实验，在vlc中能播放就能用
        // this.webRtcServer.connect(url);
        // setTimeout(() => {
        //   document.getElementById("videoCodeCLK").click();
        // }, 20);
        var video = document.getElementById("video_playera");
        // console.log(video, "video");
        var hls = new Hls();
        hls.loadSource(res.data.outputParams.url);
        hls.attachMedia(video);
        hls.on(Hls.Events.MANIFEST_PARSED, function () {
          video.play();
        });
      });
    },
    videoTwoPlay() {
      invokeService("d47cd9fabc7a437caf9af7dc2a20441e").then((res) => {
        console.log(res, "============监控===========");
        // let url = `${res.data.outputParams.url}?channel=1&subtype=1`;
        // this.webRtcServer = new WebRtcStreamer(
        //   "videoTwoCode",
        //   location.protocol + "//127.0.0.1:8000"
        // );
        // //需要看的rtsp视频地址，可以在网上找在线的rtsp视频地址来进行demo实验，在vlc中能播放就能用
        // this.webRtcServer.connect(url);
        // setTimeout(() => {
        //   document.getElementById("videoTwoCodeCLK").click();
        // }, 20);
        var video = document.getElementById("video_playerb");
        // console.log(video, "video");
        var hls = new Hls();
        hls.loadSource(res.data.outputParams.url);
        hls.attachMedia(video);
        hls.on(Hls.Events.MANIFEST_PARSED, function () {
          video.play();
        });
      });
    }, // 监控

    alarmsChart(data) {
      // let data = [
      //   {
      //     name: "信息",
      //     value: 0,
      //   },
      //   {
      //     name: "告警",
      //     value: 0,
      //   },
      //   {
      //     name: "故障",
      //     value: 0,
      //   },
      // ];
      let chart = echarts.init(document.getElementById("alarms_chart"));
      // 监听屏幕变化自动缩放图表
      window.addEventListener("resize", function () {
        chart.resize();
      });
      // 绘制图表
      chart.setOption({
        color: [
          "rgba(30, 248, 255, 1)",
          "rgba(251, 179, 81, 1)",
          "rgba(255, 110, 111, 1)",
        ],
        // 设置图表的位置
        grid: {
          // x: 60, // 左间距
          // y: 80, // 上间距
          // x2: 60, // 右间距
          // y2: 40, // 下间距
          top: "50%",
          left: 0,
          right: "0%",

          containLabel: true, // grid 区域是否包含坐标轴的刻度标签, 常用于『防止标签溢出』的场景
        },
        // 提示框组件
        // tooltip: {
        //   trigger: 'axis', // 触发类型, axis: 坐标轴触发
        //   axisPointer: {
        //   	// 指示器类型  'line' 直线指示器 'shadow' 阴影指示器 'none' 无指示器
        //   	// 'cross' 十字准星指示器 其实是种简写，表示启用两个正交的轴的 axisPointer
        //     type: 'none'
        //   },
        //   textStyle: {
        //     color: '#cdd3ee' // 文字颜色
        //   },
        //   // 提示框浮层内容格式器，支持字符串模板和回调函数两种形式 折线（区域）图、柱状（条形）图、K线图
        //   // {a}（系列名称），{b}（类目值），{c}（数值）, {d}（无）
        //   formatter: '{b}<br />{a0}: {c0}%<br />{a1}: {c1}%<br />{a2}: {c2}%'
        // },
        // 图例组件
        legend: {
          textStyle: {
            // 文本样式
            fontSize: this.fontSize(14),
            color: "#cdd3ee",
          },
          itemWidth: this.fontSize(16),
          itemHeight: this.fontSize(16),
          itemGap: this.fontSize(50),
          bottom: 0, // 定位
          data: data, // 图例的数据数组
          formatter: (name) => {
            // 添加
            let total = 0;
            let target;
            for (let i = 0; i < data.length; i++) {
              total += data[i].value;
              if (data[i].name === name) {
                target = data[i].value;
              }
            }
            var arr = [];
            return `{a|${name}} {b|${target}}`;
          },
          textStyle: {
            // 添加
            padding: [0, 0, 0, 0],
            rich: {
              a: {
                // 设置文字、数学、英语这一列的样式
                width: this.fontSize(50),
                color: "rgba(231, 231, 231, 1)",
                fontSize: this.fontSize(16),
                // fontWeight: "bolder",
                verticalAlign: "middle",
              },
              b: {
                // 设置文字、数学、英语这一列的样式
                color: "rgba(231, 231, 231, 1)",
                fontSize: this.fontSize(22),
                fontFamily: "DigitalRegular",
                verticalAlign: "middle",
              },
            },
          },
        },
        // X轴
        xAxis: {
          type: "value", // 坐标轴类型,   'value' 数值轴，适用于连续数据
          // 坐标轴刻度
          axisTick: {
            show: false, // 是否显示坐标轴刻度 默认显示
          },
          // 坐标轴轴线
          axisLine: {
            // 是否显示坐标轴轴线 默认显示
            show: false, // 是否显示坐标轴轴线 默认显示
          },
          // 坐标轴在图表区域中的分隔线
          splitLine: {
            show: false, // 是否显示分隔线。默认数值轴显示
          },
          // 坐标轴刻度标签
          axisLabel: {
            show: false, // 是否显示刻度标签 默认显示
          },
        },
        yAxis: [
          // 左侧Y轴
          {
            // 坐标轴类型,  'category' 类目轴，适用于离散的类目数据
            // 为该类型时必须通过 data 设置类目数据
            type: "category",
            // 坐标轴刻度
            axisTick: {
              show: false, // 是否显示坐标轴刻度 默认显示
            },
            // 坐标轴轴线
            axisLine: {
              // 是否显示坐标轴轴线 默认显示
              show: false, // 是否显示坐标轴轴线 默认显示
              lineStyle: {
                // 坐标轴线线的颜色
                color: "#cdd3ee",
              },
            },
            // 坐标轴在图表区域中的分隔线
            splitLine: {
              show: false, // 是否显示分隔线。默认数值轴显示
            },
            // 坐标轴刻度标签
            axisLabel: {
              show: false, // 是否显示刻度标签 默认显示
              fontSize: this.fontSize(12), // 文字的字体大小
              color: "#cdd3ee", // 刻度标签文字的颜色
              // 使用字符串模板，模板变量为刻度默认标签 {value}
              formatter: "{value}",
            },
            // 类目数据，在类目轴（type: 'category'）中有效
            data: ["北京"],
          },
        ],
        // 系列列表
        series: [
          {
            type: "bar", // 系列类型
            name: "信息", // 系列名称, 用于tooltip的显示, legend 的图例筛选
            // 数据堆叠，同个类目轴上系列配置相同的stack值后，后一个系列的值会在前一个系列的值上相加
            stack: "总量",
            barMaxWidth: 16, // 柱条的最大宽度，不设时自适应
            barWidth: this.fontSize(16),
            // 图形上的文本标签
            label: {
              show: false,
              // 标签的位置 left right bottom top inside  // 绝对的像素值 position: [10, 10]
              // 相对的百分比 position: ['50%', '50%']
              position: "inside",
            },
            // 图形样式
            itemStyle: {
              // barBorderRadius: [10, 0, 0, 10], // 圆角半径, 单位px, 支持传入数组分别指定 4 个圆角半径
            },
            data: [data[0].value], // 系列中的数据内容数组
          },
          {
            type: "bar", // 系列类型
            name: "告警", // 系列名称, 用于tooltip的显示, legend 的图例筛选
            // 数据堆叠，同个类目轴上系列配置相同的stack值后，后一个系列的值会在前一个系列的值上相加
            stack: "总量",
            barMaxWidth: 12, // 柱条的最大宽度，不设时自适应
            // 图形上的文本标签
            label: {
              show: false,
              // 标签的位置 left right bottom top inside  // 绝对的像素值 position: [10, 10]
              // 相对的百分比 position: ['50%', '50%']
              position: "inside",
            },
            data: [data[1].value], // 系列中的数据内容数组
          },
          {
            type: "bar", // 系列类型
            name: "故障", // 系列名称, 用于tooltip的显示, legend 的图例筛选
            // 数据堆叠，同个类目轴上系列配置相同的stack值后，后一个系列的值会在前一个系列的值上相加
            stack: "总量",
            barMaxWidth: 16, // 柱条的最大宽度，不设时自适应
            // 图形上的文本标签
            label: {
              show: false,
              // 标签的位置 left right bottom top inside  // 绝对的像素值 position: [10, 10]
              // 相对的百分比 position: ['50%', '50%']
              position: "inside",
            },
            // 图形样式
            itemStyle: {
              // barBorderRadius: [0, 10, 10, 0], // 圆角半径, 单位px, 支持传入数组分别指定 4 个圆角半径
            },
            data: [data[2].value], // 系列中的数据内容数组
          },
        ],
      });
    },
    typeChart(data) {
      let myChartMachine = echarts.init(document.getElementById("type_chart"));
      // 圆环图各环节的颜色
      var color = [
        "rgba(0, 174, 179, 1)",
        "rgba(0, 111, 217, 1)",
        "rgba(89, 238, 242, 1)",
        "rgba(108, 189, 255, 1)",
        "rgba(42, 145, 243, 1)",
      ];

      // 圆环图各环节的名称和值(系列中各数据项的名称和值)
      // var data = [
      //   {
      //     name: "监控设备",
      //     value: 15,
      //   },
      //   {
      //     name: "门禁设备",
      //     value: 24,
      //   },
      //   {
      //     name: "空调设备",
      //     value: 18,
      //   },
      //   {
      //     name: "照明设备",
      //     value: 38,
      //   },
      //   {
      //     name: "能耗设备",
      //     value: 16,
      //   },
      // ];

      let option = {
        // 图例
        legend: [
          {
            icon: "circle",
            textStyle: {
              color: "#2efcfa",
            },
            orient: "vertical",
            top: "middle",
            itemGap: this.fontSize(0),
            // top: 0,
            itemWidth: this.fontSize(16),
            itemHeight: this.fontSize(16),
            data: data,
            right: 0,
            formatter: (name) => {
              // 添加
              let total = 0;
              let target;
              for (let i = 0; i < data.length; i++) {
                total += data[i].value;
                if (data[i].name === name) {
                  target = data[i].value;
                }
              }
              var arr = [];
              let per = ((target / total) * 100).toFixed(0) + "%";
              return `{a|${name}} {b|${per}}`;
            },
            textStyle: {
              // 添加
              padding: [0, 0, 0, 0],
              rich: {
                a: {
                  // 设置文字、数学、英语这一列的样式
                  width: this.fontSize(100),
                  color: "rgba(231, 231, 231, 1)",
                  fontSize: this.fontSize(16),
                  // fontWeight: "bolder",
                  verticalAlign: "middle",
                },
                b: {
                  // 设置文字、数学、英语这一列的样式
                  color: "rgba(231, 231, 231, 1)",
                  fontSize: this.fontSize(20),
                  fontFamily: "DigitalRegular",
                  verticalAlign: "middle",
                },
              },
            },
          },
        ],
        // backgroundColor: "rgba(218, 218, 218, .1)",
        // graphic 是原生图形元素组件。可以支持的图形元素包括：image, text, circle, sector, ring, polygon, polyline, rect, line, bezierCurve, arc, group,

        // 系列列表
        series: [
          {
            name: "圆环图系列名称", // 系列名称
            type: "pie", // 系列类型
            center: ["22%", "49%"], // 饼图的中心（圆心）坐标，数组的第一项是横坐标，第二项是纵坐标。[ default: ['50%', '50%'] ]
            radius: ["55%", "65%"], // 饼图的半径，数组的第一项是内半径，第二项是外半径。[ default: [0, '75%'] ]
            hoverAnimation: true, // 是否开启 hover 在扇区上的放大动画效果。[ default: true ]
            color: color, // 圆环图的颜色
            itemStyle: {
              borderRadius: "100%",
              borderColor: "rgba(218, 218, 218, .1)",
              borderWidth: "10",
            },
            label: {
              width: 12,
              // 饼图图形上的文本标签，可用于说明图形的一些数据信息，比如值，名称等.
              normal: {
                show: true, // 是否显示标签[ default: false ]
                position: "center", // 标签的位置。'outside'饼图扇区外侧，通过视觉引导线连到相应的扇区。'inside','inner' 同 'inside',饼图扇区内部。'center'在饼图中心位置。
                formatter: "设备类型\n占比", // 标签内容
                fontSize: this.fontSize(20),
                color: "#fff",
              },
            },
            data: data, // 系列中的数据内容数组。
          },
        ],
      };
      myChartMachine.setOption(option, true);
      window.addEventListener("resize", () => {
        if (myChartMachine) {
          myChartMachine.resize();
        }
      });
    },
    changePoint(index) {
      ue4("SetIconShow", false);
      this.$store.commit("changeOverviewPopup", false);
      this.$store.commit("changeEnergyPopup", false);
      this.$store.commit("changeMonitorPopup", false);
      if (this.pointTab == index) {
        this.pointTab = -1;
      } else {
        this.pointTab = index;
        if (index == 0) {
          ue4("SetIconShowByType", "true,0");
        } else if (index == 1) {
          ue4("SetIconShowByType", "true,1");
        }
      }
    },
    fontSize(res) {
      let docEl = document.documentElement,
        clientWidth =
          window.innerWidth ||
          document.documentElement.clientWidth ||
          document.body.clientWidth;
      if (!clientWidth) return;
      // 此处的3840 为设计稿的宽度，记得修改！
      let fontSize = clientWidth / 1920;
      return res * fontSize;
    },
  },
};
</script>

<style lang="less" scoped>
.home_box {
  width: 2.224rem;
  height: 5.1823rem;
  position: absolute;
  right: 0.125rem;
  top: 0.4427rem;
  .page {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    .page_left {
      width: 2.224rem;
      height: 100%;
      .iot_overview {
        width: 100%;
        height: 1.4271rem;
        .content {
          width: 100%;
          height: 1.1719rem;
          overflow: hidden;
          .device_ {
            width: 100%;
            height: 0.4948rem;
            background-image: url("~@/assets/imgs/device_.png");
            background-size: 100% 100%;
            background-position: 0 0;
            background-repeat: no-repeat;
            margin-top: 0.0781rem;
            display: flex;
            justify-content: space-between;
            align-items: center;
            .left_ {
              margin-left: 0.5521rem;
              p:nth-of-type(1) {
                font-size: 0.1667rem;
                font-family: "DigitalRegular";
                font-weight: 400;
                color: #fdfdfd;
                text-shadow: 0px 0.013rem 0.0156rem #00dcfe;
              }
              p:nth-of-type(2) {
                font-size: 0.099rem;
                font-family: "SourceHanSansCN-Regular";
                font-weight: 400;
                color: #e6e6e6;
              }
            }
            .right_ {
              width: 0.9375rem;
              height: 0.4948rem;
              display: flex;
              justify-content: space-around;
              align-items: center;
              p {
                display: flex;
                flex-direction: column;
                align-items: center;
                span:nth-of-type(2) {
                  font-size: 0.0729rem;
                  font-family: "SourceHanSansCN-Regular";
                  font-weight: 500;
                  color: #e6e6e6;
                  margin-top: 0.026rem;
                }
              }
              p:nth-of-type(1) {
                span:first-of-type {
                  font-size: 0.125rem;
                  font-family: "DigitalRegular";
                  font-weight: 400;
                  color: #58ff6d;
                  text-shadow: 0px 0.013rem 0.0156rem #47cc58;
                }
              }
              p:nth-of-type(2) {
                span:first-of-type {
                  font-size: 0.125rem;
                  font-family: "DigitalRegular";
                  font-weight: 400;
                  color: #cccccc;
                  text-shadow: 0px 0.013rem 0.0156rem #cccccc;
                }
              }
            }
          }
          .alarms_total {
            width: 100%;
            height: 0.5339rem;
            display: flex;
            flex-direction: column;
            justify-content: center;
            p {
              font-size: 0.0781rem;
              font-family: "SourceHanSansCN-Regular";
              font-weight: 500;
              color: #ffffff;
              margin-bottom: 0.0495rem;
              span {
                font-size: 0.125rem;
                font-family: "YouSheBiaoTiHei";
                font-weight: 400;
                color: #1ef8ff;
              }
            }
            .alarms_chart {
              width: 2.224rem;
              height: 0.375rem;
              #alarms_chart {
                width: 100%;
                height: 100%;
              }
            }
          }
        }
      }
      .intelligent_energy {
        width: 100%;
        height: 0.9583rem;
        .content {
          width: 100%;
          height: 0.7031rem;
          display: flex;
          align-items: center;
          justify-content: space-evenly;
          .item {
            display: flex;
            align-items: center;
            .img {
              width: 0.4427rem;
              height: 0.4375rem;
              margin-right: 0.0577rem;
              img {
                width: 100%;
                height: 100%;
              }
            }
            .name_value {
              display: flex;
              flex-direction: column;
              .name {
                font-size: 0.099rem;
                font-family: PingFang SC, PingFang SC;
                font-weight: 500;
                color: #ffffff;
              }
              .value {
                font-size: 0.0885rem;
                font-family: PingFang SC, PingFang SC;
                font-weight: 500;
                color: #ffffff;
                span {
                  font-size: 0.1667rem;
                  font-family: PingFang SC, PingFang SC;
                  font-weight: bold;
                  color: #ffffff;
                }
              }
            }
          }
        }
      }
      .device_type {
        width: 100%;
        height: 1.5rem;
        .content {
          width: 100%;
          height: 1.2448rem;
          position: relative;
          .shadow {
            width: 0.7188rem;
            height: 0.7188rem;
            position: absolute;
            top: 0.25rem;
            left: 0.1302rem;
            background-image: url("~@/assets/imgs/shadow.png");
            background-size: 100% 100%;
            background-position: 0 0;
            background-repeat: no-repeat;
          }
          .type_chart {
            width: 100%;
            height: 100%;
            #type_chart {
              width: 100%;
              height: 100%;
            }
          }
        }
      }
      .monitoring {
        width: 100%;
        height: 0.6198rem;
        .monitoring_title {
          width: 100%;
          height: 0.2552rem;
          background-image: url("~@/assets/imgs/title_bgc.png");
          background-size: 100% 100%;
          background-position: 0 0;
          background-repeat: no-repeat;
          p {
            font-size: 0.1667rem;
            font-family: "YouSheBiaoTiHei";
            font-weight: 500;
            color: #ffffff;
            // line-height: 0.1749rem;
            margin-left: 0.2823rem;
          }
        }
        //   监控
        .videoBox {
          margin-top: 0.1198rem;

          ul {
            display: flex;
            align-items: center;
            justify-content: space-around;

            li {
              position: relative;
              width: 1.0573rem;
              height: 0.6823rem;
              background: url("~@/assets/imgs/electrical/video_line_bcg.png")
                no-repeat center / cover;
              background-size: 100% 100%;
              overflow: hidden;
              .videoImg {
                position: absolute;
                top: 0;
                width: 1.0573rem;
                height: 0.6823rem;
                background: transparent;
                background-size: 100% 100%;
              }

              video {
                width: 1.0573rem;
                height: 0.6823rem;
              }

              video::-webkit-media-controls {
                display: none !important;
              }

              .video-js.vjs-fluid,
              .video-js.vjs-16-9,
              .video-js.vjs-4-3,
              .video-js.vjs-9-16,
              .video-js.vjs-1-1 {
                width: 1.0573rem;
                height: 0.6823rem;
              }
            }
          }
        }
      }
    }
  }

  .point_tab {
    width: 0.3776rem;
    height: 0.2854rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    top: 0;
    right: 2.9661rem;
    .item {
      width: 0.3776rem;
      height: 0.1042rem;
      background-image: url("~@/assets/imgs/point_tab_1.png");
      background-size: 100% 100%;
      background-position: 0 0;
      background-repeat: no-repeat;
      cursor: pointer;
      display: flex;
      align-items: center;
      img {
        width: 0.0547rem;
        height: 0.0547rem;
        margin-left: 0.0521rem;
        margin-right: 0.0286rem;
      }
      p {
        font-size: 0.0521rem;
        font-family: "SourceHanSansCN-Regular";
        font-weight: 500;
        color: #e6e6e6;
      }
    }
    .itemActives {
      width: 0.3776rem;
      height: 0.1042rem;
      background-image: url("~@/assets/imgs/point_tab_2.png");
      background-size: 100% 100%;
      background-position: 0 0;
      background-repeat: no-repeat;
      cursor: pointer;
      display: flex;
      align-items: center;
      img {
        width: 0.0547rem;
        height: 0.0547rem;
        margin-left: 0.0521rem;
        margin-right: 0.0286rem;
      }
      p {
        font-size: 0.0521rem;
        font-family: "SourceHanSansCN-Regular";
        font-weight: 500;
        color: #fff;
      }
    }
  }
  .floorTab {
    position: absolute;
    bottom: 0.1016rem;
    right: 2.9661rem;
  }
}
</style>
