<template>
  <div class="titlePublic">
    <p class="titleMoov">{{ titleList.title }}</p>
  </div>
</template>

<script>
export default {
  props: {
    titleList: {
      type: Object,
      default: "",
    },
  },
};
</script>

<style lang="less" scoped>
.titlePublic {
  width: 2.224rem;
  height: .2552rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-image: url("~@/assets/imgs/title_bgc.png");
  background-size: 100% 100%;
  background-position: 0 0;
  background-repeat: no-repeat;
  .titleMoov {
    font-size: .1667rem;
    font-family: "YouSheBiaoTiHei";
    font-weight: 500;
    color: #ffffff;
    // line-height: 0.1749rem;
    margin-left: .2823rem;
  }
  .EngMoov {
    font-size: 0.0453rem;
    font-family: "SourceHanSansCN-Medium";
    font-weight: 500;
    color: #b0b3b3;
  }
}
</style>
