import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import '@/assets/font/font.less'
import "./config/flexible.js"
import scroll from 'vue-seamless-scroll'
Vue.config.productionTip = false
Vue.use(scroll)
// // 将自动注册所有组件为全局组件
// import dataV from '@jiaminghi/data-view'
// import { scrollBoard } from '@jiaminghi/data-view'
// Vue.use(scrollBoard)
// Vue.use(dataV)
const hls = require('videojs-contrib-hls')
Vue.use(hls)
Vue.prototype.$bus = new Vue()
Vue.use(ElementUI)
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
