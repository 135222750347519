import http from "@/server/sever";
// 智能门禁

// 门禁通行
export function getDoors() {
  return http.get(
    'foo/system/door/getDoors?size=19',
  )
}

// 能耗管理

// 用电概览
export function deviceStatistics(labelIds) {
  return http.get(
    'api/api/app/orc/1558147365/deviceStatistics?pk=CYLCT2oipcs' + '&labelIds=' + labelIds,
  )
}

// 用水用电 TOPS
export function deviceStatisticsTOP() {
  return http.get(
    'api/api/udmp/metadata/sql-query/exec/1742749755173212162',
  )
}

// 用水概览
export function deviceStatisticsW(labelIds) {
  return http.get(
    'api/api/app/orc/1558147365/deviceStatistics?pk=CaaT3bomTw1' + '&labelIds=' + labelIds,
  )
}

// 设备概况
export function deviceCount() {
  return http.get(
    'api/api/device/dmc/deviceCount',
  )
}


// 暖通空调

// 空调概览
export function OverviewofAirConditioning(labelIds) {
  return http.get(
    'api/api/app/orc/1558147365/deviceStatistics?pk=CdI5EuuEYCl' + '&labelIds=' + labelIds,
  )
}

// 环境监测
export function exec() {
  return http.get(
    'api/api/udmp/metadata/sql-query/exec/1742740870899142658'
  )
}

// 空调列表
export function productKey(labelIds) {
  return http.get(
    'api/api/device/dmc/device/page?productKey=CaErIWhWt63&size=200&labelIds=' + labelIds
  )
}

// 智能照明
export function intelligentlighting(labelIds) {
  return http.get(
    'api/api/app/orc/1558147365/deviceStatistics?pk=CYfL9euE08v&labelIds=' + labelIds
  )
}
// 车位统计
export function execmetadata() {
  return http.get(
    'api/api/udmp/metadata/sql-query/exec/1742728714120040449'
  )
}
// 停车管理
export function deviceStatisticsexecParking(labelIds) {
  return http.get(
    'api/api/app/orc/1558147365/deviceStatistics?pk=CaYLKQVoGmr&labelIds=' + labelIds
  )
}
// 车流统计
export function Trafficflow() {
  return http.get(
    'api/api/udmp/metadata/sql-query/exec/1742733560206299137'
  )
}
// 车行 进出记录
export function inandout() {
  return http.get(
    'api/api/udmp/metadata/sql-query/exec/1742464187134611457'
  )
}
// 办学条件
export function schoolConditions() {
  return http.get(
    'api/api/udmp/metadata/sql-query/exec/1743991079583059969',
  )
}

// 师生基本情况
export function teachersAndStudents() {
  return http.get(
    'api/api/udmp/metadata/sql-query/exec/1743993738494971906',
  )
}

// 按校区师生基本情况
export function Campus() {
  return http.get(
    'api/api/udmp/metadata/sql-query/exec/1743994355540004866',
  )
}

// 按培养师生基本情况

export function culture() {
  return http.get(
    'api/api/udmp/metadata/sql-query/exec/1743994655273357313',
  )
}

//教学项目情况

export function teaching() {
  return http.get(
    'api/api/udmp/metadata/sql-query/exec/1743996366952042498',
  )
}

//校舍情况
export function schoolBuilding() {
  return http.get(
    'api/api/udmp/metadata/sql-query/exec/1743997332828950530',
  )
}

//学科建设
export function discipline() {
  return http.get(
    'api/api/udmp/metadata/sql-query/exec/1743991563551215617',
  )
}

//科研情况
export function research() {
  return http.get(
    'api/api/udmp/metadata/sql-query/exec/1744184002975076354',
  )
}

//科研数量
export function researchNum() {
  return http.get(
    'api/api/udmp/metadata/sql-query/exec/1744184680141262850',
  )
}

//科研经费
export function researchMoneyNum() {
  return http.get(
    'api/api/udmp/metadata/sql-query/exec/1744185033461043202',
  )
}

//物联概览
export function deviceState() {
  return http.get(
    'api/api/app/orc/1558147365/deviceStatistics',
  )
}

//门禁概览
export function floorControl() {
  return http.get(
    'api/api/app/orc/1558147365/deviceStatistics?pk=CZ5uzWrH0av&labelIds=528221552517316608',
  )
}

//监控概览
export function monitor() {
  return http.get(
    'api/api/app/orc/1558147365/deviceStatistics?pk=A0101-00002&labelIds=528221552517316608',
  )
}

// 空调概览
export function airConditioning() {
  return http.get(
    'api/api/app/orc/1558147365/deviceStatistics?pk=CaErIWhWt63&labelIds=528221552517316608',
  )
}

//照明概览
export function lighting() {
  return http.get(
    'api/api/app/orc/1558147365/deviceStatistics?pk=CYfL9euE08v&labelIds=528221552517316608',
  )
}

//道闸情况
export function barrierGate() {
  return http.get(
    'api/api/app/orc/1558147365/deviceStatistics?pk=CaYLKQVoGmr&labelIds=528221552517316608',
  )
}
//视频监控
export function invokeService(devId) {
  return http.get(
    'api/api/device/dmc/deviceItem/invokeService',
    {
      "devId": devId,
      "identifier": "APIPreviewURLs",
      "inputParams": {
        "toH264": "1",
        "protocol": "rtsp",
        "streamType": "0"
      },
      "async": false
    }
  )
}
//单栋空调列表
export function deviceStatisticsAir(labelIds, groupId) {
  return http.get(
    `api/api/device/dmc/device/page?productKey=CaErIWhWt63&size=200&labelIds=${labelIds}&groupId=${groupId}`,
  )
}
//单栋照明列表
export function lightListApiOne(labelIds, groupId) {
  return http.get(
    `api/api/device/dmc/device/page?productKey=CYfL9euE08v&size=200&labelIds=${labelIds}&groupId=${groupId}`,
  )
}
//首页设备类型
export function deviceType() {
  return http.get(
    `api/api/udmp/metadata/sql-query/exec/1784956778799140865`,
  )
}
//单栋设备列表
export function deviceList(labelIds, groupId) {
  return http.get(
    `api/api/device/dmc/device/page?size=200&labelIds=${labelIds}&groupId=${groupId}`,
  )
}
// 门禁页面--7日通行统计
export function SevenType() {
  return http.get(
    `api/api/udmp/metadata/sql-query/exec/1739601517213618178`,
  )
}
