<template>
  <div class="homePage">
    <homepage v-if="isShow"></homepage>
  </div>
</template>

<script>
import homepage from "@/components/homepage.vue";
export default {
  components: {
    homepage,
  },
  data(){
    return{
      isShow:true
    }
  },
  mounted(){
     ue.interface.PixSendToFront = (val) => {
      this.isShow=false
      let value=JSON.parse(val)
      this.$store.commit("changepanoramicList", value);
      this.$store.commit("changeglobalList", value);
      this.$store.commit("changeisEventShow", value);
      setTimeout(()=>{
         this.isShow=true
      },100)
    };
  }
};
</script>

<style lang="less" scoped>

</style>