import Vue from 'vue'
import Vuex from 'vuex'
import { deviceStatisticsAir, lightListApiOne, deviceList } from "@/api/common";
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    token: String,
    overviewPopup: false,
    energyPopup: false,
    monitorPopup: false,
    singleData: [
      {
        Floorname: "尚学园2号楼",
        typename: "张瑞祥",
        state: "刷卡进门",
        time: "2024/1/10/9：55",
      },
      {
        Floorname: "尚学园2号楼",
        typename: "李翔宇",
        state: "刷卡进门",
        time: "2024/1/10/9：30",
      },
      {
        Floorname: "尚学园2号楼",
        typename: "王敏",
        state: "刷卡进门",
        time: "2024/1/10/9：10",
      },
      {
        Floorname: "尚学园2号楼",
        typename: "徐志明",
        state: "刷卡进门",
        time: "2024/1/10/8：55",
      },
      {
        Floorname: "尚学园2号楼",
        typename: "张瑞祥",
        state: "刷卡进门",
        time: "2024/1/10/7：55",
      },
      {
        Floorname: "尚学园2号楼",
        typename: "李翔宇",
        state: "刷卡进门",
        time: "2024/1/10/9：20",
      },
      {
        Floorname: "尚学园2号楼",
        typename: "王敏",
        state: "刷卡进门",
        time: "2024/1/9/9：55",
      },
      {
        Floorname: "尚学园2号楼",
        typename: "徐志明",
        state: "刷卡进门",
        time: "2024/1/19/9：45",
      },
    ],
    singleDataOne: [
      {
        Floorname: "尚学园3号楼",
        typename: "监控001",
        state: 0,
      },
      {
        Floorname: "尚学园3号楼",
        typename: "监控001",
        state: 1,
      },
      {
        Floorname: "尚学园3号楼",
        typename: "监控001",
        state: 0,
      },
      {
        Floorname: "尚学园3号楼",
        typename: "监控001",
        state: 0,
      },
      {
        Floorname: "尚学园3号楼",
        typename: "监控001",
        state: 0,
      },
    ],
    singleDataTwo: [
      {
        Floorname: "尚学园2号楼",
        typename: "暖通001",
        state: "0",
      },
      {
        Floorname: "尚学园2号楼",
        typename: "暖通001",
        state: "1",
      },
      {
        Floorname: "尚学园2号楼",
        typename: "暖通001",
        state: "0",
      },
      {
        Floorname: "尚学园2号楼",
        typename: "暖通001",
        state: "1",
      },
      {
        Floorname: "尚学园2号楼",
        typename: "暖通001",
        state: "0",
      },
      {
        Floorname: "尚学园2号楼",
        typename: "暖通001",
        state: "1",
      },
      {
        Floorname: "尚学园2号楼",
        typename: "暖通001",
        state: "1",
      },
      {
        Floorname: "尚学园2号楼",
        typename: "暖通001",
        state: "0",
      },
      {
        Floorname: "尚学园2号楼",
        typename: "暖通001",
        state: "1",
      },
      {
        Floorname: "尚学园2号楼",
        typename: "暖通001",
        state: "1",
      },
      {
        Floorname: "尚学园2号楼",
        typename: "暖通001",
        state: "0",
      },
      {
        Floorname: "尚学园2号楼",
        typename: "暖通001",
        state: "1",
      },
    ],
    singleDataThree: [
      {
        Floorname: "尚学园2号楼",
        typename: "照明回路001",
        state: "0",
      },
      {
        Floorname: "尚学园2号楼",
        typename: "照明回路001",
        state: "1",
      },
      {
        Floorname: "尚学园2号楼",
        typename: "照明回路001",
        state: "0",
      },
      {
        Floorname: "尚学园2号楼",
        typename: "照明回路001",
        state: "1",
      },
      {
        Floorname: "尚学园2号楼",
        typename: "照明回路001",
        state: "0",
      },
      {
        Floorname: "尚学园2号楼",
        typename: "照明回路001",
        state: "1",
      },
      {
        Floorname: "尚学园2号楼",
        typename: "照明回路001",
        state: "1",
      },
      {
        Floorname: "尚学园2号楼",
        typename: "照明回路001",
        state: "0",
      },
      {
        Floorname: "尚学园2号楼",
        typename: "照明回路001",
        state: "1",
      },
      {
        Floorname: "尚学园2号楼",
        typename: "照明回路001",
        state: "1",
      },
      {
        Floorname: "尚学园2号楼",
        typename: "照明回路001",
        state: "0",
      },
      {
        Floorname: "尚学园2号楼",
        typename: "照明回路001",
        state: "1",
      },
    ],
    isShow: true,
    isShowOne: true,
    isShowTwo: true,
    isShowThree: true,
    isFloor: true,
    // 校区ID
    labelIds: '',
    AirListData: [

    ],
    HomeListData: [
      
    ],
    LightingList: [
    ],
    monitorData: {
      name: '',
      devId: ''
    },
    panoramicList: [
      { name: "宏观态势", isShow: true },
      { name: "校园周边", isShow: true },
      { name: "校园态势", isShow: true },
      { name: "线上校史馆", isShow: true },
      { name: "线上交叉研究院", isShow: true },
    ],
    globalList: [
      { name: "智慧门禁", isShow: true },
      { name: "智能监控", isShow: true },
      { name: "能耗管理", isShow: true },
      { name: "暖通空调", isShow: true },
      { name: "智能照明", isShow: true },
      { name: "停车管理", isShow: true },
    ],
    isEventShow: true,
  },
  getters: {},
  mutations: {
    changepanoramicList(state,value){
      state.panoramicList.forEach((item) => {
        console.log(value.includes(item.name));
          item.isShow = value.includes(item.name);
      });
    },
    changeglobalList(state,value){
      state.globalList.forEach((item) => {
        console.log(value.includes(item.name));
        item.isShow = value.includes(item.name);
      });
    },
    changeisEventShow(state,value){
      state.isEventShow = value.includes("事件中心");
    },
    changeOverviewPopup(state, value) {
      state.overviewPopup = value
    },
    changeEnergyPopup(state, value) {
      state.energyPopup = value
    },
    changeMonitorPopup(state, value) {
      state.monitorPopup = value
    },
    changesingleData(state, value) {
      state.singleData = value
    },
    changesingleDataOne(state, value) {
      state.singleDataOne = value
    },
    changesingleDataTwo(state, value) {
      state.singleDataTwo = value
    },
    changesingleDataThree(state, value) {
      state.singleDataThree = value
    },
    changeIsShow(state, value) {
      state.isShow = value
    },
    changeIsShowOne(state, value) {
      state.isShowOne = value
    },
    changeIsShowTwo(state, value) {
      state.isShowTwo = value
    },
    changeIsShowThree(state, value) {
      state.isShowThree = value
    },
    changeFloor(state, value) {
      state.isFloor = value
    },
    changeAirListData(state, value) {
      deviceStatisticsAir(state.labelIds, value.IconID).then(res => {
        state.AirListData = res.data.records.map((item) => {
          return {
            Floorname: item.groupName,
            typename: item.devName,
            state: item.online,
          };
        });
      })
      // .forEach(item => {
      //     item.Floorname = value
      //   })
    },
    changeHomeListData(state, value) {
      deviceList(state.labelIds, value.IconID).then(res => {
        state.HomeListData = res.data.records.map((item) => {
          return {
            Floorname: item.groupName,
            typename: item.devName,
            state: item.online,
          };
        });
      })
    },
    changeLightingList(state, value) {
      lightListApiOne(state.labelIds, value.IconID).then(res => {
        console.log(res, '照明');
        state.LightingList = res.data.records.map((item) => {
          return {
            Floorname: item.groupName,
            typename: item.devName,
            state: item.online,
          };
        });
      })
      // state.LightingList.forEach(item => {
      //   item.Floorname = value
      // })
    },
    changemonitorData(state, value) {
      state.monitorData = value
    },
    // 校区  ID
    labelIds(state, labelIds) {
      state.labelIds = labelIds
    }
  },
  actions: {
    token(state, token) {
      state.token = token
    }
  },
  modules: {}
})
