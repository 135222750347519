<template>
  <div class="header">
    <div class="header_box" @click="changeDrawer"></div>
    <div class="left_shadow_box" @mousemove="drawer = true">
      <el-drawer
        title="我是标题"
        :visible.sync="drawer"
        :direction="direction"
        :with-header="false"
        :modal="false"
        :wrapperClosable="false"
      >
        <div class="left_shadow">
          <div class="panoramic_overview">
            <div class="panoramic_">
              <img src="@/assets/imgs/panoramic_overview.png" alt="" />
              <p>全景概览</p>
            </div>
            <div class="panoramic_menu">
              <div v-for="(item, index) in $store.state.panoramicList" :key="index">
                <div
                  :class="isPanoramic == index ? 'item active' : 'item'"
                  @click="changePanoramicMenu(index)"
                  v-show="item.isShow"
                >
                  {{ item.name }}
                </div>
              </div>
            </div>
          </div>
          <div class="global_iot">
            <div class="global_">
              <img src="@/assets/imgs/panoramic_overview.png" alt="" />
              <p>全域物联</p>
            </div>
            <div class="global_menu">
              <div v-for="(item, index) in $store.state.globalList" :key="index">
                <div
                  :class="isGlobal == index ? 'item active' : 'item'"
                  @click="changeGlobal(index)"
                  v-show="item.isShow"
                >
                  {{ item.name }}
                </div>
              </div>
            </div>
          </div>
          <div class="event_center">
            <div class="event_" @click="changeEvent" v-if="$store.state.isEventShow">
              <img src="@/assets/imgs/panoramic_overview.png" alt="" />
              <p>事件中心</p>
            </div>
          </div>
          <div
            class="floor"
            v-if="isPanoramic == 4 && this.schoolName == '拱宸桥校区'"
          >
            <div
              :class="isFloor == index ? 'item active' : 'item'"
              v-for="(item, index) in floorLsit"
              :key="index"
              @click="changeFloor(index)"
            >
              {{ item }}
            </div>
          </div>
        </div>
      </el-drawer>
    </div>
    <div class="right_shadow_box">
      <el-drawer
        title="我是标题"
        :visible.sync="drawer_right"
        :direction="direction1"
        :with-header="false"
        :modal="false"
        :wrapperClosable="false"
      >
        <div class="right_shadow">
          <schoolSituation v-if="isPanoramic == 0"></schoolSituation>
          <macroSituation
            v-if="isPanoramic == 1 && schoolName == '拱宸桥校区'"
          ></macroSituation>
          <macroSituationY
            v-if="isPanoramic == 1 && schoolName == '杨汛桥校区'"
          ></macroSituationY>
          <IoTOverview v-if="isPanoramic == 2"></IoTOverview>
          <ControlOverview
            v-if="isGlobal == 0 && !isIconClick"
          ></ControlOverview>
          <MonitoringOverview v-if="isGlobal == 1"></MonitoringOverview>
          <EnergyConsumption v-if="isGlobal == 2"></EnergyConsumption>
          <AirOverview v-if="isGlobal == 3 && !isIconClick"></AirOverview>
          <LightingOverview
            v-if="isGlobal == 4 && !isIconClick"
          ></LightingOverview>
          <ParkingManagement v-if="isGlobal == 5"></ParkingManagement>
          <EventCenter v-if="isEvent"></EventCenter>
          <AirList v-if="isGlobal == 3 && isIconClick"></AirList>
          <TrafficRecords v-if="isGlobal == 0 && isIconClick"></TrafficRecords>
          <LightingList v-if="isGlobal == 4 && isIconClick"></LightingList>
          <HomeList
            v-if="
              isIconClick && isGlobal == -1 && !isEvent && isPanoramic == -1
            "
          ></HomeList>
        </div>
      </el-drawer>
    </div>

    <!-- 天气 -->
    <div class="topright">
      <div class="backstage">
        <img src="@/assets/imgs/fine.png" alt="" />
      </div>
      <div class="weather">
        <div class="weathers">晴</div>
        <div class="tem">18­°C</div>
      </div>
    </div>
    <!-- 日期 -->
    <div class="date_box">
      <div class="time">{{ currTime }}</div>
      <img src="@/assets/imgs/line.png" alt="" />
      <div class="date">
        <div class="currDate">{{ currDate }}</div>
      </div>
    </div>
    <!-- 城市 -->
    <div class="city">
      <img src="@/assets/imgs/city.png" alt="" />
      <span>杭州市</span>
    </div>
    <!-- 模拟天气 -->
    <div class="weather_simulation" @click="weatherOpen">
      <img class="arrow" src="@/assets/imgs/arrow.png" alt="" />
    </div>
    <!-- 模拟天气下拉 -->
    <div class="weather_list" v-if="isWeather">
      <div
        :class="weatherActive == index ? 'item activeItem' : 'item'"
        v-for="(item, index) in weather_list"
        :key="index"
        @click="changeWeather(index)"
      >
        <img :src="item.url" alt="" />
      </div>
    </div>
    <!-- 动态光照 -->
    <div class="dynamic_lighting" @click="lightingBars"></div>
    <!-- 动态光照拉杆 -->
    <div class="lighting_bar" v-if="lightingBar">
      <div class="slider">
        <el-slider
          v-model="value"
          vertical
          :max="2400"
          :step="100"
          @change="changeTime"
        ></el-slider>
      </div>
    </div>
    <!-- 注销按钮 -->
    <div class="log_off" @click="off"></div>
    <!-- 校区选择 -->
    <div class="selection_selection" @click="selectionSchool">
      <p>{{ schoolName }}</p>
      <img src="@/assets/imgs/down_arrow.png" alt="" />
    </div>
    <!-- 校区弹窗 -->
    <div class="school_popup" v-if="schoolPopup">
      <div
        :class="activeItems == index ? 'item activeItems' : 'item'"
        v-for="(item, index) in schoolList"
        :key="index"
        @click="changeSchool(item, index)"
      >
        {{ item.name }}
      </div>
    </div>
    <overviewPopup
      v-if="$store.state.overviewPopup"
      :popupData="popupData"
    ></overviewPopup>
    <energyPopup
      v-if="$store.state.energyPopup"
      :popupData="energyPopupData"
    ></energyPopup>
    <monitorPopup v-if="$store.state.monitorPopup"></monitorPopup>
    <!-- 全域物联菜单 -->
    <!-- <div class="iot_menu" v-if="isRightSelect == 0">
      <div
        class="item"
        v-for="(item, index) in iotMenu"
        :key="index"
        @click="changeRightMenu(item, index)"
      >
        <img :src="rightMenu == index ? item.activeUrl : item.url" alt="" />
      </div>
    </div> -->
  </div>
</template>

<script>
import bus from "@/bus";
import overviewPopup from "@/components/overviewPopup.vue";
import energyPopup from "@/components/energyPopup.vue";
import monitorPopup from "@/components/monitorPopup.vue";
import macroSituation from "@/components/macroSituation.vue";
import schoolSituation from "@/components/schoolSituation.vue";
import IoTOverview from "@/components/IoTOverview.vue";
import ControlOverview from "@/components/ControlOverview.vue";
import MonitoringOverview from "@/components/MonitoringOverview.vue";
import EnergyConsumption from "@/components/EnergyConsumption.vue";
import AirOverview from "@/components/AirOverview.vue";
import LightingOverview from "@/components/LightingOverview.vue";
import ParkingManagement from "@/components/ParkingManagement.vue";
import EventCenter from "@/components/EventCenter.vue";
import AirList from "@/components/AirList.vue";
import TrafficRecords from "@/components/TrafficRecords.vue";
import LightingList from "@/components/LightingList.vue";
import macroSituationY from "@/components/macroSituationY.vue";
import HomeList from "@/components/HomeList.vue";
export default {
  name: "homepage",
  components: {
    overviewPopup,
    energyPopup,
    monitorPopup,
    macroSituation,
    schoolSituation,
    IoTOverview,
    ControlOverview,
    MonitoringOverview,
    EnergyConsumption,
    AirOverview,
    LightingOverview,
    ParkingManagement,
    EventCenter,
    AirList,
    TrafficRecords,
    LightingList,
    macroSituationY,
    HomeList,
  },
  data() {
    return {
      value: 1000,
      // 时间
      currTime: "",
      drawer: false,
      direction: "ltr",
      direction1: "rtl",
      drawer_right: false,
      // 周期
      weeks: "",
      //年月日
      currDate: "",
      weather_list: [
        {
          url: require("@/assets/imgs/fine_day.png"),
        },
        {
          url: require("@/assets/imgs/cloudy_day.png"),
        },
        {
          url: require("@/assets/imgs/rainy_day.png"),
        },
        {
          url: require("@/assets/imgs/snowy_day.png"),
        },
      ],
      weatherActive: -1,
      isWeather: false,
      lightingBar: false,
      schoolList: [
        {
          name: "拱宸桥校区",
          labelIds: "528221552517316608",
        },
        {
          name: "杨汛桥校区",
          labelIds: "528221582745665536",
        },
      ],
      activeItems: -1,
      schoolPopup: false,
      isPanoramic: -1,
      isRightSelect: -1,
      schoolName: "拱宸桥校区",
      hour: 0,
      popupData: {
        title: "门禁概况",
        data: [
          {
            name: "门禁总数：",
            value: "165",
          },
          {
            name: "在线数：",
            value: "160",
          },
          {
            name: "离线数：",
            value: "5",
          },
          {
            name: "告警数：",
            value: "0",
          },
          {
            name: "通行总数：",
            value: "1065",
          },
        ],
      },
      energyPopupData: {
        title: "能耗概况",
        eleData: [
          {
            name: "电表总数：",
            value: "100",
          },
          {
            name: "在线数：",
            value: "95",
          },
          {
            name: "离线数：",
            value: "5",
          },
          {
            name: "告警数：",
            value: "0",
          },
        ],
        waterData: [
          {
            name: "水表总数：",
            value: "100",
          },
          {
            name: "在线数：",
            value: "95",
          },
          {
            name: "离线数：",
            value: "5",
          },
          {
            name: "告警数：",
            value: "0",
          },
        ],
      },
    
      
      isGlobal: -1,
      floorLsit: ["6F", "5F", "4F", "3F", "2F", "1F"],
      isFloor: -1,
      isEvent: false,
      isIconClick: false,
      
    };
  },
  methods: {
    changeEvent() {
      // console.log("1111111111111111");
      this.drawer_right = true;
      this.isEvent = !this.isEvent;
      this.isPanoramic = -1;
      this.isGlobal = -1;
      ue4("SetBuildShow", true);
      this.isIconClick = false;
    },
    changeFloor(index) {
      this.isFloor = index;
      ue4("SetBuildShow", false);
      ue4("MoveToTarget", "9号楼");
      if (index == 0) {
        ue4("SetFloor", 6);
      } else if (index == 1) {
        ue4("SetFloor", 5);
      } else if (index == 2) {
        ue4("SetFloor", 4);
      } else if (index == 3) {
        ue4("SetFloor", 3);
      } else if (index == 4) {
        ue4("SetFloor", 2);
      } else if (index == 5) {
        ue4("SetFloor", 1);
      }
    },
    changeDrawer() {
      this.drawer = false;
      this.isFloor = -1;
      this.drawer_right = false;
      this.isIconClick = false;
      ue4("ResetLeftArea");
    },
    changeGlobal(index) {
      if (this.isGlobal == index) {
        this.isGlobal = -1;
      } else {
        this.isGlobal = index;
        this.isPanoramic = -1;
        this.isFloor = -1;
        this.drawer_right = true;
        this.isEvent = false;
        this.isIconClick = false;
        this.$store.commit("changeMonitorPopup", false);
        ue4("SetBuildShow", true);
        ue4("SetIconShow", false);
        ue4("SetTime", this.value);
        if (index == 0) {
          ue4("MoveToTarget", "安防态势");
          ue4("SetIconShowByType", "true,1");
        } else if (index == 1) {
          ue4("MoveToTarget", "能耗监控");
          ue4("SetIconShowByType", "true,0");
        } else if (index == 2) {
          ue4("MoveToTarget", "购电设备");
          ue4("SetIconShowByType", "true,1");
        } else if (index == 3) {
          ue4("MoveToTarget", "暖通空调");
          ue4("SetIconShowByType", "true,1");
        } else if (index == 4) {
          ue4("MoveToTarget", "智能照明");
          ue4("SetIconShowByType", "true,1");
        } else if (index == 5) {
          ue4("MoveToTarget", "停车管理");
        }
      }
    },
    changePanoramicMenu(index) {
      if (this.isPanoramic == index) {
        this.isPanoramic = -1;
      } else {
        this.isPanoramic = index;
        this.isGlobal = -1;
        this.drawer_right = true;
        this.isEvent = false;
        this.isIconClick = false;
        this.$store.commit("changeMonitorPopup", false);
        ue4("SetTime", this.value);
        ue4("SetBuildShow", true);
        ue4("SetIconShow", false);
        if (index != 2) {
          this.isFloor = -1;
        }
        if (index == 0) {
          ue4("MoveToTarget", "宏观态势1");
        } else if (index == 1) {
          ue4("MoveToTarget", "区域态势");
          ue4("SetTime", 2400);
          ue4("SetIconShowByType", "true,3");
          ue4("SetIconShowByType", "true,4");
          ue4("SetIconShowByType", "true,5");
        } else if (index == 2) {
          ue4("MoveToTarget", "园区态势");
        }
        // if (index == 3) {
        //   this.drawer_right = false;
        // } else if (index == 4) {
        //   this.drawer_right = false;
        // }
      }
    },
    // 时间
    getime() {
      var myDate = new Date();
      var year = myDate.getFullYear();
      var yue = myDate.getMonth() + 1;
      var ri = myDate.getDate();
      var shi = myDate.getHours();
      this.hour = myDate.getHours();
      var fen = myDate.getMinutes();
      var miao = myDate.getSeconds();
      var wk = myDate.getDay();
      miao = miao < 10 ? "0" + miao : miao;
      fen = fen < 10 ? "0" + fen : fen;
      shi = shi < 10 ? "0" + shi : shi;
      this.currDate = year + "-" + yue + "-" + ri;
      this.currTime = shi + ":" + fen + ":" + miao;
      //当前星期
      let weeks = [
        "星期日",
        "星期一",
        "星期二",
        "星期三",
        "星期四",
        "星期五",
        "星期六",
      ];
      this.weeks = weeks[wk];
      // console.log(weeks[wk], 'weeks')
    },
    changeTime(e) {
      console.log(e);
      ue4("SetTime", e);
    },
    weatherOpen() {
      this.isWeather = !this.isWeather;
    },
    changeWeather(index) {
      if (this.weatherActive == index) {
        this.weatherActive = -1;
      } else {
        this.weatherActive = index;
        if (index == 0) {
          ue4("WeatherSwitch", "fine");
        } else if (index == 1) {
          ue4("WeatherSwitch", "overcast");
        } else if (index == 2) {
          ue4("WeatherSwitch", "moderaterain");
        } else if (index == 3) {
          ue4("WeatherSwitch", "moderatesnow");
        }
      }
    },
    lightingBars() {
      this.lightingBar = !this.lightingBar;
      this.value = Number(this.hour + "00");
      if (this.lightingBar) {
        ue4("AudoTime", false);
      } else {
        ue4("AudoTime", true);
      }
    },
    changeSchool(item, index) {
      if (this.activeItems == index) {
        this.activeItems = -1;
      } else {
        this.activeItems = index;
        if (index == 0) {
          ue4("SwitchLevel", "ShuRenDaXue_GongShu");
          this.$store.commit("changeFloor", true);
        } else if (index == 1) {
          ue4("SwitchLevel", "ShurenDaXue_YangXunQiao");
          this.$store.commit("changeFloor", false);
        }
      }
      bus.$emit("labelIds", item.labelIds);
    },
    selectionSchool() {
      this.schoolPopup = !this.schoolPopup;
    },

    off() {
      ue4("EEC", "exit");
    },
  },
  mounted() {
    setInterval(() => {
      this.getime();
    }, 1000);
    ue4("GetCurrentLevel", "");
    // this.$router.push("/ParkSituation");
    ue4("MoveToTarget", "园区态势");
    ue4("SetTime", this.value);
    ue.interface.CallbackCurrentLevelName = (val) => {
      // alert(val)
      ue4("SetIconShowByType", "true,1");
      ue4("EEC", "DisableAllScreenMessages");
      ue4("ResetLeftArea");
      if (val == "ShuRenDaXue_GongShu") {
        this.schoolName = "拱宸桥校区";
        this.$store.commit("changeFloor", true);
        let labelIds = "528221552517316608";
        this.$store.commit("labelIds", labelIds);
      } else if (val == "ShurenDaXue_YangXunQiao") {
        this.schoolName = "杨汛桥校区";
        this.$store.commit("changeFloor", false);
        let labelIds = "528221582745665536";
        this.$store.commit("labelIds", labelIds);
      }
    };
    ue.interface.SuccessCallback = (val) => {
      console.log(val);
    };
    // ue.interface.OnNewConnection = (val) => {
    //   this.$forceUpdate();
    // };
    // ue.interface.PixSendToFront = (val) => {
    //   this.drawer = false;
    //   this.drawer_right = false;
    //   let value = JSON.parse(val);
    //   console.log(val);
    //   console.log(this.panoramicList, this.globalList);
    //   this.$nextTick(() => {
    //     this.panoramicList.forEach((item) => {
    //       console.log(val.includes(item.name));
    //       if (!val.includes(item.name)) {
    //         item.isShow = false;
    //       }
    //     });
    //     this.globalList.forEach((item) => {
    //       console.log(val.includes(item.name));
    //       if (!val.includes(item.name)) {
    //         item.isShow = false;
    //       }
    //     });
    //     this.isEventShow = val.includes("事件中心");
    //   });

    //   console.log(this.panoramicList, this.globalList);
    // };
    ue.interface.OnMouseHoverLeftArea = (val) => {
      console.log(val);
      this.drawer = true;
    };
    ue.interface.ErrorCallback = (val) => {
      console.log(val);
    };
    ue.interface.IconClicked = (val) => {
      let value = JSON.parse(val);
      console.log(value); //val: ue场景返回的数据
      // this.isIconClick = false;
      this.drawer_right = true;
      this.$store.commit("changeAirListData", value);
      this.$store.commit("changeLightingList", value);
      this.$store.commit("changeHomeListData", value);
      if (value.IconType == 0) {
        console.log(value, "================");
        let data = {
          name: value.IconName,
          devId: value.IconID,
        };
        this.$store.commit("changemonitorData", data);
        this.$store.commit("changeMonitorPopup", true);
      }
      setTimeout(() => {
        this.isIconClick = true;
      }, 10);
    };
  },
  watch: {},
};
</script>

<style scoped lang="less">
.header {
  position: relative;
  width: 10rem;
  height: 0.4063rem;
  color: #feffff;
  font-size: 0.1923rem;
  font-family: YouSheBiaoTiHei;
  //background: url("~@/assets/imgs/headerbcg.png") no-repeat center / cover;
  .header_box {
    width: 10rem;
    height: 0.4427rem;
    background-image: url("~@/assets/imgs/header_bgc.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: 0 0;
    position: absolute;
    top: 0;
    left: 0;
    cursor: pointer;
    z-index: 99;
  }

  .left_shadow_box {
    width: 3.4688rem;
    height: 5.625rem;
    position: absolute;
    left: 0;
    top: 0;

    /deep/ .el-drawer {
      width: 100% !important;
      background-color: transparent;
      box-shadow: none;
    }
    /deep/ .el-drawer__wrapper {
      width: 3.4688rem;
      height: 5.625rem;
    }
    // .drawer-enter-active,
    // .drawer-leave-active {
    //   transition: all 2s ease;
    // }
    // .drawer-enter,
    // .drawer-leave-to {
    //   transform: translateX(100%);
    // }
    .left_shadow {
      width: 3.4688rem;
      height: 5.625rem;
      position: absolute;
      left: 0;
      top: 0;
      // z-index: -1;
      background-image: url("~@/assets/imgs/zuo.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
      background-position: 0 0;
      .panoramic_overview {
        width: 1.6354rem;
        // height: 2.1823rem;
        // position: absolute;
        // top: 0.4323rem;
        // left: 0.2604rem;
        margin-left: 0.2604rem;
        margin-top: 0.4323rem;
        .panoramic_ {
          display: flex;
          align-items: center;
          img {
            width: 0.2344rem;
            height: 0.2448rem;
            margin-right: 0.1146rem;
          }
          p {
            font-size: 0.2083rem;
            text-shadow: 0px 0.0052rem 0.0313rem #ffd48f;
          }
        }
        .panoramic_menu {
          width: 1rem;
          // height: 1.7344rem;
          display: flex;
          flex-direction: column;
          align-items: center;
          margin-left: 0.2375rem;
          margin-top: 0.0677rem;
          .item {
            width: 1rem;
            height: 0.2513rem;
            background-image: url("~@/assets/imgs/panoramic_menu.png");
            background-size: 100% 100%;
            background-repeat: no-repeat;
            background-position: 0 0;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 0.125rem;
            margin-bottom: 0.1181rem;
            cursor: pointer;
          }
          // .item:last-of-type {
          //   margin-bottom: 0;
          // }
          .active {
            background-image: url("~@/assets/imgs/panoramic_menu_.png");
            background-size: 100% 100%;
            background-repeat: no-repeat;
            background-position: 0 0;
          }
        }
      }
      .global_iot {
        width: 1.6354rem;
        // height: 2.5365rem;
        // position: absolute;
        // bottom: 0.4085rem;
        // left: 0.2604rem;
        margin-left: 0.2604rem;
        .global_ {
          display: flex;
          align-items: center;
          img {
            width: 0.2344rem;
            height: 0.2448rem;
            margin-right: 0.1146rem;
          }
          p {
            font-size: 0.2083rem;
            text-shadow: 0px 0.0052rem 0.0313rem #ffd48f;
          }
        }
        .global_menu {
          width: 1rem;
          // height: 2.0344rem;
          display: flex;
          flex-direction: column;
          align-items: center;
          margin-left: 0.2375rem;
          margin-top: 0.0677rem;
          .item {
            width: 1rem;
            height: 0.2513rem;
            background-image: url("~@/assets/imgs/panoramic_menu.png");
            background-size: 100% 100%;
            background-repeat: no-repeat;
            background-position: 0 0;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 0.125rem;
            margin-bottom: 0.1181rem;
            cursor: pointer;
          }
          // .item:last-of-type {
          //   margin-bottom: 0;
          // }
          .active {
            background-image: url("~@/assets/imgs/panoramic_menu_.png");
            background-size: 100% 100%;
            background-repeat: no-repeat;
            background-position: 0 0;
          }
        }
      }
      .event_center {
        width: 1.6354rem;
        height: 0.2448rem;
        // position: absolute;
        // bottom: 0.2188rem;
        // left: 0.2604rem;
        margin-left: 0.2604rem;
        .event_ {
          display: flex;
          align-items: center;
          img {
            width: 0.2344rem;
            height: 0.2448rem;
            margin-right: 0.1146rem;
          }
          p {
            font-size: 0.2083rem;
            text-shadow: 0px 0.0052rem 0.0313rem #ffd48f;
          }
        }
      }
      .floor {
        width: 0.3385rem;
        height: 1.6927rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        position: absolute;
        top: 1.8438rem;
        left: 1.6354rem;
        background-image: url("~@/assets/imgs/floor_tab.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
        background-position: 0 0;
        .item {
          width: 100%;
          flex: 1;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          font-family: "SourceHanSansCN-Medium";
          font-size: 0.1146rem;
        }
        .active {
          background-image: url("~@/assets/imgs/select_item.png");
          background-size: 100% 100%;
          background-repeat: no-repeat;
          background-position: 0 0;
          color: #fff;
        }
      }
    }
  }
  .right_shadow_box {
    width: 3.4688rem;
    height: 5.625rem;
    position: absolute;
    right: 0;
    top: 0;
    /deep/ .el-drawer {
      width: 100% !important;
      background-color: transparent;
      box-shadow: none;
      position: absolute;
      right: 0;
      top: 0;
    }
    /deep/ .el-drawer__wrapper {
      width: 3.4688rem;
      height: 5.625rem;
      position: absolute;
      right: 0;
      top: 0;
    }
    .right_shadow {
      width: 3.4688rem;
      height: 5.625rem;
      position: absolute;
      right: 0;
      top: 0;
      background-image: url("~@/assets/imgs/you.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
      background-position: 0 0;
    }
  }

  .topright {
    position: absolute;
    top: 0.0781rem;
    z-index: 9999;
    left: 0.8854rem;
    display: flex;

    .backstage {
      width: 0.1667rem;
      height: 0.1823rem;
      position: relative;
      margin-right: 0.0625rem;

      img {
        width: 100%;
        height: 100%;
        position: absolute;
        right: 0;
        top: 0;
      }
    }

    .weather {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 0.1823rem;

      .weathers {
        font-size: 0.0573rem;
        font-family: "SourceHanSansCN-Regular";
        font-weight: 400;
        color: #ffffff;
      }

      .tem {
        font-size: 0.0573rem;
        font-family: "SourceHanSansCN-Regular";
        font-weight: 400;
        color: #ffffff;
      }
    }
  }

  .date_box {
    display: flex;
    align-items: center;
    width: 1.9198rem;
    height: 0.1354rem;
    position: absolute;
    top: 0.099rem;
    left: 1.3698rem;
    z-index: 9999;
    .time {
      font-size: 0.099rem;
      font-family: "SourceHanSansCN-Regular";
      font-weight: 400;
      color: #ffffff;
      margin-right: 0.0651rem;
    }

    img {
      height: 0.1406rem;
    }

    .date {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 0.1406rem;
      margin-left: 0.0651rem;

      .currDate {
        font-size: 0.099rem;
        font-family: "SourceHanSansCN-Regular";
        font-weight: 400;
        color: #e6e6e6;
      }
    }
  }

  .city {
    position: absolute;
    top: 0.0625rem;
    left: 0.1771rem;
    display: flex;
    align-items: center;
    z-index: 9999;
    img {
      width: 0.1615rem;
      height: 0.2031rem;
      margin-right: 0.0625rem;
    }

    span {
      font-size: 0.099rem;
      font-family: "SourceHanSansCN-Regular";
      font-weight: 400;
      color: #cce0ff;
    }
  }

  .weather_simulation {
    width: 0.6198rem;
    height: 0.1719rem;
    background-image: url("~@/assets/imgs/weather_simulation.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: 0 0;
    position: absolute;
    top: 0.0781rem;
    right: 1.1927rem;
    cursor: pointer;
    z-index: 9999;
    .arrow {
      width: 0.0573rem;
      height: 0.0573rem;
      position: absolute;
      top: 0.0521rem;
      right: 0.0781rem;
    }
  }

  .dynamic_lighting {
    width: 0.6198rem;
    height: 0.1719rem;
    position: absolute;
    top: 0.0781rem;
    right: 0.4583rem;
    background-image: url("~@/assets/imgs/dynamic_lighting.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: 0 0;
    cursor: pointer;
    z-index: 9999;
  }

  .log_off {
    width: 0.1719rem;
    height: 0.1719rem;
    position: absolute;
    top: 0.0781rem;
    right: 0.1979rem;
    background-image: url("~@/assets/imgs/log_off.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: 0 0;
    cursor: pointer;
    z-index: 9999;
  }

  .weather_list {
    width: 0.6198rem;
    height: 0.7813rem;
    position: absolute;
    top: 0.2771rem;
    right: 1.1921rem;
    background-image: url("~@/assets/imgs/weather_list.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: 0 0;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    z-index: 9999;

    .item {
      width: 0.5208rem;
      height: 0.1641rem;
      background-image: url("~@/assets/imgs/weather_lists.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
      background-position: 0 0;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      img {
        width: 0.2161rem;
        height: 0.0833rem;
      }
    }

    .activeItem {
      background-image: url("~@/assets/imgs/weather_listss.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
      background-position: 0 0;
    }
  }

  .lighting_bar {
    width: 0.4167rem;
    height: 0.9375rem;
    position: absolute;
    top: 0.2771rem;
    right: 0.5573rem;
    background-image: url("~@/assets/imgs/lighting_bar.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: 0 0;
    z-index: 9999;

    .slider {
      width: 0.1198rem;
      height: 0.8594rem;
      position: absolute;
      top: 0.0391rem;
      left: 0.0703rem;

      /deep/ .el-slider {
        width: 100%;
        height: 100%;
      }

      /deep/ .el-slider.is-vertical .el-slider__runway {
        width: 100%;
        height: 100%;
        margin: 0;
        background: none;
      }

      /deep/ .el-slider__button {
        width: 0.2083rem;
        height: 0.2083rem;
        background-image: url("~@/assets/imgs/slider_icon.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
        background-position: 0 0;
        border: none;
        background-color: transparent;
      }

      /deep/ .el-slider.is-vertical .el-slider__button-wrapper {
        left: -0.0391rem;
        width: 0.2083rem;
        height: 0.2083rem;
        display: flex;
        justify-content: center;
        align-items: center;
        // top: -0.0391rem;
      }

      /deep/ .el-slider.is-vertical .el-slider__bar {
        left: 0.0443rem;
        background: linear-gradient(
          to top,
          rgba(242, 219, 167, 0.1),
          rgba(222, 201, 153, 0.47),
          rgba(179, 162, 123, 0.6),
          rgba(179, 162, 123, 0.47),
          rgba(179, 162, 123, 0.1)
        );
      }
    }
  }

  .selection_selection {
    width: 0.6198rem;
    height: 0.1719rem;
    background-image: url("~@/assets/imgs/selection_selection.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: 0 0;
    position: absolute;
    top: 0.0781rem;
    right: 1.9271rem;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    cursor: pointer;
    z-index: 9999;
    border-radius: 0.1719rem;

    p {
      font-size: 0.0729rem;
      font-family: "SourceHanSansCN-Regular";
      font-weight: 400;
      color: #ffffff;
    }

    img {
      width: 0.0651rem;
      height: 0.0443rem;
    }
  }

  .school_popup {
    width: 0.6146rem;
    height: 0.3359rem;
    background-image: url("~@/assets/imgs/school_popup.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: 0 0;
    position: absolute;
    top: 0.3052rem;
    right: 1.9378rem;
    z-index: 99;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 9999;

    .item {
      width: 0.6146rem;
      height: 0.1667rem;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 0.0651rem;
      font-family: "SourceHanSansCN-Bold";
      font-weight: bold;
      color: #ffffff;
      cursor: pointer;
    }

    .activeItems {
      background-image: url("~@/assets/imgs/activeItems_bgc.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
      background-position: 0 0;
    }
  }

  // .panoramic_overview {
  //   width: 0.5833rem;
  //   height: 0.1172rem;
  //   position: absolute;
  //   top: 0.0521rem;
  //   left: 2.4349rem;
  //   display: flex;
  //   justify-content: space-around;
  //   align-items: center;
  //   cursor: pointer;

  //   p {
  //     font-size: 0.1016rem;
  //     font-family: "YouSheBiaoTiHei";
  //     font-weight: 400;
  //     color: #95aacc;
  //   }

  //   .active {
  //     color: #ffffff;
  //     text-shadow: 0px 0.0026rem 0.0078rem #ffd48f;
  //   }

  //   img {
  //     width: 0.112rem;
  //     height: 0.1172rem;
  //   }
  // }

  // .iot_event {
  //   display: flex;
  //   justify-content: space-between;
  //   align-items: center;
  //   width: 1.3516rem;
  //   height: 0.1172rem;
  //   position: absolute;
  //   top: 0.0521rem;
  //   right: 1.7031rem;

  //   .item {
  //     width: 0.5833rem;
  //     height: 0.1172rem;
  //     display: flex;
  //     justify-content: space-around;
  //     align-items: center;
  //     cursor: pointer;

  //     p {
  //       font-size: 0.1016rem;
  //       font-family: "YouSheBiaoTiHei";
  //       font-weight: 400;
  //       color: #95aacc;
  //     }

  //     .active {
  //       color: #ffffff;
  //       text-shadow: 0px 0.0026rem 0.0078rem #ffd48f;
  //     }

  //     img {
  //       width: 0.112rem;
  //       height: 0.1172rem;
  //     }
  //   }
  // }

  // .panoramic_menu {
  //   width: 1.5938rem;
  //   height: 0.1354rem;
  //   display: flex;
  //   justify-content: space-between;
  //   align-items: center;
  //   position: absolute;
  //   top: 0.2825rem;
  //   left: 1.9219rem;

  //   .item {
  //     width: 0.5104rem;
  //     height: 0.1354rem;
  //     cursor: pointer;

  //     img {
  //       width: 100%;
  //       height: 100%;
  //     }
  //   }
  // }

  // .iot_menu {
  //   width: 3.1224rem;
  //   height: 0.1354rem;
  //   position: absolute;
  //   top: 0.25rem;
  //   right: 1.2839rem;
  //   display: flex;
  //   justify-content: space-between;
  //   align-items: center;

  //   .item {
  //     width: 0.5104rem;
  //     height: 0.1354rem;
  //     cursor: pointer;

  //     img {
  //       width: 100%;
  //       height: 100%;
  //     }
  //   }
  // }
}
</style>
