<template>
  <div class="home_box">
    <Title :titleList="titleList"></Title>
    <!--     用电概览 -->
    <div class="OverviewofelectricityBox">
      <div class="TotalnumberBox">
        <div class="metersBox">
          <p class="num">{{ EleconsList.deviceNum }}</p>
          <p class="totles">电表总数</p>
        </div>
      </div>
      <!--      在线-->
      <div class="onlineBox">
        <div class="metersBox">
          <p class="num">{{ EleconsList.onlineNum }}</p>
          <p class="totles">在线数</p>
        </div>
        <div class="metersBox">
          <p class="grey">{{ EleconsList.offlineNum }}</p>
          <p class="totles">离线数</p>
        </div>
      </div>
    </div>
    <!--    告警总数-->
    <div class="devicealarmsBox">
      <p class="title">设备告警总数：</p>
      <p class="num">{{ ararmNumToals }}</p>
    </div>
    <!--     图表-->
    <div class="Stack_Graph_box">
      <div class="Stack_Graph" ref="countrys_sagesc"></div>
    </div>
    <!--     图表 信息-->
    <div class="EchartsnewsBox">
      <ul>
        <li v-for="(item, index) in EchartsnewsList" :key="index">
          <div class="title">
            <p :class="item.color"></p>
            <p class="name">{{ item.name }}</p>
          </div>
          <p class="num">{{ item.num }}</p>
        </li>
      </ul>
    </div>
    <div class="echartsBox">
      <div class="echarts_bcg">
        <div class="colorBcg">在线比例</div>
        <div class="careAgedEcharts" ref="careAgedsEcharts"></div>
      </div>
      <!--     图表 数据 -->
      <div class="PieBox">
        <ul>
          <li v-for="(item, index) in EchartpieList" :key="index">
            <div class="title">
              <p :class="item.color"></p>
              <p class="name">{{ item.name }}</p>
            </div>
            <p class="num">{{ item.num }}%</p>
          </li>
        </ul>
      </div>
    </div>
    <!--    小标题-->
    <smallTitle :titleList="smalltitle"></smallTitle>
    <div class="PowerconsumptionBox">
      <ul>
        <li>
          <div class="powerBox">
            <p class="title">当日用电量(KWh)</p>
            <p class="num">3296.23</p>
          </div>
          <img src="@/assets/imgs/Energy/coulometricanalysis_bcg.png" alt="" />
          <div class="powerBox">
            <p class="title">本周用电量(KWh)</p>
            <p class="num">21330.88</p>
          </div>
        </li>
      </ul>
    </div>
    <div class="Rankingecharts" ref="Rankingescharts"></div>
    <!--     折线图-->
    <div class="obtainEcharts" ref="LifeobtainsEcharts"></div>
  </div>
</template>

<script>
import * as echarts from "echarts";
import Title from "@/components/Title";
import smallTitle from "@/components/smallTitle";
import bus from "@/bus";
import { deviceStatistics, deviceStatisticsTOP } from "@/api/common";

export default {
  components: {
    Title,
    smallTitle,
  },
  data() {
    return {
      // 标题
      titleList: {
        title: "用电概览",
      },
      // 小标题
      smalltitle: {
        title: "用电分析",
      },
      EchartsnewsList: [],
      //   饼图 数据
      EchartpieList: [],
      // 拱宸桥Id
      labelIds: "528221552517316608",
      // 概览数据
      EleconsList: {},
      // 设备告警总数
      ararmNumToals: 0,
    };
  },
  mounted() {
    this.CountrySages();
    this.Rankingecharts();
    this.LifeobtainEcharts();
    let labelIds = this.$store.state.labelIds;
    this.deviceStatisticsHandle(labelIds);
    this.deviceStatisticsTOPHandle();
    // bus.$on('labelIds', (val) => {
    //   console.log(val, 'val')
    //   this.deviceStatisticsHandle(val)
    // })
  },
  methods: {
    // 用电概览
    async deviceStatisticsHandle(labelIds) {
      const res = await deviceStatistics(labelIds);
      this.EleconsList = res;
      console.log(res, "res 用电");
      this.EchartsnewsList = [
        {
          color: "skyblue",
          name: "信息",
          num: res.infoNum,
        },
        {
          color: "orange",
          name: "告警",
          num: res.alarmNum,
        },
        {
          color: "cameobrown",
          name: "故障",
          num: res.faultNum,
        },
      ];
      this.ararmNumToals =
        Number(res.infoNum) + Number(res.alarmNum) + Number(res.faultNum);
      let onlineNum = (Number(res.onlineNum) / res.deviceNum) * 100;
      let offlineNum = (Number(res.offlineNum) / res.deviceNum) * 100;
      console.log(onlineNum);
      //   饼图 数据
      this.EchartpieList = [
        {
          color: "blue",
          name: "在线率",
          num: onlineNum,
        },
        {
          color: "skyblue",
          name: "离线率",
          num: offlineNum,
        },
      ];
      this.careAgedEcharts(res.onlineNum, res.offlineNum);
    },
    // 柱状数据
    async deviceStatisticsTOPHandle() {
      const res = await deviceStatisticsTOP();
      // this.EleconsList = res
      console.log(res, "res 柱状数据");
    },
    //  图表
    CountrySages() {
      // 基于准备好的dom，初始化echarts实例
      const myChart = echarts.init(this.$refs.countrys_sagesc);
      myChart.setOption({
        // tooltip: {
        //   trigger: 'axis',
        //   axisPointer: {
        //     // Use axis to trigger tooltip
        //     type: 'shadow' // 'shadow' as default; can also be 'line' or 'shadow'
        //   }
        // },
        legend: {
          show: false,
        },
        grid: {
          left: "0.5%",
          right: "0.5%",
          containLabel: true,
        },
        xAxis: {
          type: "value",
          splitLine: {
            show: false,
          },
          axisLabel: {
            show: false,
          },
        },
        yAxis: {
          type: "category",
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            show: false,
          },
          data: ["数量"],
        },
        series: [
          {
            name: "会员",
            type: "bar",
            stack: "total",
            emphasis: {
              // focus: 'series'
            },
            label: {
              show: false,
            },
            itemStyle: {
              color: "#1EF8FF",
              // borderColor: '#fff',
              borderWidth: 1,
            },
            barWidth: 27,
            data: [this.EleconsList.infoNum],
          },
          {
            name: "",
            type: "bar",
            stack: "total",
            emphasis: {
              // focus: 'series'
            },
            label: {
              show: false,
            },
            itemStyle: {
              color: "rgba(255, 255, 255, .1)",
              // borderColor: 'rgba(255, 255, 255, .1)',
              borderWidth: 10,
            },
            barWidth: 27,
            data: [10],
          },
          {
            name: "",
            type: "bar",
            stack: "total",
            emphasis: {
              // focus: 'series'
            },
            label: {
              show: false,
            },
            itemStyle: {
              color: "#FBB351",
              // borderColor: 'rgba(255, 255, 255, .1)',
              borderWidth: 10,
            },
            barWidth: 27,
            data: [this.EleconsList.alarmNum],
          },
          {
            name: "",
            type: "bar",
            stack: "total",
            emphasis: {
              // focus: 'series'
            },
            label: {
              show: false,
            },
            itemStyle: {
              color: "rgba(255, 255, 255, .1)",
              // borderColor: 'rgba(255, 255, 255, .1)',
              borderWidth: 10,
            },
            barWidth: 27,
            data: [10],
          },
          {
            name: "理事",
            type: "bar",
            stack: "total",
            label: {
              show: false,
            },
            itemStyle: {
              color: "#FF6E6F",
              // borderColor: '#fff',
              borderWidth: 2,
            },
            barWidth: 27,
            emphasis: {
              // focus: 'series'
            },
            data: [this.EleconsList.faultNum],
          },
        ],
      });
    },
    //  饼图
    careAgedEcharts(onlineNum, offlineNum) {
      // 基于准备好的dom，初始化echarts实例
      const myChart = echarts.init(this.$refs.careAgedsEcharts);
      // 绘制图表
      myChart.setOption({
        color: ["#199BFF", "#4CE9E9"], //饼状图颜⾊数组
        tooltip: {
          trigger: "item",
          //提示框样式调整
          textStyle: {
            color: "#e6e6e6",
            fontFamily: "SourceHanSansCN-Regular",
            fontSize: 14,
          },
          extraCssText:
            "border: 1px solid #e6e6e6;color: #ccc;font-family:'SourceHanSansCN-Regular';background:rgba(39, 69, 86,.8);font-size:14px;",
        },
        series: [
          {
            type: "pie",
            radius: ["70%", "90%"],
            avoidLabelOverlap: false,

            label: {
              show: false,
              position: "center",
            },
            emphasis: {
              label: {
                show: false,
                fontSize: "14",
                fontWeight: "bold",
              },
            },
            labelLine: {
              show: false,
            },
            data: [onlineNum, offlineNum],
          },
        ],
      });
    },
    // 柱状图
    Rankingecharts() {
      const myChart = echarts.init(this.$refs.Rankingescharts);
      // 绘制图表
      myChart.setOption({
        title: {
          text: "",
        },
        tooltip: {
          trigger: "axis",

          axisPointer: {
            type: "shadow", //背景色
          },
          //提示框样式调整
          textStyle: {
            color: "#E6E6E6",
            fontFamily: "SourceHanSansCN-Regular",
            fontSize: 12,
          },
          borderColor: "#E6E6E6",
          extraCssText:
            'font-size:12px;font-family: "SourceHanSansCN-Regular"; font-family:"SourceHanSansCN-Regular";color: rgba(255,255,255,1);background:rgba(0, 0, 0, 0.3)',

          // formatter: function (keyWaterList) {
          //   console.log(keyWaterList, 'keyWaterList')
          //   return keyWaterList[0].marker + '污水处理量' + '<br/>' + '<br/>' + keyWaterList[0].value + '吨'; //将小数转化为百分数显示
          // }
        },
        grid: {
          top: "0%",
          bottom: "1%",
          left: "1%",
          right: "8%",
          containLabel: true,
        },
        legend: {
          itemWidth: 8,
          itemHeight: 8,
          itemGap: 30,
          left: "2%",
          textStyle: {
            //文字颜色
            color: "#e6e6e6",
            fontSize: "12",
            fontFamily: "SourceHanSansCN-Regular",
          },
        },

        xAxis: {
          type: "value",
          axisLabel: {
            show: false,
            formatter: "{value}" + "KWh",
            color: "#E6E6E6",
            fontSize: "12",
            interval: 1,
          },
          // boundaryGap: [0, 0.01],
          // position: 'bottom',
          splitLine: {
            // 去掉虚线
            show: false,
          },
          axisLine: {
            show: true,
          },
          axisTick: {
            show: false,
          },
        },
        yAxis: {
          type: "category",
          data: ["TOP5", "TOP4", "TOP3", "TOP2", "TOP1"],
          position: "left",
          axisTick: {
            show: false, //不显示y轴刻度
          },
          splitLine: {
            // 去掉虚线
            show: false,
            lineStyle: {
              color: "rgba(210, 210, 210, .4)",
              width: 1,
              type: "dotted",
            },
          },
          axisLine: {
            show: true,
          },
          nameTextStyle: {
            color: "#E6E6E6",
            fontSize: "12",
            fontFamily: "SourceHanSansCN-Regular",
            padding: [0, 0, 0, 0], // 上右下左与原位置距离
          },
          axisLabel: {
            formatter: "{value}",
            color: "#E6E6E6",
            fontSize: "12",
            fontFamily: "SourceHanSansCN-Regular",
            margin: 5,
          },
        },
        series: [
          {
            name: "",
            type: "bar",
            data: [0, 250, 500, 750, 1000],
            barWidth: 5,
            showBackground: true,
            backgroundStyle: {
              color: "#00395B",
            },
            itemStyle: {
              normal: {
                color: new echarts.graphic.LinearGradient(0, 0, 1, 0, [
                  { offset: 1, color: "rgba(93, 187, 251, 1)" }, // 起点颜色
                  { offset: 0.5, color: "rgba(0, 152, 254, 1)" }, // 过渡颜色
                  { offset: 0, color: "rgba(0, 152, 254, 1)" }, // 结束颜色
                ]),
                barBorderRadius: [0, 20, 20, 0], // 设置边角为弧形
              },
            },
          },
        ],
      });
    },
    //  折线图
    LifeobtainEcharts() {
      // 基于准备好的dom，初始化echarts实例
      const myChart = echarts.init(this.$refs.LifeobtainsEcharts);
      // 绘制图表
      myChart.setOption({
        color: ["#0092FF"],
        tooltip: {
          trigger: "axis",

          axisPointer: {
            type: "shadow", //背景色

            // shadowStyle: {

            //   color: "red"//背景色设置

            // },
          },
          //提示框样式调整
          textStyle: {
            color: "#E1E1E1",
            fontFamily: "DS-Digital",
            fontSize: 14,
          },
          extraCssText: " color: #ccc;background:rgba(39, 69, 86,.8);",
        },
        legend: {
          data: [],
          itemWidth: 10,
          itemHeight: 10,
          itemGap: 30,
          icon: "rect",
          left: "-0.5%",

          textStyle: {
            //文字颜色
            color: "#F9F9F9",
            fontSize: "14",
            fontFamily: "SourceHanSansCN-Regular",
          },
        },
        grid: {
          top: "20%",
          bottom: "3%",
          left: "2%",
          right: "0%",
          containLabel: true,
        },
        xAxis: [
          {
            type: "category",
            // boundaryGap: false,
            data: ["1/4", "1/5", "1/6", "1/7", "1/8", "1/9", "1/10"],
            position: "bottom",
            splitLine: {
              // 去掉虚线
              show: false,
            },
            axisTick: {
              show: false,
            },
            axisLabel: {
              color: "#E6E6E6",
              fontSize: "10",
              interval: 0,
            },
            axisLine: {
              lineStyle: {
                // textAlign: 'right'
                color: "rgba(195,190,190,.6)",
              },
            },
          },
        ],
        yAxis: [
          {
            name: "单位：KWh",
            type: "value",
            splitLine: {
              show: true,
              lineStyle: {
                color: "rgba(195,190,190,.6)",
                type: "dashed",
              },
            },
            axisLine: {
              show: false,
            },
            nameTextStyle: {
              color: "#E6E6E6",
              fontSize: "12",
              fontFamily: "SourceHanSansCN-Regular",
              padding: [0, 0, 0, -7], // 上右下左与原位置距离
            },
            axisLabel: {
              formatter: "{value}",
              color: "#E6E6E6",
              fontSize: "12",
              fontFamily: "SourceHanSansCN-Regular",
              margin: 8,
            },
          },
          {
            type: "value",
            splitLine: {
              // 去掉虚线
              show: false,
            },
            axisLine: {
              show: false,
            },
            nameTextStyle: {
              color: "#E6E6E6",
              fontSize: "14",
              fontFamily: "SourceHanSansCN-Regular",
              padding: [0, 0, 0, 15],
            },
          },
        ],
        series: [
          {
            name: "",
            type: "line",
            stack: "1",
            showSymbol: false, //去除圆点
            symbolSize: 8,
            data: [55, 50, 66, 40, 88, 50, 15],
            smooth: true,
            // 实心
            symbol: "circle",
            areaStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: "rgba(26, 209, 255, 1)",
                },
                {
                  offset: 1,
                  color: "rgba(26, 209, 255, 0)",
                },
              ]),
            },
          },
        ],
      });
    },
  },
};
</script>

<style lang="less" scoped>
.home_box {
  width: 100%;
  height: 100%;
  //   position: absolute;
  //   left: 0.2604rem;
  //   top: 0.4948rem;
  // 用电 概览
  .OverviewofelectricityBox {
    display: flex;
    width: 100%;
    height: 0.4948rem;
    background: url("~@/assets/imgs/Energy/overviewofelect_bcg.png") no-repeat
      center / cover;
    background-size: 100% 100%;
    margin-top: 0.0339rem;

    .TotalnumberBox {
      display: flex;
      width: 1.0833rem;
      // 电表总数
      .metersBox {
        margin: 0.1069rem 0 0 0.5521rem;

        .num {
          height: 0.1171875rem;
          line-height: 0.1171875rem;
          font-size: 0.1667rem;
          font-family: DigitalRegular;
          font-weight: 400;
          color: #fdfdfd;
          letter-spacing: 0.0052rem;
          text-align: center;
          text-shadow: 0px 0.013rem 0.0313rem #00dcfe;
          -webkit-background-clip: text;
        }

        .totles {
          //   background: red;

          font-size: 0.099rem;
          font-family: SourceHanSansCN-Regular;
          font-weight: 400;
          color: #e6e6e6;
          letter-spacing: 0.0052rem;
          -webkit-background-clip: text;
        }
      }
    }

    .onlineBox {
      display: flex;
      justify-content: center;
      width: 0.9375rem;
      margin-left: 0.1354rem;
      //  在线 离线 率
      .metersBox {
        margin: 0.0390625rem 0;

        width: 0.3906rem;
        text-align: center;
        // padding: 0.04rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .num {
          height: 0.1171875rem;
          line-height: 0.1171875rem;
          font-size: 0.125rem;
          font-family: DigitalRegular;
          color: #58ff6d;
          font-weight: 400;
          letter-spacing: 0.0052rem;
          text-shadow: 0px 0.013rem 0.0313rem #00dcfe;
          -webkit-background-clip: text;
        }

        .grey {
          height: 0.1171875rem;
          line-height: 0.1171875rem;
          font-size: 0.125rem;
          font-family: DigitalRegular;
          color: #cccccc;
          font-weight: 400;
          letter-spacing: 0.0052rem;
          text-shadow: 0px 0.013rem 0.0313rem #00dcfe;
          -webkit-background-clip: text;
        }

        .totles {
          font-size: 0.0729rem;
          font-family: SourceHanSansCN-Regular;
          letter-spacing: 0.0052rem;
          font-weight: 500;
          color: #e6e6e6;
          -webkit-background-clip: text;
        }
      }
      .metersBox:first-of-type {
        margin-right: 0.1021rem;
        margin-left: 0.0521rem;
      }
    }
  }

  //   告警总数
  .devicealarmsBox {
    display: flex;
    align-items: center;
    margin-top: 0.1406rem;

    .title {
      font-size: 0.0781rem;
      font-family: SourceHanSansCN-Medium;
      font-weight: 500;
      color: #ffffff;
    }

    .num {
      font-size: 0.125rem;
      font-family: YouSheBiaoTiHei;
      font-weight: 400;
      color: #1ef8ff;
    }
  }

  //   图表
  .Stack_Graph_box {
    width: 2.224rem;
    height: 0.1094rem;
    background: url("~@/assets/imgs/Energy/line_bcg.png") no-repeat center /
      cover;
    background-size: 100% 100%;

    .Stack_Graph {
      padding: 0.01rem 0;
      height: 0.1094rem;
    }
  }

  //   图表信息
  .EchartsnewsBox {
    margin-top: 0.0286rem;

    ul {
      display: flex;
      justify-content: space-around;

      li {
        display: flex;
        align-items: center;

        .title {
          display: flex;
          align-items: center;

          .name {
            font-size: 0.0729rem;
            font-family: SourceHanSansCN-Medium;
            font-weight: 500;
            color: #e6e6e6;
            margin: 0 0.0208rem 0 0.013rem;
          }
        }

        .num {
          font-size: 0.1094rem;
          font-family: YouSheBiaoTiHei;
          font-weight: 400;
          color: #ffffff;
        }

        .skyblue {
          width: 0.0729rem;
          height: 0.0729rem;
          background: #1ef8ff;
          border-radius: 0px 0px 0px 0px;
          opacity: 1;
        }

        .orange {
          width: 0.0729rem;
          height: 0.0729rem;
          background: #fbb351;
          border-radius: 0px 0px 0px 0px;
          opacity: 1;
        }

        .cameobrown {
          width: 0.0729rem;
          height: 0.0729rem;
          background: #ff6e6f;
          border-radius: 0px 0px 0px 0px;
          opacity: 1;
        }
      }
    }
  }

  // 饼图
  .echartsBox {
    display: flex;
    justify-content: space-between;
    padding: 0 0.02rem;
    margin: 0.1875rem 0 0.0677rem;

    //   饼图
    .echarts_bcg {
      position: relative;

      .colorBcg {
        position: relative;
        top: 0.0972rem;
        left: 20%;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 0.5208rem;
        height: 0.5208rem;
        background: #00325a;
        border-radius: 50%;
        font-size: 0.099rem;
        font-family: SourceHanSansCN-Bold;
        font-weight: bold;
        color: #e6e6e6;
        padding: 0.0521rem;
        text-align: center;
      }

      .careAgedEcharts {
        position: absolute;
        top: 0;
        left: 0.0104rem;
        width: 0.6927rem;
        height: 0.6927rem;
      }
    }

    //   饼图信息
    .PieBox {
      //margin-top: 0.0521rem;
      height: 0.6927rem;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
      margin-right: 0.1042rem;
      ul {
        li {
          display: flex;
          align-items: center;
          height: 0.2344rem;

          .title {
            display: flex;
            align-items: center;

            .blue {
              width: 0.0833rem;
              height: 0.0833rem;
              background: #199afd;
              border-radius: 50%;
              opacity: 1;
            }

            .skyblue {
              width: 0.0833rem;
              height: 0.0833rem;
              background: #4be8e9;
              border-radius: 50%;
              opacity: 1;
            }

            .name {
              font-size: 0.0938rem;
              font-family: SourceHanSansCN-Medium;
              font-weight: 500;
              color: #e6e6e6;
              margin: 0 0.151rem 0 0.0573rem;
            }
          }

          .num {
            font-size: 0.1458rem;
            font-family: YouSheBiaoTiHei;
            font-weight: 400;
            color: #e6e6e6;
          }
        }
      }
    }
  }

  .PowerconsumptionBox {
    height: 0.4635rem;
    margin: 0.046875rem 0 0.0651rem;

    ul {
      li {
        display: flex;
        align-items: center;
        justify-content: space-around;

        img {
          width: 0.7708rem;
          height: 0.4635rem;
        }

        .powerBox {
          .title {
            text-align: center;
            font-size: 0.0885rem;
            font-family: PINGFANG;
            font-weight: 400;
            color: #ffffff;
            height: 0.0885rem;
            line-height: 0.2396rem;
          }

          .num {
            width: 0.8594rem;
            height: 0.4292rem;
            line-height: 0.4396rem;
            font-size: 0.1367rem;
            font-family: PINGFANG;
            font-weight: bold;
            color: #ffffff;
            text-align: center;
            background: url("~@/assets/imgs/Energy/analysis_bcg.png") no-repeat
              center / cover;
            background-size: 100% 100%;
          }
        }
      }
    }
  }

  // 柱状图
  .Rankingecharts {
    width: 2.224rem;
    height: 0.7396rem;
    margin-top: 0.1563rem;
  }

  //   折线图
  .obtainEcharts {
    width: 2.224rem;
    height: 0.7396rem;
    margin-top: 0.1563rem;
  }
}
</style>
