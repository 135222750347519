import Vue from 'vue'
import VueRouter from 'vue-router'


import homePage from "@/views/homePage/index.vue";
import { login } from '@/api/login'
import Cookies from 'js-cookie'
Vue.use(VueRouter)
const originalPush = VueRouter.prototype.push
// 修改 原型对象中的push方法
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

const routes = [

  {
    path: '/',
    name: 'homePage',
    component: homePage,
  },
]

const router = new VueRouter({
  routes
})
router.beforeEach((to, from, next) => {
  login().then(res => {
    let token = res.data.token
    console.log(token, 'token')
    Cookies.set('token', token)
    if (res) {
      setTimeout(() => {
        next()
      }, 100);
    }
  })
  // next()
})
export default router
