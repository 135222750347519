<template>
  <div class="titlePublics">
    <img src="@/assets/imgs/arrow_right.png" alt="" />
    <p class="titleMoov">{{ titleList.title }}</p>
  </div>
</template>

<script>
export default {
  props: {
    titleList: {
      type: Object,
      default: "",
    },
  },
};
</script>

<style lang="less" scoped>
.titlePublics {
  //   width: 100%;
  //   height: 0.1432rem;
  display: flex;
  //   justify-content: space-between;
  align-items: center;
  //   background-image: url("~@/assets/imgs/title_bgc.png");
  //   background-size: 100% 100%;
  //   background-position: 0 0;
  //   background-repeat: no-repeat;
  .titleMoov {
    font-size: 0.1042rem;
    font-family: "SourceHanSansCN-Regular";
    font-weight: bold;
    color: #4db2ff;
  }
  img {
    width: 0.0469rem;
    height: 0.0781rem;
    margin-right: 0.0234rem;
    // margin-left: 0.0677rem;
  }
}
</style>