<template>
  <div class="home_box">
    <Title :titleList="titleList"></Title>
    <div class="bcg_own">
      <ul class="th">
        <li v-for="(item, index) in rankingData" :key="index + 'rankingData'">
          {{ item.name }}
        </li>
      </ul>
      <div>
        <vue-seamless-scroll
          :data="$store.state.AirListData"
          :class-option="classOption"
          class="classOption_scroll"
        >
          <ul class="rankingData_ul">
            <li
              v-for="(item, index) in $store.state.AirListData"
              :key="index + 'PerceptionData'"
              :class="index % 2 !== 0 ? 'active_lig' : ''"
            >
              <p>{{ item.Floorname }}</p>
              <p>{{ item.typename }}</p>
              <p
                :class="
                  item.state === '1'
                    ? 'orange_active'
                    : item.state === '2'
                    ? 'skyblue_active'
                    : ''
                "
              >
                {{ item.state == 1 ? "运行中" : "已关闭" }}
              </p>
            </li>
          </ul>
        </vue-seamless-scroll>
      </div>
    </div>
  </div>
</template>

<script>
import * as echarts from "echarts";
import Title from "@/components/Title";
import smallTitle from "@/components/smallTitle";
import vueSeamlessScroll from "vue-seamless-scroll";
import floorTab from "@/components/floorTab.vue";
import bus from "@/bus";
import { productKey } from "@/api/common";

export default {
  components: {
    Title,
    smallTitle,
    vueSeamlessScroll,
    floorTab,
  },
  data() {
    return {
      // 标题
      titleList: {
        title: "空调列表",
      },
      // 小标题
      smalltitle: {
        title: "单个楼栋空调列表",
      },
      // 总体 数据 tr
      rankingData: [
        {
          name: "楼栋名称",
        },
        {
          name: "设备名称",
        },
        {
          name: "状态",
        },
      ],
      // th
      PerceptionData: [
        {
          Floorname: "尚学园2号楼",
          typename: "暖通001",
          state: "0",
        },
        {
          Floorname: "尚学园2号楼",
          typename: "暖通001",
          state: "1",
        },
        {
          Floorname: "尚学园2号楼",
          typename: "暖通001",
          state: "0",
        },
        {
          Floorname: "尚学园2号楼",
          typename: "暖通001",
          state: "1",
        },
        {
          Floorname: "尚学园2号楼",
          typename: "暖通001",
          state: "0",
        },
        {
          Floorname: "尚学园2号楼",
          typename: "暖通001",
          state: "1",
        },
        {
          Floorname: "尚学园2号楼",
          typename: "暖通001",
          state: "1",
        },
        {
          Floorname: "尚学园2号楼",
          typename: "暖通001",
          state: "0",
        },
        {
          Floorname: "尚学园2号楼",
          typename: "暖通001",
          state: "1",
        },
        {
          Floorname: "尚学园2号楼",
          typename: "暖通001",
          state: "1",
        },
        {
          Floorname: "尚学园2号楼",
          typename: "暖通001",
          state: "0",
        },
        {
          Floorname: "尚学园2号楼",
          typename: "暖通001",
          state: "1",
        },
      ],
      // 总体 数据 tr
      singleList: [
        {
          name: "楼栋名称",
        },
        {
          name: "设备名称",
        },
        {
          name: "状态",
        },
      ],
      // th

      pointTabList: [
        {
          url: require("@/assets/imgs/building_2.png"),
          urlActive: require("@/assets/imgs/building_1.png"),
          text: "楼宇情况",
        },
      ],
      pointTab: -1,
      // 拱宸桥Id
      labelIds: "528221552517316608",
    };
  },
  mounted() {
    let labelIds = this.$store.state.labelIds;
    this.productKeyHandle(labelIds);
    // bus.$on("labelIds", (val) => {
    //   console.log(val, "val");
    //   setTimeout(() => {
    //     this.productKeyHandle(val);
    //   }, 200)
    // });
  },
  computed: {
    classOption() {
      return {
        step: 0.5, // 数值越大速度滚动越快
        limitMoveNum: 11, // 开始无缝滚动的数据量 this.dataList.length
        hoverStop: true, // 是否开启鼠标悬停stop
        direction: 1, // 0向下 1向上 2向左 3向右
        openWatch: true, // 开启数据实时监控刷新dom
        singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 1000, // 单步运动停止的时间(默认值1000ms)
      };
    },
    classOptions() {
      return {
        step: 0.5, // 数值越大速度滚动越快
        limitMoveNum: 5, // 开始无缝滚动的数据量 this.dataList.length
        hoverStop: true, // 是否开启鼠标悬停stop
        direction: 1, // 0向下 1向上 2向左 3向右
        openWatch: true, // 开启数据实时监控刷新dom
        singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 1000, // 单步运动停止的时间(默认值1000ms)
      };
    },
  },
  methods: {
    // 空调列表
    async productKeyHandle(labelIds) {
      const res = await productKey(labelIds);
      console.log(res, "空调列表");
    },
    changePoint(index) {
      ue4("SetIconShow", false);
      if (this.pointTab == index) {
        this.pointTab = -1;
      } else if (index == 0) {
        ue4("SetIconShowByType", "true,1");
        this.pointTab = index;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.home_box {
  width: 2.224rem;
  height: 5.1823rem;
  position: absolute;
  right: 0.125rem;
  top: 0.4427rem;

  .bcg_own {
    overflow: hidden;
    margin: 0.0495rem 0 0.112rem;

    .th {
      display: flex;
      width: 2.224rem;
      height: 0.1979rem;
      background: url("~@/assets/imgs/HVAC/scholl_tr.png") no-repeat center /
        cover;
      background-size: 100% 100%;

      align-items: center;
      justify-content: space-around;

      li {
        font-size: 0.0885rem;
        font-family: SourceHanSansCN-Medium;
        font-weight: 500;
        color: #ffffff;
        text-align: center;
      }

      li:nth-child(1) {
        width: 0.7813rem;
      }

      li:nth-child(2) {
        width: 0.8167rem;
      }

      li:nth-child(3) {
        width: 0.4167rem;
      }
      li:nth-child(4) {
        width: 0.8698rem;
      }
    }

    .classOption_scroll {
      height: 4.4063rem;
      overflow: hidden;
      margin: 0.026rem 0;

      .rankingData_ul {
        li {
          display: flex;
          justify-content: space-around;
          align-items: center;
          width: 2.224rem;
          height: 0.1979rem;
          margin: 0 0 0.05rem;

          p {
            font-size: 0.0885rem;
            font-family: SourceHanSansCN-Regular;
            font-weight: 400;
            color: #e6e6e6;
            width: 0.5rem;
            text-align: center;
          }
          p:nth-child(1) {
            width: 0.7813rem;
          }

          p:nth-child(2) {
            width: 0.8167rem;
          }

          p:nth-child(3) {
            width: 0.4167rem;
          }
          p:nth-child(4) {
            width: 0.8698rem;
          }
        //   p:nth-child(1) {
        //     margin-left: 0.06rem;
        //   }

        //   p:nth-child(2) {
        //     margin-left: 0.13rem;
        //   }

        //   p:nth-child(3) {
        //     margin-left: 0.08rem;
        //   }

          .orange_active {
            color: #1ef8ff;
          }

          .skyblue_active {
            color: #fbb351;
          }
        }

        .active_lig {
          width: 2.224rem;
          height: 0.1979rem;
          background: url("~@/assets/imgs/HVAC/line_th.png") no-repeat center /
            cover;
          background-size: 100% 100%;
        }
      }
    }

    .singleData_scroll {
      height: 0.58rem;
      overflow: hidden;
      margin: 0.026rem 0;

      .rankingData_ul {
        li {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 1.4036rem;
          height: 0.1015625rem;
          margin: 0 0 0.05rem;

          p {
            font-size: 0.0521rem;
            font-family: SourceHanSansCN-Regular;
            font-weight: 400;
            color: #e6e6e6;
            width: 0.5rem;
            text-align: center;
          }

          p:nth-child(1) {
            margin-left: 0.06rem;
          }

          p:nth-child(2) {
            margin-left: 0.13rem;
          }

          p:nth-child(3) {
            margin-left: 0.08rem;
          }

          .orange_active {
            color: #1ef8ff;
          }

          .skyblue_active {
            color: #fbb351;
          }
        }

        .active_lig {
          width: 1.4036rem;
          height: 0.1015625rem;
          background: url("~@/assets/imgs/HVAC/line_th.png") no-repeat center /
            cover;
          background-size: 100% 100%;
        }
      }
    }
  }

  .point_tab {
    width: 0.3776rem;
    height: 0.3854rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    top: 0;
    right: 1.5661rem;

    .item {
      width: 0.3776rem;
      height: 0.1042rem;
      background-image: url("~@/assets/imgs/point_tab_1.png");
      background-size: 100% 100%;
      background-position: 0 0;
      background-repeat: no-repeat;
      cursor: pointer;
      display: flex;
      align-items: center;

      img {
        width: 0.0547rem;
        height: 0.0547rem;
        margin-left: 0.0521rem;
        margin-right: 0.0286rem;
      }

      p {
        font-size: 0.0521rem;
        font-family: "SourceHanSansCN-Regular";
        font-weight: 500;
        color: #e6e6e6;
      }
    }

    .itemActives {
      width: 0.3776rem;
      height: 0.1042rem;
      background-image: url("~@/assets/imgs/point_tab_2.png");
      background-size: 100% 100%;
      background-position: 0 0;
      background-repeat: no-repeat;
      cursor: pointer;
      display: flex;
      align-items: center;

      img {
        width: 0.0547rem;
        height: 0.0547rem;
        margin-left: 0.0521rem;
        margin-right: 0.0286rem;
      }

      p {
        font-size: 0.0521rem;
        font-family: "SourceHanSansCN-Regular";
        font-weight: 500;
        color: #fff;
      }
    }
  }

  .floorTab {
    position: absolute;
    bottom: -0.3002rem;
    right: 1.5661rem;
  }
}
</style>
