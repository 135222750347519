<template>
  <div class="macro_situation">
    <Title class="title" :titleList="titleList"></Title>
    <!-- <img src="@/assets/imgs/macro_situation.png" alt="" /> -->
    <h1>地铁公交</h1>
    <p>方圆1公里内5站</p>
    <p>
      公交：展望村、树人大学(南)、紫薇小学(北)
    </p>
  </div>
</template>

<script>
import Title from "@/components/Title.vue";
export default {
  components: {
    Title,
  },
  data() {
    return {
      titleList: {
        title: "树人学院",
      },
    };
  },
};
</script>

<style lang="less" scoped>
.macro_situation {
  width: 2.224rem;
  height: 5.1823rem;
  position: absolute;
  top: 0.4427rem;
  right: 0.125rem;
  h1 {
    font-family: "SourceHanSansCN-Regular";
    font-size: 0.2344rem;
    margin-top: 0.1302rem;
  }
  p {
    font-family: "SourceHanSansCN-Regular";
    font-size: 0.1667rem;
    font-weight: bold;
    margin-top: 0.2604rem;
  }
  // .title {
  //   position: absolute;
  //   right: 0.2135rem;
  //   top: 0.4427rem;
  // }
  // img {
  //   width: 2.4167rem;
  //   height: 2.2708rem;
  //   position: absolute;
  //   right: 0;
  //   top: 0.8333rem;
  // }
}
</style>