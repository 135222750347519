import http from "@/server/sever";

export function login() {
  return http.post(
    'api/api/permission/auth/login',
    {
      "loginName": "admin",
      "password": "1Sysc0re!"
    }
  )
}
