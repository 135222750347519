import axios from 'axios'
import Cookies from "js-cookie";

let baseURL;
let HeaderToken;

// console.log(HeaderToken,'===========HeaderToken======');
if (process.env.NODE_ENV == "development") {
  //开发环境接口地址
  baseURL = ''
} else {
  //生产环境接口地址
  baseURL = ''
}
const http = axios.create({
  baseURL,
  timeout: 10000 // 请求超时时间
})
// 请求拦截器
http.interceptors.request.use(config => {
  // do something before request is sent
  const token = window.sessionStorage.getItem('token')
  HeaderToken = Cookies.get('token')
  config.headers['X-Requested-With'] = 'XMLHttpRequest'
  config.headers.post['Content-Type'] = 'application/json; charset=UTF-8'
  config.headers['token'] = encodeURIComponent(HeaderToken)//让每个请求携带自定义token
  return config;
}, error => {
  return Promise.reject(error);
});
// 响应拦截器
http.interceptors.response.use(res => {
  //console.log(res)
  if (res.status == 200) {
    return res.data;
  } else {
    return Promise.reject(res.data);
  }
}, error => {
  return Promise.reject(error);
});
export default http
